import React, { FC } from 'react';

type TableHeadProps = {
  className?: string;
  item?: string | number;
};

const TableHead: FC<TableHeadProps> = ({ className, item }) => (
  <th
    className={`w-[10%] py-4 px-6 bg-gray-50 uppercase font-normal text-sm text-[#6F7FAF] border-b border-gray-light text-left ${className}`}
  >
    {item}
  </th>
);

export default TableHead;
