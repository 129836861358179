export enum TopUpType {
  ONLINE = 'online',
  ONSITE_CARD = 'onsite-card',
  ONSITE_CASH = 'onsite-cash',
  TOPUP_BONUS = 'topup-bonus',
  AUTO_TOPUP = 'auto-topup',
  ADMIN_TOPUP = 'admin-topup',
  CUSTOM_TOPUP = 'custom-topup',
}

export const isDefaultTopUpType = (id: string): boolean => {
  return Object.values(TopUpType).includes(id as TopUpType);
};
