import { useEffect } from 'react';

import { useOrders } from 'data/useOrders';
import { isObjectEmpty } from 'helpers';
import { selectEventsUpcomingEvents, selectUserData, useAppSelector } from 'store';

const useFetchEvents = () => {
  const upcomingEvents = useAppSelector(selectEventsUpcomingEvents);
  const { fetchUpcomingEvents } = useOrders();
  const userData = useAppSelector(selectUserData);

  /**
   * Fetch events only once
   */
  useEffect(() => {
    (!upcomingEvents || isObjectEmpty(upcomingEvents)) &&
      userData &&
      userData.sub &&
      fetchUpcomingEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUpcomingEvents, userData]);
};

export default useFetchEvents;
