import classNames from 'classnames';
import React, { FC, ReactNode, LegacyRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { ExclamationCircleIcon } from 'components/icons';

type AlignmentType = 'left' | 'right';

export type FieldWrapperProps = {
  label?: string;
  description?: string;
  alignDescription?: AlignmentType;
  alignLabel?: AlignmentType;
  isRequired?: boolean;
  isError?: boolean;
  isWarning?: boolean;
  isOptional?: boolean;
  isDisabled?: boolean;
  errorMsg?: string;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  leftChildren?: ReactNode;
  rightChildren?: ReactNode;
  className?: string;
  labelClassName?: string;
  ref?: LegacyRef<HTMLDivElement>;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

const FieldWrapper: FC<FieldWrapperProps & { children: ReactNode }> = ({
  label,
  description,
  alignDescription = 'left',
  alignLabel = 'left',
  isRequired,
  isError,
  isWarning,
  isOptional,
  isDisabled,
  errorMsg,
  leftIcon,
  rightIcon,
  leftChildren,
  rightChildren,
  onClick,
  className,
  ref,
  children,
  labelClassName,
}) => {
  const inputContainerStyles = classNames(
    'w-full relative',
    isDisabled && 'bg-[#F9F9F9] rounded-lg px-3 py-2',
  );
  const inputWrapperStyles = classNames(
    'relative flex items-center rounded-md justify-between border-[1.5px] shadow-[0px_1px_2px_rgba(0,0,0,0.05)] text-base text-[#232E59]',
    !isDisabled && 'px-3 mb-1 h-[38px]',
    isDisabled && 'border-none shadow-none bg-[#F9F9F9] px-0 pt-[2px]',
    {
      'border-[#FD563D]': isError,
      'border-[#FBBF24]': isWarning,
      'border-[#c4cbdd]': !isError && !isWarning,
    },
  );
  const exclamationCircleWrapperStyles = classNames('w-[16px] ml-[10px]', {
    'text-[#FD563D]': isError,
    'text-[#FBBF24]': isWarning,
  });
  const inputHeaderStyles = classNames(
    'flex justify-between',
    alignLabel === 'right' && 'flex-row-reverse',
  );
  const inputBottomTextStyle = classNames(
    'text-[10px] leading-[14px] pt-1',
    alignDescription === 'right' && 'text-right',
  );

  return (
    <div className={classNames(inputContainerStyles, className)} onClick={onClick} ref={ref}>
      <span className={inputHeaderStyles}>
        {label && (
          <p className={twMerge(`text-sm text-[#6F7FAF] pb-1 leading-[18px] ${labelClassName}`)}>
            {label}
            {isRequired && '*'}
          </p>
        )}

        {isOptional && !isRequired && !isDisabled && (
          <p className='self-end text-sm text-[#C4CBDD] pb-1 leading-[18px]'>Optional</p>
        )}
      </span>

      <span className={inputWrapperStyles}>
        {leftChildren && <span className='box-content pr-2'>{leftChildren}</span>}
        {leftIcon && <span className='box-content w-[16px] pr-2'>{leftIcon}</span>}
        {children}
        {(isError || isWarning) && (
          <span className={exclamationCircleWrapperStyles}>
            <ExclamationCircleIcon />
          </span>
        )}
        {rightIcon && <span className='box-content w-[16px] pl-2'>{rightIcon}</span>}
        {rightChildren && <span className='box-content pl-2'>{rightChildren}</span>}
      </span>

      {description && !isError && !isDisabled && (
        <p className={classNames(inputBottomTextStyle, 'text-[#6F7FAF]')}>{description}</p>
      )}
      {isError && errorMsg && !isDisabled && (
        <p className={classNames(inputBottomTextStyle, 'text-[#FD563D]')}>{errorMsg}</p>
      )}
    </div>
  );
};

export default FieldWrapper;
