import axios from 'axios';
import { Agent } from 'jwt-cert-sdk';

export interface ClientConfig {
  baseURL: string;
  timeout: number;
}

abstract class Api {
  abstract clientConfig(): ClientConfig;
  abstract authToken(): Promise<string>;
  protected client;
  constructor() {
    this.client = axios.create({
      headers: { 'content-type': 'application/json' },
    });
    this.client.interceptors.request.use(
      async (config) => {
        const { baseURL, timeout } = this.clientConfig();
        config.baseURL = baseURL;
        config.timeout = timeout;
        config.headers['Authorization'] = `Bearer ${await this.authToken()}`;
        return config;
      },
      (error) => {
        console.log('Failed to make request with error:');
        console.log(error);
        return Promise.reject(error);
      },
    );
  }
}

export abstract class SecuredApi extends Api {
  agent: Agent;
  abstract authtokenTTL: number;
  abstract rfidSearch?: string;
  constructor(agent: Agent) {
    super();
    this.agent = agent;
  }
  authToken = async () => {
    const { baseURL } = this.clientConfig();
    const token = await (this.agent as any).tokenManager.getToken(
      baseURL,
      this.authtokenTTL,
      { rfid: this.rfidSearch }, // ITX-1693 This is the 'args' of the registerTokenGenerator that get used to generate a token if provided. we need to this for secure RFID search
    );
    return token;
  };
}

export default Api;
