import { Config } from '../Config';

import { ClientConfig, SecuredApi } from './Api';
import type { ClaimWristbandRequestBody } from './types/OrdersApiTypes';

import { Order, TopupType, Vendor } from 'data';
import { paths } from 'data/constants';

interface OrdersApi {
  transferTicket: (id: string, body: any) => Promise<any>;
  createTopUpOrder: (topUpFolioUuid: string, orderInfo: any) => Promise<unknown>;
  getOrderById: (orderId: string) => Promise<Order>;
  getVendorByFolioId: (orderId: string) => Promise<Vendor>;
  setWristbandFrozenStatus: (eventId: string, ticketId: string, isFrozen: boolean) => Promise<any>;
  claimWristband: (body: ClaimWristbandRequestBody) => Promise<any>;
  checkIfWristbandExists: (body: ClaimWristbandRequestBody) => Promise<{ existed: boolean }>;
  refundHistory: (accountId: string, eventId: string) => Promise<any>;
  getCurrency: (folioId: string) => Promise<any>;
  getTopupTypeById: (folioId: string, topupTypeId: string) => Promise<TopupType>;
  getOrdersByRfid: (rfidUid: string) => Promise<any>;
}

export class OrdersApiImpl extends SecuredApi implements OrdersApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return {
      baseURL: Config.ORDERS,
      timeout: 15000,
    };
  };

  getProducts = async (topUpFolioUuid: string): Promise<any> => {
    const response = await this.client.get(paths.getProducts(topUpFolioUuid));
    return response.data;
  };

  transferTicket = async (folioId: string, body: any): Promise<any> => {
    const response = await this.client.post(paths.transferTicket(folioId), body);
    return response.data;
  };

  createTopUpOrder = async (topUpFolioUuid: string, body: any): Promise<Order> => {
    const response = await this.client.post(paths.createOrder(topUpFolioUuid), body);
    return response.data;
  };

  getOrderById = async (orderId: string) => {
    const response = await this.client.get(paths.fetchOrderById(orderId));
    return response.data?.entry;
  };

  getVendorByFolioId = async (folioId: string): Promise<Vendor> => {
    const response = await this.client.get(paths.getVendorByFolioId(folioId));
    return response.data;
  };

  getOrdersByRfid = async (rfidUid: string): Promise<any> => {
    this.rfidSearch = rfidUid;
    const response = await this.client.get(paths.getOrdersByRfid(rfidUid));
    return response.data;
  };

  setWristbandFrozenStatus = async (
    eventId: string,
    ticketId: string,
    isFrozen: boolean,
  ): Promise<any> => {
    const body = {
      isFrozen,
    };

    const response = await this.client.put(paths.setIsFrozen(eventId, ticketId), body);
    return response.data;
  };

  claimWristband = async (body: ClaimWristbandRequestBody): Promise<any> => {
    const response = await this.client.put(paths.claimWristband(), body);
    return response.data;
  };

  checkIfWristbandExists = async ({
    rfidUid,
    securityCode,
  }: ClaimWristbandRequestBody): Promise<{ existed: boolean }> => {
    const response = await this.client.get(paths.checkIfWristbandExists(rfidUid!, securityCode!));
    return response.data;
  };

  sendEmailReceipt = async (orderId: string): Promise<any> => {
    const response = await this.client.get(paths.sendEmailReceipt(orderId));
    return response.data;
  };
  refundHistory = async (accountId: string, eventId: string) => {
    try {
      const response = await this.client.get(paths.refundHistory(accountId, eventId));

      return response;
    } catch (err) {
      console.log('Failed to retrieve History', err);
    }
  };
  getCurrency = async (folioId: string) => {
    const response = await this.client.get(paths.getCurrency(folioId));

    return response.data;
  };

  getTopupTypeById = async (folioId: string, topupTypeId: string) => {
    const response = await this.client.get(paths.getTopupTypes(folioId, topupTypeId));

    return response.data;
  };
}

export default OrdersApi;
