import { createAsyncThunk } from '@reduxjs/toolkit';

export enum UserActions {
  FETCH_USER_DATA = 'user/fetchUserData',
  FETCH_USER_DETAILED_INFO = 'user/fetchUserDetailedInfo',
  UPDATE_USER = 'user/updateUser',
}

export const fetchUserDataAction = createAsyncThunk(
  UserActions.FETCH_USER_DATA,
  async ({ securedApi }: any) => {
    const response = await securedApi.UserApi.getCertifiedAccount();
    return response;
  },
);

export const fetchUserDetailedInfoAction = createAsyncThunk(
  UserActions.FETCH_USER_DETAILED_INFO,
  async ({ securedApi, email }: any) => {
    const response = await securedApi.UserApi.getUserDetailedInfo(email);
    return response;
  },
);

export const updateUserAction = createAsyncThunk(
  UserActions.UPDATE_USER,
  async ({ securedApi, body }: any) => {
    const response = await securedApi.AccountApi.updateUser(body);
    return response;
  },
);
