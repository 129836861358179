import axios from 'axios';
import { EmailContactInfo, Identity } from 'jwt-cert-sdk';

import { MyAgent, useJCTAgent, UseJCTAgentOptions } from './useJCTAgent';

import { Config } from 'data/Config';

export function useFirebasePoweredJCTAgent(
  props: {
    getFirebaseToken: { (): Promise<string> } | null;
  } & Omit<Omit<UseJCTAgentOptions<EmailContactInfo>, 'deferInitialization'>, 'identity'>,
) {
  const { alias, deviceName, getFirebaseToken, finalize, ready } = props;
  const rootAuthority = Config.ROOT_ISSUER;
  const { agent } = useJCTAgent({
    alias,
    deferInitialization: getFirebaseToken === null,
    identity: async () => {
      if (!getFirebaseToken)
        throw new Error(
          'getFirebaseToken is null and cannot be used yet. ' +
            'deferInitialization should prevent this from occuring',
        );
      const token = await getFirebaseToken();
      const response = await axios.request({
        method: 'post',
        baseURL: rootAuthority,
        url: '/account',
        timeout: 60000,
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      });
      const account = await response.data;
      return {
        contact: {
          type: 'email',
          info: account.primaryEmail,
        },
        name: account?.name,
        misc: {},
        sub: account?.sub,
      } as Identity<EmailContactInfo>;
    },
    deviceName,
    ready,
    finalize: (agent: MyAgent<EmailContactInfo>) => {
      if (getFirebaseToken !== null)
        // Add authorization to rootAuthorityAxios
        agent.rootAuthorityAxios.interceptors.request.use(async (config) => {
          const token = await getFirebaseToken();
          config.headers.set({
            ...config.headers,
            Authorization: `Bearer ${token}`,
          });
          return config;
        });

      if (typeof finalize === 'function') finalize(agent);
    },
  });
  return { agent };
}
