import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { TicketsType, AccountTicketsType } from 'data';
import { resetAll } from 'store/common';
import { setRejectedState, setPendingState, setFulfilledState } from 'store/common/commonActions';
import {
  fetchEventTicketsAction,
  transferTicketAction,
  createTopUpOrder,
  fetchTicketsByAccountIdAction,
  fetchProductsAction,
  sendEmailReceiptAction,
} from 'store/orders';

type topupTokensKeyValue = {
  [key: string]: any;
};

export const setWristbandFrozenStatus = createAsyncThunk(
  'orders/setWristbandFrozenStatus',
  async ({ securedApi, eventId, ticketId, isFrozen }: any) => {
    const response = await securedApi?.OrdersApi.setWristbandFrozenStatus(
      eventId,
      ticketId,
      isFrozen,
    );
    return response;
  },
);

export type OrdersState = {
  tickets: TicketsType | Record<string, never>;
  accountTickets: AccountTicketsType | Record<string, never>;
  orders: any;
  selectedOrder: any;
  topupTokens: topupTokensKeyValue;
  response: any;
  status: 'idle' | 'loading' | 'failed';
};

const initialState = {
  tickets: {},
  response: null,
  topupTokens: {},
  status: 'idle',
} as OrdersState;

export const ordersSlice = createSlice({
  name: 'ordersData',
  initialState,
  reducers: {
    setTickets: (state, action) => {
      state.tickets = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAll, () => initialState)
      // sendReceipt
      .addCase(sendEmailReceiptAction.pending, setPendingState)
      .addCase(sendEmailReceiptAction.rejected, setRejectedState)
      .addCase(sendEmailReceiptAction.fulfilled, setFulfilledState)
      // createTopUpOrder
      .addCase(createTopUpOrder.pending, setPendingState)
      .addCase(createTopUpOrder.rejected, setRejectedState)
      .addCase(createTopUpOrder.fulfilled, (state, action) => {
        state.response = action.payload;
        state.status = 'idle';
      })
      // transferTicket
      .addCase(transferTicketAction.fulfilled, (state, action) => {
        state.response = action.payload;
        state.status = 'idle';
      })
      // fetchEventTickets
      .addCase(fetchEventTicketsAction.pending, setPendingState)
      .addCase(fetchEventTicketsAction.rejected, setRejectedState)
      .addCase(fetchEventTicketsAction.fulfilled, (state, action) => {
        state.tickets = action.payload;
        state.status = 'idle';
      })
      // fetchTicketsByAccountId
      .addCase(fetchTicketsByAccountIdAction.fulfilled, (state, action) => {
        state.accountTickets = action.payload;
        state.status = 'idle';
      })
      // fetchProductsAction
      .addCase(fetchProductsAction.pending, setPendingState)
      .addCase(fetchProductsAction.rejected, setRejectedState)
      .addCase(fetchProductsAction.fulfilled, (state, action) => {
        //Create key value pair for topup tokens with topUpFolioId as key
        const key = action?.meta?.arg?.topUpFolioId;
        const entries = action.payload?.entries;
        const tokens = entries.filter((entry: any) => {
          if (entry?.productSummary?.kind === 'token') {
            return entry?.uuid;
          }
        });
        const value = tokens[0] ?? null;
        state.topupTokens[key] = value;
        state.status = 'idle';
      })
      .addCase(setWristbandFrozenStatus.pending, (state, action) => {
        state.status = 'loading';
        const keyToChange = action?.meta?.arg?.ticketId;
        const value = action?.meta?.arg?.isFrozen;
        for (const key in state.tickets.userTickets) {
          if (key === keyToChange) {
            state.tickets.userTickets[keyToChange] = {
              ...state.tickets?.userTickets[keyToChange],
              isFrozen: value,
            };
          }
        }
      })
      .addCase(setWristbandFrozenStatus.rejected, (_state, _action) => {
        toast.error('Failed to set wristband frozen status');
      })
      .addCase(setWristbandFrozenStatus.fulfilled, (state, _action) => {
        state.status = 'idle';
        toast.success('Wristband frozen status updated successfully');
      });
  },
});

export const { setTickets } = ordersSlice.actions;
export default ordersSlice.reducer;
