import { User } from 'firebase/auth';

import { useFirebasePoweredJCTAgent } from './useFirebasePoweredJCTAgent';

import { Config } from 'data/Config';

const useAppAgent = (currentUser: Nullable<User>) => {
  const { agent } = useFirebasePoweredJCTAgent({
    // Assign current users email so that we can match the agent against logged in user, only create agent once the user has verified
    // If we set alias before current user is verified agent will not be created
    alias:
      currentUser && currentUser.email && currentUser.emailVerified
        ? currentUser.email
        : 'logged-out',
    getFirebaseToken:
      // Defer until user is verified
      currentUser && currentUser.email && currentUser.emailVerified
        ? () => {
            return currentUser.getIdToken(true);
          }
        : null,
    finalize: (agent) => {
      // The args will be used for the claim wristband and loading the order on the secure rfid search
      agent.tokenManager.registerTokenGenerator(
        Config.ORDERS,
        (agent: any, ttl: number, args: any) =>
          agent.generateAuthenticationJCT({ aud: Config.ORDERS, rfid: args?.rfid }, ttl),
      );

      agent.tokenManager.registerTokenGenerator(Config.PAYMENTS, (agent: any, ttl: number) =>
        agent.generateAuthenticationJCT({ aud: Config.PAYMENTS }, ttl),
      );

      agent.tokenManager.registerTokenGenerator(Config.EVENTS, (agent: any, ttl: number) =>
        agent.generateAuthenticationJCT({ aud: Config.EVENTS }, ttl),
      );

      agent.tokenManager.registerTokenGenerator(Config.ACCESS_CONTROL, (agent: any, ttl: number) =>
        agent.generateAuthenticationJCT({ aud: Config.ACCESS_CONTROL }, ttl),
      );

      agent.tokenManager.registerTokenGenerator(
        Config.DISTRIBUTED_LEDGER,
        (agent: any, ttl: number) =>
          agent.generateAuthenticationJCT({ aud: Config.DISTRIBUTED_LEDGER }, ttl),
      );

      agent.tokenManager.registerTokenGenerator(Config.MIDDLEWARE, (agent: any, ttl: number) =>
        agent.generateAuthenticationJCT({ aud: Config.MIDDLEWARE }, ttl),
      );

      agent.tokenManager.registerTokenGenerator(Config.ROOT_ISSUER, (agent: any, ttl: number) =>
        agent.generateAuthenticationJCT(
          {
            aud: Config.ROOT_ISSUER,
            allowCertificateManagement: 'yes',
          },
          ttl,
        ),
      );
    },
  });

  return { agent };
};

export default useAppAgent;
