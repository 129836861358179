import React, { FC } from 'react';

import {
  SelectFieldDefaultProps,
  SelectFieldCardProps,
  SelectFieldDefault,
  SelectFieldCard,
} from './components';

type SelectFieldProps = (SelectFieldCardProps & SelectFieldDefaultProps) & {
  type?: 'default' | 'card';
};

const SelectField: FC<SelectFieldProps> = ({ type = 'default', ...props }) => {
  switch (type) {
    case 'card':
      return <SelectFieldCard {...props} />;
    case 'default':
      return <SelectFieldDefault {...props} />;
    default:
      return <SelectFieldDefault {...props} />;
  }
};

export default SelectField;
