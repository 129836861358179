import React, { FC, useEffect, useState } from 'react';
import { BiLoader } from 'react-icons/bi';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import MyTransactionDetails from './MyTransactionDetails';
import { useTransactionSearchForm } from './useTransactionSearchForm';

import { Box, ControlledTextField, Heading, Table, TableCell } from 'components';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { Order, useData } from 'data';
import { currency, formatCurrency } from 'helpers/currency';

const calculateOrderTotal = (order: Order) => {
  return order.totalPrice + (order.tip ? order.tip : 0);
};

const MyTransactions: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { isValid },
    setValue,
    getValues,
  } = useTransactionSearchForm();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState([] as any[]);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(undefined);

  const isMobile = useMediaQuery();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { securedApi } = useData();

  const rfidUid = searchParams.get('rfidUid');
  const securityCode = searchParams.get('securityCode');
  const isTransactionPurchase = (order: Order) =>
    order.products[0].productKind === 'add-on' && !order?.refunded;

  const getOrdersByRfid = async (rfidUid: string) => {
    const response = await securedApi?.OrdersApi.getOrdersByRfid(rfidUid);

    if (response && _.isArray(response) && response.length > 0) {
      // Search for refunds on an order
      response.forEach((order) => {
        order.refunds.forEach((refund: any) => {
          if (refund.refunded) {
            // Will add refunds as seperate orders into the response
            response.push({
              ...order,
              refundTotal: refund.refundTotal,
              refunded: refund.refunded,
              refundDate: refund.refundDate,
            });
          }
        });
      });
    }

    setOrders(response);
  };

  useEffect(() => {
    if (rfidUid && securityCode) {
      setValue('wristbandId', rfidUid);
      setValue('securityId', securityCode);
    }
  }, [rfidUid, securityCode, setValue]);

  const handleFormSubmit = async () => {
    setIsLoading(true);
    try {
      await getOrdersByRfid(getValues('wristbandId'));
    } catch (error) {
      console.error('Error while fetching orders', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {selectedOrder !== undefined ? (
        <MyTransactionDetails
          order={selectedOrder}
          handleBackClick={() => setSelectedOrder(undefined)}
        />
      ) : (
        <>
          <div className='flex flex-1 h-[84px] items-center'>
            <Heading headingLevel='p' className='text-[#232E59] leading-5 font-semibold text-base'>
              Transactions
            </Heading>
          </div>
          <div className='pb-4'>
            <Box removeBackground={!isMobile}>
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className={`flex ${isMobile ? 'flex-col' : 'flex-row align-items-center'}`}>
                  <div className={`pb-3 ${!isMobile ? 'mr-4' : ''}`}>
                    <ControlledTextField
                      labelClassName='text-base'
                      control={control}
                      label='Security ID'
                      fieldName='securityId'
                      placeholder={'4-7 digit code'}
                    />
                  </div>
                  <div className='pb-3'>
                    <ControlledTextField
                      labelClassName='text-base'
                      control={control}
                      label='Wristband/Card UID'
                      fieldName='wristbandId'
                      placeholder={'14-16 chars combo of numbers & letters'}
                    />
                  </div>
                  <div className='flex flex-col gap-2 md:mt-4 mt-0'>
                    <div className='flex flex-col md:flex-row md:justify-end gap-3'>
                      <div className={`md:hidden block ${!isMobile ? 'h-[24px]' : ''}`} />
                      {isMobile ? (
                        <>
                          <Button
                            type='submit'
                            disabled={!isValid}
                            className={`btn btn-primary btn-sm w-full md:w-auto bg-intelli-primaryNew ${
                              isMobile && 'py-4'
                            }`}
                          >
                            {isLoading ? (
                              <div className='flex items-center'>
                                Loading..&nbsp;&nbsp;
                                <BiLoader />
                              </div>
                            ) : (
                              'Look for transactions'
                            )}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type='submit'
                            className={`btn btn-primary btn-sm mt-2 w-full ml-16  bg-intelli-primaryNew md:w-auto`}
                            disabled={!isValid}
                          >
                            {isLoading ? (
                              <div className='flex items-center'>
                                Loading..&nbsp;&nbsp;
                                <BiLoader />
                              </div>
                            ) : (
                              'Look for transactions'
                            )}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </Box>
          </div>
          {orders && orders.length > 0 ? (
            <>
              {isMobile ? (
                orders.map((order: any) => (
                  <button onClick={() => setSelectedOrder(order)} key={order.id} className='w-full'>
                    <div className='bg-white rounded-lg shadow-sm p-3 mb-4 flex items-center justify-between'>
                      <div className='flex flex-column text-start pr-2'>
                        <div className='text-md'>{order.vendorName}</div>
                        <div className='text-md text-gray-500 pt-1'>
                          {order.refunded
                            ? moment(order.refundDate).format('h:mm A')
                            : moment(order.createdOn).format('h:mm A')}
                        </div>
                      </div>
                      <div
                        className={classNames('font-bold text-lg', {
                          'text-green-600': true,
                        })}
                      >
                        {(isTransactionPurchase(order) ? '-' : '+') +
                          formatCurrency(
                            isTransactionPurchase(order)
                              ? calculateOrderTotal(order) / 100
                              : order.refundTotal / 100,
                            order.currency && order.currency !== ''
                              ? (order.currency as currency)
                              : currency.CAD,
                          )}
                      </div>
                      {!isTransactionPurchase(order) ? (
                        <div className='ml-4'>Refund</div>
                      ) : (
                        <div className='ml-4 cursor-pointer'>
                          <FontAwesomeIcon icon={faChevronRight} color='#C7C7C7' />
                        </div>
                      )}
                    </div>
                  </button>
                ))
              ) : (
                <Table
                  theadData={[
                    {
                      Name: t('Transactions.columnTitles.vendor').toUpperCase(),
                      className: 'text-left w-[3%]',
                    },
                    {
                      Name: t('Transactions.columnTitles.date').toUpperCase(),
                      className: 'text-center',
                    },
                    {
                      Name: t('Transactions.columnTitles.transactions').toUpperCase(),
                      className: 'text-center',
                    },
                    {
                      Name: t('Transactions.columnTitles.amount').toUpperCase(),
                      className: 'text-center',
                    },
                    {
                      Name: t('Transactions.columnTitles.details').toUpperCase(),
                      className: 'text-center',
                    },
                  ]}
                  className='w-full rounded-lg'
                >
                  {orders.map((order: any) => {
                    return (
                      <>
                        <tr>
                          <TableCell
                            className='py-4 px-6 text-custom-primary text-base'
                            data={order.vendorName}
                          />
                          <TableCell className='text-center text-base text-[#6F7FAF]'>
                            {order.refunded
                              ? moment(order.refundDate).format('MMM DD YYYY')
                              : moment(order.createdOn).format('MMM DD YYYY')}
                          </TableCell>
                          <TableCell
                            className='py-4 px-6 text-base text-center'
                            data={isTransactionPurchase(order) ? 'Purchase' : 'Refund'}
                          />
                          <TableCell className='py-4 px-6 text-base text-[#6F7FAF] text-center'>
                            {(isTransactionPurchase(order) ? '-' : '+') +
                              formatCurrency(
                                isTransactionPurchase(order)
                                  ? calculateOrderTotal(order) / 100
                                  : order.refundTotal / 100,
                                order.currency && order.currency !== ''
                                  ? (order.currency as currency)
                                  : currency.CAD,
                              )}
                          </TableCell>
                          <TableCell className='py-4 px-6 text-base text-center'>
                            <Button
                              outline
                              color='primary'
                              className='btn btn-sm w-full md:w-auto'
                              onClick={() => setSelectedOrder(order)}
                            >
                              {t('buttons.view')}
                            </Button>
                          </TableCell>
                        </tr>
                      </>
                    );
                  })}
                </Table>
              )}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default MyTransactions;
