import React, { FC } from 'react';

// import SelectFieldBase, { SelectFieldBaseProps } from './SelectFieldBase'
import SelectFieldBase, { SelectFieldBaseProps } from './SelectFieldBaseNew';

export type SelectFieldDefaultProps = SelectFieldBaseProps;

const SelectFieldDefault: FC<SelectFieldDefaultProps> = (props) => <SelectFieldBase {...props} />;

export default SelectFieldDefault;
