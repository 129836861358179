import React, { FC, useEffect, useState } from 'react';
import { Input } from 'reactstrap';

import { UpcomingEventsTable } from './components';
import { useFetchEvents } from './hooks';

import { Heading, Loader } from 'components';
import { useUser } from 'data';
import { useAuthValue } from 'hooks/Firebase';
import { selectEventsUpcomingEventsArray, useAppSelector } from 'store';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

const Home: FC = () => {
  const { currentUser } = useAuthValue();
  const { fetchUserData } = useUser();
  const upcomingEvents = useAppSelector(selectEventsUpcomingEventsArray);
  const isMobile = useMediaQuery();
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (currentUser && currentUser?.emailVerified) {
      fetchUserData();
    }
  }, [fetchUserData, currentUser]);

  useFetchEvents();

  if (upcomingEvents === null) {
    return <Loader />;
  }

  return (
    <>
      <div className={`flex items-center ${isMobile ? 'flex-col mt-2 px-4' : 'flex-row'}`}>
        <Heading
          headingLevel='p'
          className={`flex flex-1 px-4 h-[84px] items-center text-xl leading-5 font-normal ${
            isMobile && 'mb-2'
          }`}
        >
          Welcome {currentUser?.displayName} 👋
        </Heading>
      </div>
      <div className='w-full px-[1.5rem] h-auto h-min-screen h-screen'>
        <div>
          <div className={`flex gap-4 ${isMobile ? 'flex-col' : 'flex-row'}`}>
            <Input
              type='search'
              name='search'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className='flex-grow-3 flex-basis-3/4'
              placeholder='🔎   Search by event name'
            />
          </div>

          <UpcomingEventsTable searchQuery={searchQuery} />
        </div>
      </div>
    </>
  );
};

export default Home;
