import React, { FC } from 'react';
// import { useNavigate } from 'react-router-dom';

import { useResetPasswordForm } from '../hooks';

import ResetPasswordForm from './ResetPasswordForm';
import ResetPasswordSuccess from './ResetPasswordSuccess';

import { useProvideAuth } from 'hooks/Firebase';

const ResetPassword: FC = () => {
  const { sendPasswordReset, isLoading, isResetPassword } = useProvideAuth();
  // const { control, handleSubmit, setError } = useResetPasswordForm();
  const { control, watch, onSubmit, onResend } = useResetPasswordForm();
  // const { t } = useTranslation();

  return isResetPassword ? (
    <ResetPasswordSuccess
      isLoading={isLoading}
      email={watch('email')}
      onResend={onResend(sendPasswordReset)}
    />
  ) : (
    <ResetPasswordForm
      control={control}
      isLoading={isLoading}
      onSubmit={onSubmit(sendPasswordReset)}
    />
  );
};

export default ResetPassword;
