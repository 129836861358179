import classNames from 'classnames';
import React, { FC, ReactNode, ChangeEvent, useState, useRef, useMemo } from 'react';

import { FieldWrapperProps, FieldWrapper } from '../../FieldWrapper';
import { getOptionByValue, isActiveOptionItem } from '../helpers';

import { ChevronDownIcon, CheckIcon } from 'components/icons';
import { useClickOutside } from 'hooks';

export type OptionType = {
  value: string;
  label: string;
};

export type SelectFieldBaseProps = FieldWrapperProps & {
  name?: string;
  value?: string;
  options?: Nullable<OptionType[]>;
  field?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  renderOptionItem?: (option: OptionType) => ReactNode;
};

const RightIcon = () => (
  <span className='text-[#6F7FAF] w-[10px]'>
    <ChevronDownIcon />
  </span>
);

const SelectFieldBase: FC<SelectFieldBaseProps> = ({
  renderOptionItem,
  options,
  name,
  value,
  onFocus,
  onBlur,
  onChange,
  ...fieldWrapperProps
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const optionsMenuRef = useRef(null);

  useClickOutside(optionsMenuRef, () => setIsMenuOpen(() => false));

  const toggleMenuVisibility = () => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  const getOptionItemStyles = (isActive: boolean) =>
    classNames(
      'py-2 px-3 leading-[20px] hover:bg-[#F5F3FF] cursor-pointer',
      isActive && 'bg-[#F5F3FF]',
    );

  const getDropdownMenuWrapperStyles = (isOpen: boolean) =>
    classNames(
      'w-full max-h-[223px] overflow-y-scroll absolute top-full left-0 mt-1 py-1 bg-[#fff] border-[1.5px] border-[#c4cbdd] rounded-md box-content shadow z-10',
      isOpen ? 'visible' : 'invisible',
    );

  const selectedOption = useMemo(() => getOptionByValue(value, options), [value, options]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    toggleMenuVisibility();
    onChange && onChange(e);
  };

  return (
    <FieldWrapper
      {...fieldWrapperProps}
      rightIcon={<RightIcon />}
      onClick={toggleMenuVisibility}
      className='relative cursor-pointer'
    >
      <span className='w-full'>
        {renderOptionItem ? renderOptionItem(selectedOption) : selectedOption.label}
      </span>
      <div ref={optionsMenuRef} className={getDropdownMenuWrapperStyles(isMenuOpen)}>
        {options && options.length && (
          <ul>
            {options.map((option) => (
              <li
                key={option.value}
                className={getOptionItemStyles(isActiveOptionItem(option.value, value))}
              >
                <input
                  name={name}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={handleChange}
                  className='hidden'
                  type='radio'
                  id={option.value}
                  value={option.value}
                />
                <label htmlFor={option.value} className='flex items-center justify-between'>
                  <span>{renderOptionItem ? renderOptionItem(option) : option.label}</span>
                  {isActiveOptionItem(option.value, value) && (
                    <span className='w-[14px]'>
                      <CheckIcon />
                    </span>
                  )}
                </label>
              </li>
            ))}
          </ul>
        )}
        {(!options || !options.length) && (
          <div className='flex items-center justify-center h-[150px] text-lg text-[#6F7FAF]'>
            No Data
          </div>
        )}
      </div>
    </FieldWrapper>
  );
};

export default SelectFieldBase;
