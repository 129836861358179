import React from 'react';

import { useLedger } from 'data';

const WalletId = () => {
  const { currenWalletId } = useLedger();

  const walletId = currenWalletId;
  const { NODE_ENV } = process.env;

  if (!walletId || NODE_ENV === 'production') return null;

  return <p className='text-[#f9f9f9]'>Wallet Id: {walletId}</p>;
};

export default WalletId;
