import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  AuthProvider,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useAppDispatch } from '../../store/hook';

import { useAuthValue } from './AuthContext';

import { useData } from 'data';
import { clearData } from 'data/ContentProvider';
import { resetAll } from 'store';
import { LoginFormValues, SignUpFormValues, ResetPasswordFormValues } from 'pages/auth';
import { Navigation } from 'navigation';

const PATRON_WEB_LOGIN_URL = `https://${process.env.REACT_APP_ENV_AUTH}.intellitix.com/login`;

export enum ServerErrorCode {
  WRONG_PASSWORD = 'auth/wrong-password',
  USER_NOT_FOUND = 'auth/user-not-found',
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
}

export type AuthActionType<T> = (
  data: T,
  onSuccess?: (data: T) => void,
  onReject?: (error: any) => void,
) => void;

export type ResendEmailVerificationType = (
  onSuccess?: () => void,
  onReject?: (err: any) => void,
) => void;

type LogOutType = (params?: { enableReloadOnLogOut: boolean }) => Promise<void> | void;

interface IErrorType {
  [key: string]: string;
}

const errorMsg: IErrorType = {
  'auth/user-not-found': '404',
  'auth/wrong-password': '401',
  'auth/internal-error': 'Internal Error',
  'auth/email-already-in-use': '403',
  'auth/weak-password': '406',
};
export const Providers = {
  google: new GoogleAuthProvider(),
  apple: new OAuthProvider('apple.com'),
};

export const useProvideAuth = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<string>('');
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  const [isResendMail, setIsResendMail] = useState<boolean>(false);
  const { auth } = useAuthValue();
  const { clearDataBinder } = useData();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const createEmailPassword: AuthActionType<SignUpFormValues> = useCallback(
    async (data, onSuccess, onReject) => {
      const { firstName, lastName, email, password } = data;
      const displayName = firstName + ' ' + lastName;

      setLoading(true);
      try {
        const { user } = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(user, { url: PATRON_WEB_LOGIN_URL });
        await updateProfile(user, { displayName: displayName });
        setIsEmailVerified(user.emailVerified);
        onSuccess && onSuccess(data);
      } catch (error: any) {
        setErrorCode(errorMsg[error.code]);
        onReject && onReject(error);
      }
      setLoading(false);
    },
    [auth],
  );

  const signInWithProvider = useCallback(
    async (provider: AuthProvider) => {
      setLoading(true);
      try {
        await signInWithPopup(auth, provider);
        setIsEmailVerified(true);
      } catch (error: any) {
        setErrorCode(error.code);
      }
      setLoading(false);
    },
    [auth],
  );

  function delay(t: number) {
    return new Promise(function (resolve) {
      setTimeout(resolve.bind(null), t);
    });
  }

  // clear user data and reload page after logout
  const logOut: LogOutType = useCallback(async () => {
    localStorage.removeItem('ClaimedWristband');
    signOut(auth)
      .then(async () => {
        dispatch(resetAll());
        clearData();
        clearDataBinder && clearDataBinder();
        await delay(50);
      })
      .catch((error) => {
        // An error happened.
        setErrorCode(error.message ?? 'Error signing out');
      });
  }, [auth, dispatch, clearDataBinder]);

  const loginWithEmailPassword: AuthActionType<LoginFormValues> = useCallback(
    async (data, onSuccess, onReject) => {
      const { loginEmail, loginPassword } = data;

      setLoading(true);
      try {
        // Attempt login
        // TODO This will change to check for !isRemeber and use the local persistence if checked
        // if (!isRemember)
        // await auth.setPersistence(indexedDBLocalPersistence);
        await setPersistence(auth, browserSessionPersistence).then(async () => {
          const { user } = await signInWithEmailAndPassword(auth, loginEmail, loginPassword);

          if (user.emailVerified) {
            setIsEmailVerified(user.emailVerified);
            navigate(Navigation.EVENTS);
          } else {
            toast.error('Email not Verified', { position: 'top-center' });
          }
        });

        setErrorCode('');
        onSuccess && onSuccess(data);
      } catch (error: any) {
        console.log('auth', error);
        setErrorCode(errorMsg[error.code]);
        onReject && onReject(error);
      }
      setLoading(false);
    },
    [auth, navigate],
  );

  const sendPasswordReset: AuthActionType<ResetPasswordFormValues> = useCallback(
    async (data, onSuccess, onReject) => {
      const { email } = data;

      setLoading(true);
      try {
        await sendPasswordResetEmail(auth, email, {
          url: PATRON_WEB_LOGIN_URL,
        });
        setIsResetPassword(true);
        onSuccess && onSuccess(data);
      } catch (error: any) {
        setErrorCode(error.code);
        onReject && onReject(error);
      }
      setLoading(false);
    },
    [auth],
  );

  const resendEmailVerification: ResendEmailVerificationType = useCallback(
    async (onSuccess, onReject) => {
      setLoading(true);
      setIsResendMail(true);
      if (auth.currentUser) {
        try {
          await sendEmailVerification(auth.currentUser);
          setIsResendMail(false);
          onSuccess && onSuccess();
        } catch (error: any) {
          setIsResendMail(false);
          setErrorCode(error.message);
          onReject && onReject(error);
        }
      } else {
        setErrorCode('No user is logged In');
      }
      setLoading(false);
    },
    [auth],
  );

  return {
    loginWithEmailPassword,
    signInWithProvider,
    logOut,
    sendPasswordReset,
    createEmailPassword,
    resendEmailVerification,
    isLoading,
    isEmailVerified,
    errorCode,
    isResetPassword,
    isResendMail,
  };
};
