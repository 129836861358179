import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcDinersClub,
  faCcJcb,
  faCcStripe,
  faCcPaypal,
  faCcApplePay,
  faCcAmazonPay,
} from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

library.add(
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faCcDiscover,
  faCcDinersClub,
  faCcJcb,
  faCcStripe,
  faCcPaypal,
  faCcApplePay,
  faCcAmazonPay,
  faCreditCard,
);
