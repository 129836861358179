import React, { FC } from 'react';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { t } from 'i18next';

import {
  Box,
  TextField,
  Section,
  Button,
  ControlledTextField,
  ControlledSelectField,
  Loader,
  DatePicker,
} from 'components';
import {
  selectUiIsUserDataLoading,
  selectUiIsUserDetailedInfoLoading,
  selectUserData,
  useAppSelector,
} from 'store';
import {
  getCountryOptions,
  getProvinceOptions,
} from 'pages/ManageProfileInfo/helpers/getCountryOptions';
import { useEditProfileForm } from 'pages/ManageProfileInfo/hooks/useManageProfileForm';
import { GENDER_OPTIONS } from 'pages/ManageProfileInfo/ManageProfileInfo';
import { EditFormValues, mapEditAccountData } from 'pages/ManageProfileInfo/helpers';
import { useUser } from 'data';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

const PersonalInfo: FC = () => {
  const { updateUser, fetchUserDetailedInfo } = useUser();
  const userData = useAppSelector(selectUserData);
  const { handleSubmit, control, formState, watch, reset } = useEditProfileForm(userData);
  const isUserDataLoading = useAppSelector(selectUiIsUserDataLoading);
  const isUserDetailedInfoLoading = useAppSelector(selectUiIsUserDetailedInfoLoading);
  const selectedCountry = watch('country');
  const isMobile = useMediaQuery();

  const { isDirty, isValid, isSubmitting } = formState;
  const isDisabled = isSubmitting || !isDirty || !isValid;

  const onSubmit = async (value: typeof control._formValues) => {
    try {
      await updateUser(mapEditAccountData(value as EditFormValues));
      fetchUserDetailedInfo();
      reset();
      toast.success(t('AccountEdit.profileUpdate.success'));
    } catch (error) {
      toast.error(t('AccountEdit.profileUpdate.error'));
    }
  };

  if (isUserDataLoading || isUserDetailedInfoLoading) {
    return <Loader dataTestId='loader' />;
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} data-testid='personal-info-form'>
        <div className='flex-1 md:p-4'>
          {!isMobile && (
            <div className='flex justify-end pb-3'>
              <Button
                type='submit'
                className='px-8 py-2 bg-intelli-primary rounded-md disabled:bg-slate-300 disabled:text-gray-400 disabled:cursor-not-allowed w-44'
                disabled={isDisabled}
              >
                Save
              </Button>
            </div>
          )}

          <Box className='p-12'>
            <Section title='Account Information'>
              <div className='pb-3'>
                <div className='flex flex-col md:flex-row gap-3'>
                  <ControlledTextField
                    control={control}
                    fieldName='name'
                    label='Name'
                    dataTestId='name'
                  />
                  <TextField value={userData.primaryEmail} label='Email Address' isDisabled />
                </div>
              </div>
              <div className='pb-3'>
                <div className='flex flex-col md:flex-row gap-3'>
                  <Controller
                    control={control}
                    name='dob'
                    defaultValue={moment(userData.dob).toDate().toString()}
                    render={({ field, fieldState, ...rest }) => (
                      <DatePicker
                        label='Date of Birth'
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        value={
                          // This will set the default value if userData.dob is set otherwise it will change to field.value i.e. changing the field value
                          field.value ? moment(field.value).toDate() : moment(userData.dob).toDate()
                        }
                        isError={fieldState.invalid}
                        errorMsg={fieldState.error?.message}
                        {...rest}
                      />
                    )}
                  />
                  <ControlledSelectField
                    control={control}
                    fieldName='gender'
                    label='Gender'
                    options={GENDER_OPTIONS}
                  />
                </div>
              </div>
              <div className='pb-3'>
                <ControlledTextField
                  type='tel'
                  fieldName='phoneNumber'
                  label='Phone Number'
                  control={control}
                />
              </div>
            </Section>
            <div className='w-full h-[1px] bg-[#E8EAED] mt-3 mb-3' />
            <Section title='Address Information'>
              <div className='pb-3'>
                <ControlledSelectField
                  control={control}
                  defaultValue={userData.contactInfo?.address.country}
                  fieldName='country'
                  label='Country'
                  options={getCountryOptions()}
                />
              </div>
              <div className='pb-3'>
                <div className='flex flex-col md:flex-row gap-3'>
                  <ControlledTextField
                    fieldName='streetAddress'
                    label='Street Address'
                    control={control}
                  />
                  <ControlledTextField fieldName='city' label='City' control={control} />
                </div>
              </div>
              <div className='pb-3'>
                <div className='flex flex-col md:flex-row gap-3'>
                  {(selectedCountry === 'US' || selectedCountry === 'CA') && (
                    <ControlledSelectField
                      control={control}
                      fieldName='state'
                      label='State/Province'
                      defaultValue={userData.contactInfo?.address.state}
                      options={getProvinceOptions(selectedCountry)}
                    />
                  )}
                  <ControlledTextField
                    fieldName='zip'
                    label='Zip / Postal Code'
                    control={control}
                  />
                </div>
              </div>
            </Section>
          </Box>
        </div>
        {isMobile && formState.isDirty && !formState.isSubmitting && (
          <div className='fixed bottom-0 left-0 right-0 p-3'>
            <Button
              className='w-full rounded-md mt-2 bg-intelli-primaryNew py-2'
              type='submit'
              disabled={!formState.isValid}
            >
              Save
            </Button>
          </div>
        )}
      </form>
    </>
  );
};

export default PersonalInfo;
