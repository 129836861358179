import { countries, getCountryData } from 'countries-list';
import type { ICountry, TCountryCode } from 'countries-list';

import CanadianProvinces from 'assets/collections/canada_province.json';
import USStates from 'assets/collections/us_states.json';

const sortByCountryName = (a: [string, ICountry], b: [string, ICountry]) => {
  if (a[0] === 'US' || a[0] === 'CA') {
    return -1;
  } else if (b[0] === 'US' || b[0] === 'CA') {
    return 1;
  } else {
    return a[1].name.localeCompare(b[1].name);
  }
};

export const getCountryOptions = () => {
  const sortedCountries = Object.entries(countries).sort(sortByCountryName);
  return sortedCountries.map(([code, data]) => ({
    label: data.name,
    value: code,
  }));
};

export const getProvinceOptions = (country: string) => {
  if (country === 'US') {
    return Object.values(USStates).map((state) => ({
      value: state,
      label: state,
    }));
  }

  if (country === 'CA') {
    return Object.values(CanadianProvinces).map((province) => ({
      value: province,
      label: province,
    }));
  }

  return;
};

export const getCountryNameByCode = (countryCode: string | undefined) => {
  if (countryCode) {
    return getCountryData(countryCode as TCountryCode).name;
  }

  return '';
};
