import { CaseReducer, AnyAction } from '@reduxjs/toolkit';

import { ActionStatusType } from 'store/types';

type MatcherType = (action: any) => boolean;

const getActionType = (action: AnyAction): string =>
  action.type.replace(`/${action.meta.requestStatus}`, '');

export const isRejectedAction: MatcherType = (action) =>
  action?.meta?.requestStatus === ActionStatusType.REJECTED;

export const isPendingAction: MatcherType = (action) =>
  action?.meta?.requestStatus === ActionStatusType.PENDING;

export const isFulfilledAction: MatcherType = (action) =>
  action?.meta?.requestStatus === ActionStatusType.FULFILLED;

export const addLoadingAction: CaseReducer = (state, action) => {
  state.loadingActions = [...new Set([...state.loadingActions, getActionType(action)])];
};

export const removeLoadingAction: CaseReducer = (state, action) => {
  const loadingActions = [...state.loadingActions];
  loadingActions.splice(loadingActions.indexOf(getActionType(action)), 1);
  state.loadingActions = loadingActions;
};

export const addRejectedAction: CaseReducer = (state, action) => {
  state.rejectedActions[getActionType(action)] = action.error;
};

export const removeRejectedAction: CaseReducer = (state, action) => {
  delete state.rejectedActions[getActionType(action)];
};

export const handlePendingAction: CaseReducer = (state, action) => {
  addLoadingAction(state, action);
  removeRejectedAction(state, action);
};

export const handleRejectedAction: CaseReducer = (state, action) => {
  removeLoadingAction(state, action);
  addRejectedAction(state, action);
};

export const handleFulfilledAction: CaseReducer = (state, action) => {
  removeLoadingAction(state, action);
  removeRejectedAction(state, action);
};
