import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const createClaimWristbandSchema = (isSecurityIdOnly: boolean) => {
  return yup.object().shape({
    ...(isSecurityIdOnly
      ? {
          securityId: yup
            .string()
            .matches(
              /^[A-Z\d]+$/,
              'Your Security ID must be at least 7-character alphanumeric in UPPERCASE',
            )
            .required('Security ID is required')
            .min(7),
        }
      : {
          securityId: yup
            .string()
            .matches(/^\d+$/, 'Must be only digits')
            .required('Security ID is required')
            .max(7)
            .min(4),
          wristbandId: yup.string().required('Wristband/Card UID is required'),
        }),
  });
};

export const useClaimWristbandForm = (isSecurityIdOnly: boolean) =>
  useForm({
    resolver: yupResolver(createClaimWristbandSchema(isSecurityIdOnly)),
    defaultValues: {
      wristbandId: '',
      securityId: '',
    },
    mode: 'all',
  });
