import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useClaimWristbandForm } from './hooks';

import securityIdOnly from 'assets/images/securityIdOnly.jpeg';
import wristband from 'assets/images/wristband.jpeg';
import { Heading, Box, Button, Section, ControlledTextField } from 'components';
import { useData, useLedger } from 'data';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { waitFor } from 'navigation/utils';

const ClaimWristband: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { securedApi } = useData();
  const { fetchEventWallet } = useLedger();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery();
  const [preparingWallet, setPreparingWallet] = useState(false);

  const getQueryParam = (param: string) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  const isSecurityIdOnly = getQueryParam('securityIdOnly') === 'true';
  const ledgerId = getQueryParam('ledgerId');
  const folioId = getQueryParam('folioId');
  const extractedId = folioId ? folioId.split(',id:')[1] : null;

  if (!ledgerId) {
    console.error('ledgerId is not provided in the URL parameters');
  }

  const { control, handleSubmit } = useClaimWristbandForm(isSecurityIdOnly);

  const claimWristband = async (
    folioId?: string,
    securityCode?: string,
    rfidUid?: string,
  ): Promise<void> => {
    try {
      await securedApi?.OrdersApi.claimWristband({ rfidUid, securityCode, folioId });
      toast.success('Success! Your wristband/card has been added');
      if (extractedId && ledgerId) {
        setPreparingWallet(true);
        const walletInfo = await waitFor(fetchEventWallet, [ledgerId!], 1000, 10);
        if (walletInfo) {
          navigate(`/events/${extractedId}/event-balance`);
        } else {
          navigate(`/events`);
          console.error('No wallet info received');
        }
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors?.[0] || error.message;
      console.error('Error claiming wristband:', errorMessage);
      toast.error(errorMessage);
    } finally {
      setPreparingWallet(false);
    }
  };

  const onSubmit = async ({
    wristbandId,
    securityId,
  }: {
    wristbandId?: string;
    securityId: string;
  }) => {
    const checkAndClaimWristband = async (
      rfidUid: string,
      securityCode: string,
      folioId: string,
    ) => {
      try {
        setIsLoading(true);
        if (isSecurityIdOnly) {
          await claimWristband(folioId!, securityCode);
        } else {
          const { existed: wristbandExists } = await securedApi!.OrdersApi.checkIfWristbandExists({
            rfidUid,
            securityCode,
          });

          if (wristbandExists) {
            await claimWristband(undefined, securityCode, rfidUid);
          } else {
            toast.error('Cannot find a wristband');
          }
        }
      } catch (error: any) {
        const errorMessage = error?.response?.data?.errors?.[0] || error.message;
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    try {
      if (!securityId) return;
      const rfidUid = wristbandId?.toUpperCase();
      await checkAndClaimWristband(rfidUid!, securityId, folioId!);
    } catch (error) {
      toast.error('An unexpected error occurred');
    }
  };

  return (
    <>
      <div className='flex flex-1 h-[84px] items-center'>
        <Heading headingLevel='p' className='text-[#232E59] leading-5 font-normal text-base'>
          Add Wristband/Card
        </Heading>
      </div>

      <Box removeBackground={isMobile}>
        <div className='md:flex md:justify-between '>
          <div className='md:w-1/2 md:pr-8'>
            <Section
              title='Details'
              titleClassName='text-xl md:text-3xl'
              className='col-span-12'
              description='Fill out the following information to add your wristband or card'
              isMobile={isMobile}
            />
            <div className='hidden md:block shadow-lg rounded p-[4px] max-w-[320px] '>
              <div className='rounded overflow-hidden'>
                <img
                  src={isSecurityIdOnly ? securityIdOnly : wristband}
                  alt='intellitix-wristband-reference'
                  className='w-full rounded'
                />
              </div>
            </div>
            <p className='text-[#6F7FAF] pt-6 hidden md:block'>
              {isSecurityIdOnly
                ? ''
                : ' *ID numbers will appear on a wristband, badge, or card. Not all ID numbers will befound in the same location.'}
            </p>
          </div>

          <div className='md:w-1/2'>
            <Box removeBackground={!isMobile}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {isSecurityIdOnly ? (
                  <ControlledTextField
                    labelClassName='text-base'
                    control={control}
                    label={`Security ID  ${
                      isMobile ? '' : '(7 alphanumeric code on the back of your wristband )'
                    }`}
                    fieldName='securityId'
                    placeholder={isMobile ? '4-7 digit code' : ''}
                  />
                ) : (
                  <>
                    <div className='pb-3'>
                      <ControlledTextField
                        labelClassName='text-base'
                        control={control}
                        label={`Security ID  ${isMobile ? '' : '(4-7 digit code)'}`}
                        fieldName='securityId'
                        placeholder={isMobile ? '4-7 digit code' : ''}
                      />
                    </div>
                    <div className='pb-3'>
                      <ControlledTextField
                        labelClassName='text-base'
                        control={control}
                        label={`Wristband/Card UID ${
                          isMobile
                            ? ''
                            : '(14-16 character code containing a combination of numbers and letters)'
                        }`}
                        fieldName='wristbandId'
                        placeholder={isMobile ? '14-16 character combo of numbers & letters' : ''}
                      />
                    </div>
                  </>
                )}
                <div className='flex flex-col gap-2 md:mt-4 mt-0'>
                  <div className='flex flex-col md:flex-row md:justify-end gap-3'>
                    <div className={`md:hidden block ${!isMobile ? 'h-[24px]' : ''}`} />
                    {isMobile ? (
                      <>
                        <Button
                          isLoading={isLoading}
                          loadingText={preparingWallet ? 'Preparing Account' : ''}
                          type='submit'
                          className={`btn btn-primary btn-sm w-full md:w-auto ${
                            isMobile && 'py-4'
                          }`}
                        >
                          Add Wristband/Card
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          isLoading={isLoading}
                          loadingText={preparingWallet ? 'Preparing Account' : ''}
                          type='submit'
                          className={`btn btn-primary btn-sm w-full md:w-auto ${
                            isMobile && 'py-4'
                          }`}
                        >
                          Add Wristband/Card
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </Box>

            <div
              className={`block md:hidden rounded justify-center mx-auto mt-[15px] ${
                isMobile ? '' : 'shadow-md p-[4px] '
              }`}
            >
              <div className='rounded overflow-hidden flex justify-center'>
                <img
                  src={isSecurityIdOnly ? securityIdOnly : wristband}
                  alt='intellitix-wristband-reference'
                  className='w-full rounded max-w-[320px]'
                />
              </div>
              <p className='text-[#6F7FAF] md:hidden p-6 text-sm'>
                *ID numbers will appear on a wristband, badge, or card. Not all ID numbers will be
                found in the same location.
              </p>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default ClaimWristband;
