import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faRedo } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'reactstrap';

import { Button } from '../components';

import { Navigation } from 'navigation';
import envelope from 'assets/images/envelope.png';

type ResetPasswordSuccessProps = {
  email: string;
  isLoading: boolean;
  onResend: any;
};

const ResetPasswordSuccess: FC<ResetPasswordSuccessProps> = ({ email, isLoading, onResend }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onReturnToLogin = () => {
    navigate(Navigation.LOGIN);
  };

  return (
    <>
      <img className='d-block mx-auto mb-4' src={envelope} alt='sent' width={70} />
      <h4 className='text-center mb-2'>{t('ResetPasswordSuccess.title')}</h4>
      <p className='text-center text-[#5e6e82] font-light mb-3'>
        {t('ResetPasswordSuccess.subTitle', { email })}
      </p>
      <Row className='justify-center'>
        <Button
          size='sm'
          color='primary'
          className='m-center mt-3'
          isLoading={isLoading}
          isLoadingContent={t('SignUpSuccess.button.loading')}
          onClick={() => onResend(email)}
        >
          <FontAwesomeIcon icon={faRedo} transform='shrink-4' className='mr-1' />
          {t('ResetPasswordSuccess.button.resendEmailConfirmation')}
        </Button>
      </Row>
      <Row className='justify-center'>
        <Button size='sm' color='primary' className='m-center mt-3' onClick={onReturnToLogin}>
          <FontAwesomeIcon icon={faChevronLeft} transform='shrink-4' className='mr-1' />
          {t('ResetPasswordSuccess.button.returnToLogin')}
        </Button>
      </Row>
    </>
  );
};

export default ResetPasswordSuccess;
