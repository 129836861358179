import React, { FC } from 'react';

import { getCardType, getCardCVV } from './helpers';

import { ImageSrcSet } from 'components';

type CardDigitsProps = {
  cardNumber: Nullable<string>;
};

const dotStyles = 'w-[5px] h-[5px] rounded-md flex bg-[#232E59] mr-[2px]';

const CardDigits: FC<CardDigitsProps> = ({ cardNumber }) => (
  <>
    {cardNumber && (
      <div className='flex items-center justify-items-center flex-wrap'>
        <span className='pr-2'>
          <ImageSrcSet
            alt={getCardType(cardNumber)}
            srcSet={`/card-types/${getCardType(cardNumber)}`}
            className='bg-none md:w-[45px]'
          />
        </span>
        <div className='flex items-center text-[#232E59] text-[16px] font-normal'>
          <span className='flex pr-1'>
            <span className={dotStyles} />
            <span className={dotStyles} />
            <span className={dotStyles} />
            <span className={dotStyles} />
          </span>
          <span className='flex pr-1'>
            <span className={dotStyles} />
            <span className={dotStyles} />
            <span className={dotStyles} />
            <span className={dotStyles} />
          </span>
          <span className='flex pr-1'>
            <span className={dotStyles} />
            <span className={dotStyles} />
            <span className={dotStyles} />
            <span className={dotStyles} />
          </span>
          <span className='flex'>{getCardCVV(cardNumber)}</span>
        </div>
      </div>
    )}
  </>
);

export default CardDigits;
