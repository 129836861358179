import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { MenuLinkType } from 'components';

const MobileNavTabs: FC<{ links: MenuLinkType[] }> = ({ links }) => {
  const { pathname } = useLocation();

  return (
    <div className='flex pb-3'>
      {links.map(({ to, name }) => {
        const isActive = pathname.includes(to);

        return (
          <NavLink
            key={to}
            to={to}
            className={classNames('flex-grow py-2 border-b-2 cursor-pointer', {
              'border-transparent': !isActive,
              'border-green-500': isActive,
              'text-green-500': isActive,
            })}
          >
            <div
              className={classNames('text-center', 'whitespace-normal', {
                'whitespace-nowrap': name.length <= 10,
              })}
              style={{
                color: isActive ? 'rgba(84, 158, 152, 1)' : 'rgba(107, 114, 128, 1)',
                fontSize: '16px',
              }}
            >
              {name}
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default MobileNavTabs;
