import React from 'react';

import RefundModal from './RefundModal';
import useRefundContext from './RefundContext';

import { Button } from 'components';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

type RefundFormContainerProps = {
  onSubmit: (value: any) => void;
  children: any;
  handleSubmit: any;
  validationCheck: () => void;
};

const RefundFormContainer = ({
  onSubmit,
  children,
  handleSubmit,
  validationCheck,
}: RefundFormContainerProps) => {
  const { refundRequestFailed, refundSubmitted, isLoading } = useRefundContext();
  const isMobile = useMediaQuery();

  return (
    <>
      {!refundSubmitted && !refundRequestFailed && (
        <>
          <form onSubmit={handleSubmit(onSubmit as any)}>
            {children}
            <div className={`mx-4 flex pb-4`}>
              <Button
                type='button'
                className={`${isMobile && 'w-full'} btn btn-primary`}
                onClick={validationCheck}
              >
                Submit Refund Request
              </Button>
            </div>
            <RefundModal handleSubmit={handleSubmit(onSubmit)} isLoading={isLoading} />
          </form>
        </>
      )}
    </>
  );
};

export default RefundFormContainer;
