import React, { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthValue } from './AuthContext';

import { Navigation } from 'navigation';
import { selectUserHasContactInfo, useAppSelector } from 'store';

type RestrictedRouteProps = {
  children: ReactNode;
};

const RestrictedRoute: FC<RestrictedRouteProps> = ({ children }) => {
  const { currentUser, isLoading } = useAuthValue();
  const hasContactInfo = useAppSelector(selectUserHasContactInfo);

  if (currentUser?.emailVerified && !isLoading && hasContactInfo) {
    return <Navigate to={Navigation.EVENTS} replace />;
  }

  return <>{children}</>;
};

export default RestrictedRoute;
