import { createSlice } from '@reduxjs/toolkit';

import { EventType, EventsByIdsType, TransactionsHistoryByIdType } from 'data';
import { mapArrOfObjectsByKey } from 'helpers';
import { resetAll } from 'store/common';
import { setRejectedState, setPendingState } from 'store/common/commonActions';
import {
  fetchSelectedEventByIdAction,
  fetchUpcomingEventsAction,
  fetchEventTransactionHistoryAction,
} from 'store/events';

export type EventsState = {
  selectedEvent: EventType | Record<string, never>;
  upcomingEvents: EventsByIdsType | null;
  transactionHistory: Nullable<TransactionsHistoryByIdType>;
  status: 'idle' | 'loading' | 'failed';
  response: any;
};

const initialState = {
  selectedEvent: {},
  upcomingEvents: null,
  transactionHistory: null,
  status: 'idle',
  response: null,
} as EventsState;

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setSelectedEvent: (state, action) => {
      state.selectedEvent = action.payload;
      state.status = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetAll, () => initialState)
      // fetchSelectedEventById
      .addCase(fetchSelectedEventByIdAction.pending, setPendingState)
      .addCase(fetchSelectedEventByIdAction.rejected, setRejectedState)
      .addCase(fetchSelectedEventByIdAction.fulfilled, (state, action) => {
        state.selectedEvent = action.payload;
        state.status = 'idle';
      })
      // fetchUpcomingEvents
      .addCase(fetchUpcomingEventsAction.pending, setPendingState)
      .addCase(fetchUpcomingEventsAction.rejected, setRejectedState)
      .addCase(fetchUpcomingEventsAction.fulfilled, (state, action) => {
        state.upcomingEvents = mapArrOfObjectsByKey(action.payload.entries, 'uuid');
        state.status = 'idle';
      })
      .addCase(fetchEventTransactionHistoryAction.fulfilled, (state, action) => {
        state.transactionHistory = mapArrOfObjectsByKey(action.payload, 'id');
      });
  },
});

export const { setSelectedEvent } = eventsSlice.actions;
export default eventsSlice.reducer;
