import React from 'react';

const ConfirmationIcon = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='48' height='48' rx='24' fill='#F9F9F9' />
      <g clipPath='url(#clip0_1431_29392)'>
        <path
          d='M27 29V28C27 25.7909 25.2091 24 23 24H19M19 24L22 27M19 24L22 21M31 35V19C31 17.8954 30.1046 17 29 17H17C15.8954 17 15 17.8954 15 19V35L19 33L23 35L27 33L31 35Z'
          stroke='#00303B'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1431_29392'>
          <rect width='24' height='24' fill='white' transform='translate(12 11)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConfirmationIcon;
