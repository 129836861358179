import React, { FC } from 'react';
import { Alert } from 'intellitix-shared-lib';

import { Button } from 'components';

interface RefundStateMessageI {
  refundSubmitted: boolean;
  refundRequestFailed: boolean;
  onBackCallback: () => void;
}

const RefundStateMessage: FC<RefundStateMessageI> = ({
  onBackCallback,
  refundRequestFailed,
  refundSubmitted,
}) => {
  return (
    <>
      {/* Refund was a success */}
      {refundSubmitted && !refundRequestFailed && (
        <Alert title='Refund Request Successfully Submitted' type='Success' />
      )}
      {/* Refund failed */}
      {refundSubmitted && refundRequestFailed && (
        <div>
          <Alert
            title='Refund Request Failed'
            description={
              <div className='text-red-600'>
                Please try again or contact support using the link:{' '}
                <a
                  className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600'
                  href='https://intellitixrfid.zendesk.com/hc/en-us'
                >
                  Support
                </a>
              </div>
            }
            type='Error'
          />
          <Button className='btn btn-primary mt-2' onClick={onBackCallback}>
            <div className='px-1'>Back</div>
          </Button>
        </div>
      )}
    </>
  );
};

export default RefundStateMessage;
