import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useEventsApi, useOrders, useUser, useLedger } from 'data';
import { useAccessControlApi } from 'data/services/accessControl';
import { isObjectEmpty } from 'helpers';
import {
  selectEventSelectedLedgerId,
  selectEventSelected,
  selectOrdersTickets,
  setSelectedEvent,
  selectUserName,
  setTickets,
  useAppSelector,
} from 'store';

const useFetchEventAndTickets = () => {
  const ledgerId = useAppSelector(selectEventSelectedLedgerId);
  const selectedEvent = useAppSelector(selectEventSelected);
  const tickets = useAppSelector(selectOrdersTickets);
  const userName = useAppSelector(selectUserName);
  const { fetchEventWallet } = useLedger();
  const { fetchSelectedEventById } = useEventsApi();
  const { fetchAccessTickets } = useAccessControlApi();
  const { fetchEventTickets } = useOrders();
  const { fetchUserData } = useUser();
  const { eventId } = useParams();
  const dispatch = useDispatch();

  /**
   * If previous data has been lost or page has been reloaded
   * fetch all related event data
   */
  useEffect(() => {
    if (isObjectEmpty(selectedEvent) && eventId) {
      fetchSelectedEventById(eventId);
    }
  }, [selectedEvent, eventId, fetchSelectedEventById]);

  useEffect(() => {
    if (eventId) {
      fetchAccessTickets(eventId);
    }
  }, [eventId, fetchAccessTickets]);

  useEffect(() => {
    if (isObjectEmpty(tickets) && eventId) {
      fetchEventTickets(eventId);
    }
  }, [tickets, eventId, fetchEventTickets]);

  useEffect(() => {
    if (!userName) {
      fetchUserData();
    }

    if (ledgerId) {
      fetchEventWallet(ledgerId);
    }
  }, [ledgerId, userName, fetchEventWallet, fetchUserData]);

  /**
   * Clear selected event and tickets on unmount
   */
  useEffect(
    () => () => {
      dispatch(setSelectedEvent({}));
      dispatch(setTickets({}));
    },
    [dispatch],
  );
};

export default useFetchEventAndTickets;
