import React, { FC, ReactNode } from 'react';
import { Controller } from 'react-hook-form';

type ControlledRadioButtonProps = {
  control: any;
  fieldName: string;
  label?: string | ReactNode;
  options?: any;
  defaultValue?: string;
};

const ControlledRadioButton: FC<ControlledRadioButtonProps> = ({
  control,
  fieldName,
  label,
  options,
  defaultValue = '',
}) => (
  <Controller
    control={control}
    name={fieldName}
    defaultValue={defaultValue}
    render={({ field: { onChange, value }, fieldState }) => (
      <>
        {options.map((option: any) => (
          <div
            key={option.value}
            className='border-[1.5px] border-[#c4cbdd] rounded-md box-content flex items-center w-[100%] h-[38px] mt-[4px] px-[9px] py-[13px] bg-[#ffffff] text-base shadow-[0px_1px_2px_1px_rgba(168, 51, 51, 0.05)] focus:outline-none gap-2'
          >
            <input
              type='radio'
              value={option.value}
              checked={value === option.value}
              onChange={() => onChange(option.value)}
            ></input>
            <label>{option.label}</label>
          </div>
        ))}
        <span className='text-sm text-[#6F7FAF] pl-2 leading-[18px]'>{label}</span>
        {fieldState.invalid && fieldState.error?.message && (
          <p className='text-[10px] leading-[14px] pt-1 text-[#FD563D]'>
            {fieldState.error?.message}
          </p>
        )}
      </>
    )}
  />
);

export default ControlledRadioButton;
