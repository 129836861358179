import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup } from 'reactstrap';

import { Input, Button } from '../components';
import { ResetPasswordFormValues } from '../hooks';

type ResetPasswordFormProps = {
  control: Control<ResetPasswordFormValues>;
  isLoading: boolean;
  onSubmit: () => void;
};

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ control, isLoading, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <div className='text-center'>
      <h5 className='mb-0'>{t('ResetPassword.title')}</h5>
      <small className='text-[70%] text-[#5e6e82] font-light'>{t('ResetPassword.subTitle')}</small>
      <Form className='mt-4' onSubmit={onSubmit}>
        <FormGroup>
          <Input
            name='email'
            type='email'
            className='form-control'
            placeholder={t('ResetPassword.placeholder.emailAddress')}
            control={control}
          />
        </FormGroup>
        <FormGroup>
          <Input
            name='confirmEmail'
            type='email'
            className='form-control'
            placeholder={t('ResetPassword.placeholder.emailAddressConfirm')}
            control={control}
          />
        </FormGroup>
        <FormGroup>
          <Button color='primary' block isLoading={isLoading}>
            {t('ResetPassword.button.sendResetLink')}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
