import React, { FC, ReactNode } from 'react';

import { Heading } from 'components';

type SectionTitleProps = {
  title?: string;
  subtitle?: string | ReactNode;
  isMobile?: boolean;
};

const SectionTitle: FC<SectionTitleProps> = ({ title, subtitle, isMobile }) => {
  return (
    <>
      {!title && !subtitle && null}
      {title && (
        <Heading headingLevel='div' className='text-lg leading-6 font-bold text-base'>
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading
          headingLevel='div'
          className={`mt-2 leading-5 font-normal ${
            isMobile ? 'text-xl' : 'text-base'
          } text-[#232E59]`}
        >
          {subtitle}
        </Heading>
      )}
    </>
  );
};
export default SectionTitle;
