import React, { FC } from 'react';
import classNames from 'classnames';

import { Spinner } from 'components';

type SwitchProps = {
  isChecked?: boolean;
  name?: string;
  isLoading?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const Switch: FC<SwitchProps> = ({ onChange, isChecked = false, name = '', isLoading = false }) => {
  return (
    <>
      <label className='relative inline-flex items-center cursor-pointer'>
        <input
          type='checkbox'
          value=''
          className='sr-only peer'
          name={name}
          checked={isChecked}
          title={name}
          onChange={onChange}
        />
        <div className='w-8 h-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full dark:bg-gray-700 dark:border-gray-600 peer-checked:bg-blue-600' />
        {isLoading ? (
          <div
            className={classNames(
              'peer-checked:translate-x-full absolute top-[8px]',
              isChecked ? 'left-[10px]' : 'left-[2px]',
            )}
          >
            <Spinner color={isChecked ? 'gray' : 'white'} size='[10px]' />
          </div>
        ) : (
          <div
            className={classNames(
              'peer-checked:translate-x-full peer-checked:border-white absolute top-[7px] bg-white border-gray-300 border rounded-full h-[12px] w-[12px] transition-all dark:border-gray-600 peer-checked:bg-blue-600',
              isChecked ? 'left-[6px]' : 'left-[1px]',
            )}
          />
        )}
      </label>
    </>
  );
};

export default Switch;
