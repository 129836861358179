import classNames from 'classnames';
import React, { FC, ReactNode, useState, useRef } from 'react';

import { FieldWrapperProps, FieldWrapper } from '../../FieldWrapper';
import { getOptionByValue, isActiveOptionItem } from '../helpers';

import { ChevronDownIcon, CheckIcon } from 'components/icons';
import { useClickOutside } from 'hooks';

export type OptionType = {
  value: string;
  label: string;
};

export type SelectFieldBaseProps = FieldWrapperProps & {
  defaultValue?: string;
  options?: Nullable<OptionType[]>;
  onChange?: (option: OptionType, e: React.MouseEvent<HTMLLIElement>) => void;
  renderOptionItem?: (option: OptionType) => ReactNode;
};

const RightIcon = () => (
  <span className='text-[#6F7FAF] w-[10px]'>
    <ChevronDownIcon />
  </span>
);

const SelectFieldBase: FC<SelectFieldBaseProps> = ({
  defaultValue = '',
  renderOptionItem,
  options,
  onChange,
  ...fieldWrapperProps
}) => {
  const [selectedOption, setSelectedOption] = useState<OptionType>(() =>
    getOptionByValue(defaultValue, options),
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const optionsMenuRef = useRef(null);

  useClickOutside(optionsMenuRef, () => setIsMenuOpen(() => false));

  const toggleMenuVisibility = () => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  const onSelectOptionClick = (option: OptionType, e: React.MouseEvent<HTMLLIElement>): void => {
    onChange && onChange(option, e);
    setSelectedOption(option);
  };

  const getOptionItemStyles = (isActive: boolean) =>
    classNames(
      'py-2 px-3 leading-[20px] hover:bg-[#F5F3FF] cursor-pointer flex items-center justify-between',
      isActive && 'bg-[#F5F3FF]',
    );

  return (
    <FieldWrapper
      {...fieldWrapperProps}
      rightIcon={<RightIcon />}
      onClick={toggleMenuVisibility}
      className='relative cursor-pointer'
    >
      <span>{renderOptionItem ? renderOptionItem(selectedOption) : selectedOption.label}</span>
      {isMenuOpen && (
        <div className='w-full max-h-[223px] overflow-y-scroll absolute top-full left-0 mt-1 py-1 bg-[#fff] border-[1.5px] border-[#c4cbdd] rounded-md box-content shadow z-10'>
          {options && options.length && (
            <ul ref={optionsMenuRef}>
              {options.map((option) => (
                <li
                  key={option.value}
                  onClick={(e) => onSelectOptionClick(option, e)}
                  className={getOptionItemStyles(
                    isActiveOptionItem(option.value, selectedOption.value),
                  )}
                >
                  <span>{renderOptionItem ? renderOptionItem(option) : option.label}</span>
                  {isActiveOptionItem(option.value, selectedOption.value) && (
                    <span className='w-[14px]'>
                      <CheckIcon />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          )}
          {(!options || !options.length) && (
            <div className='flex items-center justify-center h-[150px] text-lg text-[#6F7FAF]'>
              No Data
            </div>
          )}
        </div>
      )}
    </FieldWrapper>
  );
};

export default SelectFieldBase;
