import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { isPositiveAmount } from '../helpers';

import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { getLinkWithParams } from 'navigation/utils';
import { Navigation } from 'navigation';
import { useData } from 'data';
import { Spinner } from 'components';

const Cell = ({ className, children }: any) => <div className={className}>{children}</div>;

const TransactionItem = ({
  transactionEntry,
  eventId,
}: {
  transactionEntry: any;
  eventId: string;
}) => {
  const isMobile = useMediaQuery();
  const [vendor, setVendor] = useState<ReactNode | string>(
    <div className='flex justify-center'>{isMobile ? '' : <Spinner />}</div>,
  );

  const { securedApi } = useData();

  const { t } = useTranslation();

  const { id, date, createdOn, transaction, amount } = transactionEntry;

  useEffect(() => {
    const fetchOrder = async () => {
      if (transaction === 'Top-Up') {
        setVendor('Intellitix');
      }

      const order = (await securedApi?.OrdersApi.getOrderById(id)) || undefined;

      if (!order) {
        console.error(`Transaction[${id}] (order) not found`);
        return;
      }

      if (order.folioId && order.products[0]?.productKind !== 'token') {
        const vendorInfo = await securedApi!.OrdersApi.getVendorByFolioId(order.folioId!);

        setVendor(vendorInfo.vendorName);
      } else {
        setVendor('Intellitix');
      }
    };

    fetchOrder();
  }, [id, transaction, securedApi]);

  const withLink = (node: ReactElement<any, any>): ReactElement<any, any> => {
    if (transaction.toLowerCase() === 'refund') {
      return node;
    } else {
      return (
        <Link
          to={`${getLinkWithParams(Navigation.TRANSACTION_DETAILS, {
            eventId: eventId!,
            transactionId: id,
          })}?hideLayoutHeader=true`}
        >
          {node}
        </Link>
      );
    }
  };

  return isMobile ? (
    withLink(
      <div
        key={id}
        className='bg-white rounded-lg shadow-sm p-3 mb-4 flex items-center justify-between'
      >
        <div className='flex-1 pr-2'>
          <div className='text-md'>
            {vendor} {transaction}
          </div>
          <div className='text-md text-gray-500 pt-1'>{moment(createdOn).format('h:mm A')}</div>
        </div>
        <div
          className={classNames('font-bold text-lg', {
            'text-green-600': isPositiveAmount(amount),
          })}
        >
          {amount}
        </div>
        {transaction !== 'Refund' ? (
          <div className='ml-4 cursor-pointer'>
            <FontAwesomeIcon icon={faChevronRight} color='#C7C7C7' />
          </div>
        ) : null}
      </div>,
    )
  ) : (
    <>
      <div key={id} className='flex border-b justify-between items-center py-2 px-6'>
        <Cell className='flex-1 text-center font-normal text-[#232E59] text-xl py-3'>{vendor}</Cell>
        <Cell className='flex-1 text-center py-3 text-[#6F7FAF] font-light'>{date}</Cell>
        <Cell className='flex-1 text-center py-3 text-[#6F7FAF] font-light'>{transaction}</Cell>
        <Cell className='flex-1 text-center py-3 text-[#6F7FAF] font-light'>{amount}</Cell>
        <Cell className='flex-1 text-center py-3 text-[#6F7FAF] font-light'>
          <Link
            to={getLinkWithParams(Navigation.TRANSACTION_DETAILS, {
              eventId: eventId!,
              transactionId: id,
            })}
          >
            <Button outline color='primary' className='btn btn-sm w-full md:w-auto'>
              {t('buttons.view')}
            </Button>
          </Link>
        </Cell>
      </div>
    </>
  );
};

export default TransactionItem;
