export type NavigationParams = {
  [Navigation.TRANSACTIONS]: { eventId: string };
  [Navigation.TICKETS]: { eventId: string };
  [Navigation.EVENT_DETAILS]: { eventId: string };
  [Navigation.EVENT_BALANCE]: { eventId: string };
  [Navigation.REFUND_REQUEST]: { eventId: string };
  [Navigation.TICKET_DETAILS]: { eventId: string; ticketId: string };
  [Navigation.TRANSACTION_DETAILS]: {
    eventId: string;
    transactionId: string;
  };
};

export enum Navigation {
  AUTH = '/auth',
  LOGIN = '/login',
  RESET_PASSWORD = '/reset-password',
  SIGN_UP = '/sign-up',
  PROFILE = '/profile',
  CARDS = '/cards',
  ADD_CARD = '/add-card',
  MY_TRANSACTIONS = '/my-transactions',
  HOME = '/',
  EVENTS = '/events',
  TRANSACTIONS = '/events/:eventId/transactions',
  TICKETS = '/events/:eventId/tickets',
  EVENT_DETAILS = '/events/:eventId/details',
  EVENT_BALANCE = '/events/:eventId/event-balance',
  REFUND_REQUEST = '/events/:eventId/refund-request',
  TICKET_DETAILS = '/events/:eventId/tickets/:ticketId/details',
  TRANSACTION_DETAILS = '/events/:eventId/transactions/:transactionId/details',
  SETTINGS = '/settings',
  USER_PROFILE = '/settings/user-profile',
  PAYMENT_METHODS = '/settings/payment-methods',
  SUPPORT = 'https://intellitixrfid.zendesk.com/hc/en-us',
  TERMS = 'https://intellitix.com/about/legal/privacy-policy',
  CLAIM_WRISTBAND = '/add-wristband',
  RESET_WITH_PASSWORD = '/reset-with-password',
  SIGN_OUT = '/sign-out',
  MANAGE_PROFILE = '/manage-profile-info',
}
