import { useState, useEffect } from 'react';

const MIN_WIDTH = 900;
function useMediaQuery() {
  const [isMobile, setIsMobile] = useState(window?.innerWidth <= MIN_WIDTH);

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= MIN_WIDTH);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return isMobile;
}

export const isSmallMobileScreen = () => {
  return window.innerHeight <= 740; // Height of iPhone SE. (+ iPhone 8, 7 & 6)
};

export default useMediaQuery;
