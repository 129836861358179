import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

type RefundContextType = {
  modalIsOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  refundSubmitted: boolean;
  setRefundSubmitted: Dispatch<SetStateAction<boolean>>;
  refundRequestFailed: boolean;
  setRefundRequestFailed: Dispatch<SetStateAction<boolean>>;
  resetFormState: () => void;
  isRefundFormValid: boolean;
  setIsRefundFormValid: Dispatch<SetStateAction<boolean>>;
};

const RefundContext = createContext<RefundContextType | undefined>(undefined);

type RefundProviderProps = {
  children: ReactNode;
};

export const RefundProvider: React.FC<RefundProviderProps> = ({ children }: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refundSubmitted, setRefundSubmitted] = useState(false);
  const [refundRequestFailed, setRefundRequestFailed] = useState(false);
  const [isRefundFormValid, setIsRefundFormValid] = useState(true);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const resetFormState = () => {
    setRefundRequestFailed(false);
    setIsRefundFormValid(true);
  };
  const contextValue: RefundContextType = {
    modalIsOpen,
    openModal,
    closeModal,
    isLoading,
    setIsLoading,
    refundSubmitted,
    setRefundSubmitted,
    refundRequestFailed,
    setRefundRequestFailed,
    resetFormState,
    isRefundFormValid,
    setIsRefundFormValid,
  };

  return <RefundContext.Provider value={contextValue}>{children}</RefundContext.Provider>;
};

const useRefundContext = () => {
  const context = useContext(RefundContext);
  if (!context) {
    throw new Error('useRefundContext must be used within a RefundProvider');
  }
  return context;
};

export default useRefundContext;
