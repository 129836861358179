import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';

import MessageModal from 'components/MessageModal';
import { useEventsApi } from 'data';
import useLedger from 'data/useLedger';
import usePayments from 'data/usePayments';
import { PAYMENT_STATUS_END_STATES } from 'store/payments/paymentsReducer';

const PaymentLoader = () => {
  const { t } = useTranslation();
  const { eventId } = useParams();
  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const { paymentStatus, clearPaymentStatus } = usePayments();
  const { fetchEventWalletAndDetailsOfSelectedEvent } = useLedger();
  const { fetchEventTransactionHistory } = useEventsApi();
  const [message, setMessage] = useState('');
  const toggleAll = () => {
    setNestedModal(false);
    setModal(false);
    clearPaymentStatus();
  };

  useEffect(() => {
    if (paymentStatus) {
      if (PAYMENT_STATUS_END_STATES.includes(paymentStatus)) {
        if (paymentStatus !== 'payment-succeeded') {
          setNestedModal(true);
          setMessage(t('paymentLoader.errorMessage'));
          fetchEventWalletAndDetailsOfSelectedEvent(); // reset the event wallet
        } else {
          setNestedModal(true);
          setMessage(t('paymentLoader.successMessage'));
          if (eventId) fetchEventTransactionHistory(eventId);
        }
      } else {
        setModal(true);
      }
    }
  }, [
    paymentStatus,
    eventId,
    clearPaymentStatus,
    setModal,
    setNestedModal,
    fetchEventTransactionHistory,
    fetchEventWalletAndDetailsOfSelectedEvent,
    t,
  ]);

  return (
    <>
      <Modal isOpen={modal} centered size={'lg'} backdrop='static'>
        <ModalHeader>{t('paymentLoader.processing')}</ModalHeader>
        <ModalBody>
          <div className='d-flex justify-content-center'>
            <Spinner color='primary' />
          </div>
          <label className='d-flex justify-content-center'>{t('paymentLoader.pleaseWait')}</label>
          <label className='d-flex justify-content-center'>{paymentStatus}</label>
          <MessageModal message={message} isOpen={nestedModal} toggleFunction={toggleAll} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default PaymentLoader;
