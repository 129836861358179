import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation, type TFunction } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { useCountdown } from './useCountdown';

import { ServerErrorCode, ResendEmailVerificationType, AuthActionType } from 'hooks/Firebase';
import { emailRegExp } from 'validation';

export type SignUpFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  checkTerms?: boolean;
};

const signUpSchema = (t: TFunction) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(t('SignUp.error.firstNameRequired'))
      .min(2, t('SignUp.error.minimumLength')),
    lastName: yup
      .string()
      .required(t('SignUp.error.lastNameRequired'))
      .min(2, t('SignUp.error.minimumLength')),
    email: yup
      .string()
      .required(t('SignUp.error.emailAddressRequired'))
      .matches(emailRegExp, t('SignUp.error.invalidEmailAddress'))
      .min(5, t('SignUp.error.emailMinimumLength')),
    password: yup
      .string()
      .required(t('SignUp.error.passwordRequired'))
      .min(6, t('SignUp.error.passwordMinimumLength')),
    confirmPassword: yup
      .string()
      .required(t('SignUp.error.passwordRequired'))
      .oneOf([yup.ref('password')], t('SignUp.error.passwordMatch')),
    checkTerms: yup.bool().oneOf([true], t('SignUp.error.termsRequired')),
  });

export const useSignUpForm = () => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const { seconds, setSeconds } = useCountdown();
  const { t } = useTranslation();
  const form = useForm<SignUpFormValues>({
    resolver: yupResolver(signUpSchema(t)),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      checkTerms: false,
    },
  });

  const onSuccess = ({ email }: SignUpFormValues) => {
    setIsSuccess(true);
    setSeconds(60);
    toast.success(t('SignUpSuccess.success', { email }) as string);
  };

  const onReject = (error: any) => {
    setIsSuccess(false);
    if (error.code === ServerErrorCode.EMAIL_ALREADY_IN_USE) {
      form.setError('email', {
        type: 'server',
        message: t('SignUp.error.emailAlreadyRegistered'),
      });
    }
  };

  const onResend = (resendEmailVerification: ResendEmailVerificationType) => {
    resendEmailVerification(
      () => {
        setSeconds(60);
        toast.success(t('SignUpSuccess.success', { email: form.getValues('email') }) as string);
      },
      (err: any) => {
        toast.error(err.message);
      },
    );
  };

  const onSubmit = (createEmailPassword: AuthActionType<SignUpFormValues>) =>
    form.handleSubmit((data) => {
      if (!isSuccess) {
        createEmailPassword(data, onSuccess, onReject);
      }
    });

  return { onSubmit, onResend, isSuccess, seconds, ...form };
};
