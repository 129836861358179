import React, { FC, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { useAuthValue } from './AuthContext';

import { Navigation } from 'navigation';
import {
  selectUserDetailedInfoLoading,
  selectUserHasContactInfo,
  selectUserHasGender,
  selectUserHasDateOfBirth,
  useAppSelector,
  selectUserIsLoading,
  selectUserData,
} from 'store';
import { useData, useUser } from 'data';
import { Loader } from 'components';

const PrivateRoute: FC = () => {
  const { currentUser, isLoading } = useAuthValue();
  const { fetchUserData } = useUser();
  const userData = useAppSelector(selectUserData);
  const hasContactInfo = useAppSelector(selectUserHasContactInfo);
  const hasGender = useAppSelector(selectUserHasGender);
  const hasDateOfBirth = useAppSelector(selectUserHasDateOfBirth);
  const isDetailedUserInfoLoading = useAppSelector(selectUserDetailedInfoLoading);
  const userInfoLoading = useAppSelector(selectUserIsLoading);
  const hasUserDetailedInfo = Object.keys(userData).length;
  const { binderState } = useData();
  const navigate = useNavigate();
  useEffect(() => {
    if (binderState !== 'loading' && currentUser && currentUser.emailVerified) {
      fetchUserData();
    }
  }, [currentUser, fetchUserData, binderState]);

  if (!currentUser?.emailVerified && !isLoading) {
    return <Navigate to={Navigation.LOGIN} replace />;
  }

  if (!hasUserDetailedInfo) {
    return <Loader />;
  }

  if (
    currentUser?.emailVerified &&
    !isLoading &&
    !isDetailedUserInfoLoading &&
    !userInfoLoading &&
    (!hasGender || !hasDateOfBirth || !hasContactInfo) &&
    location.pathname !== Navigation.MANAGE_PROFILE
  ) {
    navigate(Navigation.MANAGE_PROFILE);
  }

  return <Outlet />;
};
export default PrivateRoute;
