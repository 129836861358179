import React, { useEffect, FC } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Row, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as MobileLogo } from 'assets/images/logo-mobile.svg';
import { Navigation } from 'navigation';
import '../auth.css';
import useMediaQuery, { isSmallMobileScreen } from 'hooks/mediaQuery/useMediaQuery';

const AuthLayout: FC = () => {
  const isMobile = useMediaQuery();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const shouldHideHeader = Boolean(searchParams.get('hideHeader'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (isMobile) {
    return (
      <div className='bg-white'>
        <div className='flex flex-col h-screen px-4'>
          {shouldHideHeader ? null : (
            <div
              className={`${
                isSmallMobileScreen() ? 'h-[80px]' : 'h-1/5'
              } flex flex-row justify-center items-center`}
            >
              <a className='flex' title='Intellitix' href={Navigation.LOGIN}>
                <MobileLogo width={isSmallMobileScreen() ? 200 : 300} className='logo' />
              </a>
            </div>
          )}
          <Outlet />
        </div>
      </div>
    );
  }

  return (
    <section className='auth py-0 bg-[#f2f5ff]'>
      <Container>
        <Row className='justify-center items-center min-vh-100 py-6'>
          <Col sm={10} md={8} lg={6} xl={5} className='col-xxl-4'>
            <a className='flex justify-center mb-4' title='Intellitix' href={Navigation.LOGIN}>
              <Logo width={300} className='logo' />
            </a>
            <Card className='card border-none shadow-[0_7px_14px_0_rgba(59,65,94,.1),0_3px_6px_0_rgba(0,0,0,.07)]'>
              <CardBody className='fs--1 font-weight-normal p-5'>
                <Outlet />
              </CardBody>
            </Card>
            <div className='form-support flex items-center justify-center text-[1.2rem] mt-2 text-center'>
              <a
                href={Navigation.SUPPORT}
                target='_blank'
                rel='noreferrer'
                className='fs--1 font-[Open Sans] !text-[#748194] leading-6 font-extralight'
              >
                {t('AuthLayout.troubleLoggingIn')}
                <br />
                {t('AuthLayout.contactSupport')}
                <span className='d-inline-block ml-1'>&rarr;</span>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AuthLayout;
