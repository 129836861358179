import { createAsyncThunk } from '@reduxjs/toolkit';

export enum EventActions {
  FETCH_SELECTED_EVENT_BY_ID = 'events/fetchSelectedEventById',
  FETCH_UPCOMING_EVENTS = 'events/fetchUpcomingEvents',
  FETCH_TRANSACTION_HISTORY = 'events/fetchEventTransactionHistory',
}

export const fetchSelectedEventByIdAction = createAsyncThunk(
  EventActions.FETCH_SELECTED_EVENT_BY_ID,
  async ({ securedApi, id }: any) => {
    const response = await securedApi.EventsApi.fetchEvent(id);
    if (response.eventDetails?.tokensFolioId) {
      const currencyResponse = await securedApi.OrdersApi.getCurrency(
        response.eventDetails?.tokensFolioId,
      );

      response.currencyCode = currencyResponse.currencyCode;
    }
    return response;
  },
);

export const fetchUpcomingEventsAction = createAsyncThunk(
  EventActions.FETCH_UPCOMING_EVENTS,
  async ({ securedApi }: any) => {
    const response = await securedApi.EventsApi.fetchUpcomingEvents();
    return response;
  },
);

export const fetchEventTransactionHistoryAction = createAsyncThunk(
  EventActions.FETCH_TRANSACTION_HISTORY,
  async ({ securedApi, eventId }: any) => {
    const response = await securedApi.MiddlewareApi.fetchEventTransactionHistory(eventId);
    return response;
  },
);
