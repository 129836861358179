import React, { FC } from 'react';
import {
  Spinner,
  Button as ReactStrapButton,
  ButtonProps as ReactStrapButtonProps,
} from 'reactstrap';

const Button: FC<ReactStrapButtonProps> = ({
  children,
  isLoading,
  isDisabled,
  isLoadingContent,
  ...restProps
}) => (
  <ReactStrapButton
    cssModule={{
      btn: 'btn-auth',
      'btn-sm': 'btn-auth-sm',
      'btn-primary': 'btn-auth-primary',
    }}
    disabled={isLoading || isDisabled}
    {...restProps}
  >
    {isLoading ? (
      <div className='items-center justify-center'>
        <Spinner size='sm' color='white' />
        <span className='pl-2'>{isLoadingContent}</span>
      </div>
    ) : (
      children
    )}
  </ReactStrapButton>
);

export default Button;
