import { NavigationParams } from './routes';

export const getLinkWithParams = <T extends keyof NavigationParams>(
  link: T,
  params: NavigationParams[T],
) => {
  let linkWithParams = link as string;

  for (const param in params) {
    if (params[param]) {
      linkWithParams = linkWithParams.replace(`:${param}`, params[param] as string);
    }
  }

  return linkWithParams;
};

export const waitFor = async (
  request: (...args: any) => Promise<any>,
  args: any[],
  delay: number,
  retries = 3,
  interval = 1500,
) => {
  let attempt = 0;
  if (delay) {
    await new Promise((resolve) => setTimeout(resolve, delay));
  }
  while (attempt < retries) {
    try {
      attempt++;
      const response = await request(...args);
      if (response !== undefined) {
        return response;
      }
      if (attempt === retries) {
        console.log('No data was retrieved after: ' + retries + 'retries');
        return null;
      }
      await new Promise((resolve) => setTimeout(resolve, interval));
    } catch (error) {
      console.log('Error occurred while fetching data');
      return null;
    }
  }
};
