import React, { FC } from 'react';

import { TableHead } from './components';

type THeadType = {
  Name: string;
  className: string;
};

type TableProps = {
  children?: React.ReactNode;
  className?: string;
  theadData?: THeadType[];
};

const Table: FC<TableProps> = ({ className, theadData, children }) => (
  <table className={`text-[#6F7FAF] text-left w-full bg-white rounded-lg ${className}`}>
    <thead>
      <tr>
        {theadData?.map((h: THeadType) => (
          <TableHead key={h.Name} item={h.Name} className={h.className} />
        ))}
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
);

export default Table;
