import React from 'react';

const SignOutIcon = () => (
  <svg
    style={{ width: 'inherit', height: 'inherit' }}
    viewBox='0 0 20 18'
    stroke='currentColor'
    fill='none'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M15 13L19 9M19 9L15 5M19 9L5 9M11 13V14C11 15.6569 9.65686 17 8 17H4C2.34315 17 1 15.6569 1 14V4C1 2.34315 2.34315 1 4 1H8C9.65686 1 11 2.34315 11 4V5' />
  </svg>
);

export default SignOutIcon;
