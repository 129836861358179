import { configureStore } from '@reduxjs/toolkit';

import { accessControlReducer } from './accessControl';
import { eventsReducer } from './events';
import { ledgerReducer } from './ledger';
import { ordersReducer } from './orders';
import { paymentsReducer } from './payments';
import { uiReducer } from './ui';
import { userReducer } from './user';

const store = configureStore({
  reducer: {
    ordersData: ordersReducer,
    eventsData: eventsReducer,
    accessControlData: accessControlReducer,
    paymentsData: paymentsReducer,
    ledgerData: ledgerReducer,
    user: userReducer,
    ui: uiReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
