import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const selectLedgerData = (state: RootState) => state.ledgerData;
export const selectWallets = (state: RootState) => state.ledgerData.wallets;
export const selectStatus = (state: RootState) => state.ledgerData.isLoading;
export const selectUpdatingStatus = (state: RootState) => state.ledgerData.isUpdatingWallet;

export const selectWalletByLedgerId = createSelector(
  [selectWallets, (_state, ledgerId) => ledgerId],
  (wallets, ledgerId) => wallets[ledgerId],
);

export const selectWalletIdByLedgerId = createSelector(
  [selectWallets, (_state, ledgerId) => ledgerId],
  (wallets, ledgerId) => wallets[ledgerId]?.entry?.id,
);
