import React, { FC, ReactNode } from 'react';

type TableRowProps = {
  children?: ReactNode;
  className?: string;
  key?: string;
  data?: string | number | null | undefined;
};

const TableCell: FC<TableRowProps> = ({ key, className, data, children }) => (
  <td key={key} className={`border-b text-base ${className}`}>
    {data}
    {children}
  </td>
);

export default TableCell;
