import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { Spinner } from 'components';

type ButtonProps = {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string | undefined;
};

const spinnerClassName = 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2';

const Button: FC<ButtonProps> = ({
  type,
  className,
  onClick,
  disabled,
  isLoading,
  children,
  loadingText,
  ...restProps
}) => {
  const loadingTextDefined = loadingText && loadingText.length > 0;

  return (
    <button
      type={type}
      className={classNames(className, 'relative')}
      onClick={onClick}
      disabled={disabled || isLoading}
      {...restProps}
    >
      {isLoading && loadingTextDefined ? (
        <span>{loadingText}</span>
      ) : (
        <span className={classNames(isLoading && 'invisible')}>{children}</span>
      )}

      {isLoading && (
        <span className={loadingTextDefined ? 'ml-2' : spinnerClassName}>
          <Spinner />
        </span>
      )}
    </button>
  );
};

export default Button;
