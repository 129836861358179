import { createSlice } from '@reduxjs/toolkit';

import { UserDataType } from 'data';
import { resetAll } from 'store/common';
import { fetchUserDataAction, fetchUserDetailedInfoAction } from 'store/user';

export type UserStateType = {
  data: UserDataType | Record<string, never>;
  status: 'idle' | 'loading' | 'failed'; // Status for fetchUserDataAction
  detailedInfoStatus: 'idle' | 'loading' | 'failed'; // Status for fetchUserDetailedInfoAction
  response: any;
};

const initialState = {
  data: {},
  status: 'idle',
  detailedInfoStatus: 'idle',
  response: null,
} as UserStateType;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
      state.status = 'idle';
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetAll, () => initialState)
      // fetchUserData
      .addCase(fetchUserDataAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserDataAction.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchUserDataAction.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'idle';
      })
      // fetchUserDetailedInfo
      .addCase(fetchUserDetailedInfoAction.pending, (state) => {
        state.detailedInfoStatus = 'loading';
      })
      .addCase(fetchUserDetailedInfoAction.rejected, (state) => {
        state.detailedInfoStatus = 'failed';
      })
      .addCase(fetchUserDetailedInfoAction.fulfilled, (state, action) => {
        state.data = action.payload;
        state.detailedInfoStatus = 'idle';
      });
  },
});

export const { setUserData } = userSlice.actions;
export default userSlice.reducer;
