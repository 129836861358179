import { useCallback, useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../store/hook';
import {
  pollPayment,
  clearPayment,
  fetchPaymentMethods,
  createPaymentMethod,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  processPaymentWithExistingPaymentMethod,
  processPaymentWithSpreedlyToken,
  createSpreedlyCreditCard,
} from '../store/payments/paymentsReducer';

import { useData } from './ContentProvider';
import { CardInfo } from './model';

import {
  selectPaymentMethodsArray,
  selectPaymentStatus,
  selectStatus,
} from 'store/payments/paymentsSelectors';

const usePayments = () => {
  const [error, _setError] = useState<unknown | any>();
  const { securedApi } = useData();
  const paymentMethods = useAppSelector(selectPaymentMethodsArray);
  const paymentStatus: any = useAppSelector(selectPaymentStatus);
  const status: any = useAppSelector(selectStatus);
  const [isSuccess, _setSuccess] = useState<boolean>(); // TODO: modify
  const [isLoading, _setLoading] = useState<boolean>(false); // TODO: modify
  const dispatch = useAppDispatch();

  const clearPaymentStatus = useCallback(() => {
    dispatch(clearPayment());
  }, [dispatch]);

  const getAllPaymentMethods = useCallback(async () => {
    return dispatch(fetchPaymentMethods(securedApi));
  }, [dispatch, securedApi]);

  useEffect(() => {
    if (!paymentMethods && securedApi) {
      getAllPaymentMethods();
    }
  }, [securedApi, getAllPaymentMethods, paymentMethods]);

  useEffect(() => {
    _setLoading(status === 'loading');
  }, [status]);

  const changeDefaultPayment = useCallback(
    async (uuid: string) => {
      return dispatch(setDefaultPaymentMethod({ uuid, securedApi }));
    },
    [dispatch, securedApi],
  );

  const addPaymentMethod = useCallback(
    async (cardInfo: CardInfo) => {
      // Add as defualt payment method if no payment methods exist
      if (paymentMethods?.length === 0) {
        cardInfo.default = true;
      }
      return dispatch(createPaymentMethod({ cardInfo, securedApi }));
    },
    [dispatch, paymentMethods, securedApi],
  );

  const deletePaymentMethodByUuid = useCallback(
    async (uuid: string) => {
      const isDefault = paymentMethods.find((method) => method.uuid === uuid)?.default;
      const replacements = paymentMethods.filter((method) => method.uuid !== uuid);
      const replacementId = isDefault && !!replacements.length ? replacements[0].uuid : null;
      replacementId ? changeDefaultPayment(replacementId) : null;
      return dispatch(deletePaymentMethod({ uuid, securedApi }));
    },
    [changeDefaultPayment, dispatch, paymentMethods, securedApi],
  );

  const processWithSavedPaymentMethod = useCallback(
    async (paymentId: string, paymentMethodId: string) => {
      return dispatch(
        processPaymentWithExistingPaymentMethod({ paymentId, paymentMethodId, securedApi }),
      );
    },
    [dispatch, securedApi],
  );

  const pollPaymentStatus = useCallback(
    async (paymentId: string) => {
      return dispatch(pollPayment({ paymentId, dispatch, securedApi }));
    },
    [dispatch, securedApi],
  );

  const tokenizeCreditCard = useCallback(
    async (cardInfo: CardInfo) => {
      return dispatch(createSpreedlyCreditCard({ cardInfo, securedApi }));
    },
    [dispatch, securedApi],
  );

  const processWithNewPaymentMethod = useCallback(
    async (paymentId: string, spreedlyToken: string) => {
      return dispatch(processPaymentWithSpreedlyToken({ paymentId, spreedlyToken, securedApi }));
    },
    [dispatch, securedApi],
  );

  return {
    error,
    status,
    paymentMethods,
    isSuccess,
    isLoading,
    paymentStatus,
    clearPaymentStatus,
    pollPaymentStatus,
    getAllPaymentMethods,
    addPaymentMethod,
    deletePaymentMethodByUuid,
    changeDefaultPayment,
    processWithSavedPaymentMethod,
    processWithNewPaymentMethod,
    tokenizeCreditCard,
  };
};
export default usePayments;
