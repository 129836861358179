import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { resetAll } from 'store/common';

export enum LedgerActions {
  FETCH_WALLET = 'ledger/fetchWallet',
  FETCH_WALLET_DETAILS = 'ledger/fetchWalletDetails',
}

type LedgersKeyValue = {
  [key: string]: any;
};

type WalletsKeyValue = {
  [key: string]: any;
};

export type LedgerState = {
  isLoading: boolean;
  isUpdatingWallet: boolean;
  ledgers: LedgersKeyValue;
  wallets: WalletsKeyValue;
  error: any;
};

const initialState = {
  isLoading: false,
  isUpdatingWallet: false,
  ledgers: {},
  wallets: {},
  error: null,
} as LedgerState;

export const fetchWallet = createAsyncThunk('ledger/fetchWallet', async (props: any) => {
  const response = await props.securedApi?.DistributedLedgerApi.getWalletForLedger(props.ledgerId);
  return response;
});

export const freezeWallet = createAsyncThunk('ledger/freezeWallet', async (props: any) => {
  const response = await props.securedApi?.DistributedLedgerApi.freezeWallet(props.ledgerId);
  return response;
});

export const unfreezeWallet = createAsyncThunk('ledger/unfreezeWallet', async (props: any) => {
  const response = await props.securedApi?.DistributedLedgerApi.unfreezeWallet(props.ledgerId);
  return response;
});

export const fetchWalletDetails = createAsyncThunk(
  'ledger/fetchWalletDetails',
  async (props: any) => {
    const response = await props.securedApi?.DistributedLedgerApi.getWalletDetails(
      props.ledgerId,
      props.walletId,
    );
    response.ledgerId = props.ledgerId;
    return response;
  },
);

export const ledgerSlice = createSlice({
  name: 'ledgerData',
  initialState,
  reducers: {
    updateWalletBalance(state, action) {
      state.wallets[action.payload.ledgerId].walletBalance = action.payload.newWalletBalance;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
    builder.addCase(fetchWallet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWallet.fulfilled, (state, action) => {
      state.isLoading = false;
      const key = action.payload?.currentLedger;
      const value = action.payload;
      state.wallets[key] = value;
    });
    builder.addCase(fetchWallet.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(freezeWallet.pending, (state) => {
      state.isUpdatingWallet = true;
    });
    builder.addCase(freezeWallet.rejected, (state) => {
      state.isUpdatingWallet = false;
    });
    builder.addCase(freezeWallet.fulfilled, (state, action) => {
      state.wallets[action.payload.currentLedger].wallet = action.payload.wallet;
      state.isUpdatingWallet = false;
    });
    builder.addCase(unfreezeWallet.pending, (state) => {
      state.isUpdatingWallet = true;
    });
    builder.addCase(unfreezeWallet.rejected, (state) => {
      state.isUpdatingWallet = false;
    });
    builder.addCase(unfreezeWallet.fulfilled, (state, action) => {
      state.wallets[action.payload.currentLedger].wallet = action.payload.wallet;
      state.isUpdatingWallet = false;
    });
  },
});

export const { updateWalletBalance } = ledgerSlice.actions;
export default ledgerSlice.reducer;
