import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import { selectUiIsSettingDefaultPaymentMethod, useAppSelector } from 'store';
import { Switch, Button } from 'components';
import usePayments from 'data/usePayments';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

interface PaymentMethodRowProps {
  id: string;
  number: string;
  expiryMonth: number;
  expiryYear: number;
  defaultValue: boolean;
  cardTypeIcon: IconProp;
  setDefaultCallback: (id: string) => void;
  deleteMethodCallback?: (id: string) => void;
}
const PaymentMethodRow = ({
  id,
  number,
  expiryMonth,
  expiryYear,
  defaultValue,
  cardTypeIcon,
  setDefaultCallback,
  deleteMethodCallback,
}: PaymentMethodRowProps) => {
  const isLoading = useAppSelector(selectUiIsSettingDefaultPaymentMethod);
  const [deleting, setDeleting] = useState(false);
  const isMobile = useMediaQuery();
  const { status } = usePayments();

  const isDeleting = status === 'loading' && deleting;

  useEffect(() => {
    if (status !== 'loading' && deleting) {
      setDeleting(false);
    }
  }, [status, deleting]);

  return isMobile ? (
    <div className='flex flex-wrap pb-2'>
      <div className='flex justify-between w-full'>
        <div className='flex-shrink-0 pr-2 pl-0'>
          <FontAwesomeIcon icon={cardTypeIcon} className='pr-2.5' size='xl' />
        </div>
        <div>{number}</div>
        <div>
          {expiryMonth}/{expiryYear}
        </div>
      </div>

      <div className='flex grow flex-shrink flex-auto pt-2 pr-2 pb-2 justify-between'>
        <div className='flex'>
          <label className='text-xs pr-2 pt-1'>Default payment method:</label>
          <Switch
            isChecked={defaultValue}
            isLoading={isLoading && !defaultValue}
            onChange={() => {
              !defaultValue && setDefaultCallback(id);
            }}
          />
        </div>
      </div>
      {deleteMethodCallback && (
        <div className='flex-shrink-0 p-2 w-[60px]'>
          <Button
            isLoading={isDeleting}
            className='text-xs text-custom-primary'
            onClick={() => {
              deleteMethodCallback(id);
              setDeleting(true);
            }}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  ) : (
    <div className='flex flex-wrap pb-3'>
      <div className='flex-shrink-0 p-2 w-[60px]'>
        <FontAwesomeIcon icon={cardTypeIcon} className='mr-4 pr-2.5 pl-1.5' size='xl' />
      </div>
      <div className='flex-1 p-2'>{number}</div>
      <div className='flex-1 p-2'>
        {expiryMonth}/{expiryYear}
      </div>
      <div className='flex grow flex-shrink flex-auto p-2'>
        <label className='text-xs pr-2 pt-1'>Default payment method:</label>
        <Switch
          isChecked={defaultValue}
          isLoading={isLoading && !defaultValue}
          onChange={() => {
            !defaultValue && setDefaultCallback(id);
          }}
        />
      </div>
      {deleteMethodCallback && (
        <div className='flex-shrink-0 p-2 w-[60px]'>
          <Button
            isLoading={isDeleting}
            className='text-xs text-custom-primary'
            onClick={() => {
              deleteMethodCallback(id);
              setDeleting(true);
            }}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};

export default PaymentMethodRow;
