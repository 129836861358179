import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Control } from 'react-hook-form';
import { Form, Row, Col, FormGroup } from 'reactstrap';

import { SignUpFormValues } from '../hooks/useSignUpForm';
import { Input, Button } from '../components';

import { Navigation } from 'navigation';
import useMediaQuery, { isSmallMobileScreen } from 'hooks/mediaQuery/useMediaQuery';
import { ControlledTextField } from 'components';

type SignUpFormType = {
  control: Control<SignUpFormValues>;
  isLoading: boolean;
  onSubmit: () => void;
};

const SignUpForm: FC<SignUpFormType> = ({ control, isLoading, onSubmit }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery();

  if (isMobile) {
    return (
      <>
        <Row className='text-center pb-3'>
          <Row className='pb-2'>
            <div className='font-medium text-2xl'>{t('SignUp.titleMobile')}</div>
          </Row>
          <div className='font-light'>
            <span className='text-custom-secondary'>
              {t('SignUp.link.haveAccountMobile')}&nbsp;
            </span>
            <span className='font-light text-[#079286] visited:text-[#079286] underline'>
              <Link className='link-custom' to={Navigation.LOGIN}>
                {t('SignUp.link.logIn')}
              </Link>
            </span>
          </div>
        </Row>
        <Form onSubmit={onSubmit}>
          <FormGroup className='mt-2'>
            <div className='form-row'>
              <div className='col-6 px-[5px]'>
                <ControlledTextField
                  fieldName='firstName'
                  type='text'
                  label={t('SignUp.placeholder.firstName')}
                  control={control}
                />
              </div>
              <div className='col-6 px-[5px]'>
                <ControlledTextField
                  fieldName='lastName'
                  type='text'
                  label={t('SignUp.placeholder.lastName')}
                  control={control}
                />
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <ControlledTextField
              fieldName='email'
              type='email'
              label={t('SignUp.placeholder.email')}
              control={control}
            />
          </FormGroup>
          <FormGroup>
            <ControlledTextField
              fieldName='password'
              type='password'
              label={t('SignUp.placeholder.password')}
              control={control}
            />
          </FormGroup>
          <FormGroup>
            <ControlledTextField
              fieldName='confirmPassword'
              type='password'
              label={t('SignUp.placeholder.confirmPassword')}
              control={control}
            />
          </FormGroup>

          <FormGroup>
            <Input
              className='auth-checkbox'
              id='checkTerms'
              name='checkTerms'
              control={control}
              type='checkbox'
              label={
                <>
                  {t('SignUp.label.accept')}{' '}
                  <a href={Navigation.TERMS} target='_blank' rel='noopener noreferrer'>
                    {t('SignUp.label.terms')}
                  </a>
                </>
              }
            />
          </FormGroup>
          <FormGroup>
            <Button
              block
              className='mt-3 bg-intelli-primaryNew py-2'
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              {t('SignUp.button.continue')}
            </Button>
          </FormGroup>
          <div
            className={`form-support flex items-center justify-center text-[1.2rem] ${
              isSmallMobileScreen() ? 'mb-2' : 'mb-4'
            } text-center`}
          >
            <a
              href={Navigation.SUPPORT}
              target='_blank'
              rel='noreferrer'
              className='fs--1 font-[Open Sans] !text-[#748194] leading-6 font-extralight'
            >
              {t('AuthLayout.troubleLoggingIn')}
              <br />
              {t('AuthLayout.contactSupport')}
              <span className='d-inline-block ml-1'>&rarr;</span>
            </a>
          </div>
        </Form>
      </>
    );
  }

  return (
    <>
      <Row className='text-left justify-content-between mb-2.5'>
        <Col>
          <h5 id='modalLabel'>{t('SignUp.title')}</h5>
        </Col>
        <Col xs='auto'>
          <p className='fs--1 font-light text-[#748194]'>
            {t('SignUp.link.haveAccount')}&nbsp;
            <Link to={Navigation.LOGIN}>{t('SignUp.link.logIn')}</Link>
          </p>
        </Col>
      </Row>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <div className='form-row'>
            <div className='col-6 px-[5px]'>
              <Input
                name='firstName'
                type='text'
                className='form-control'
                placeholder={t('SignUp.placeholder.firstName')}
                control={control}
              />
            </div>
            <div className='col-6 px-[5px]'>
              <Input
                name='lastName'
                type='text'
                className='form-control'
                placeholder={t('SignUp.placeholder.lastName')}
                control={control}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <Input
            name='email'
            type='email'
            className='form-control'
            placeholder={t('SignUp.placeholder.email')}
            control={control}
          />
        </FormGroup>
        <FormGroup>
          <div className='form-row'>
            <div className='col-6 px-[5px]'>
              <Input
                name='password'
                type='password'
                className='form-control'
                placeholder={t('SignUp.placeholder.password')}
                control={control}
              />
            </div>
            <div className='col-6 px-[5px]'>
              <Input
                name='confirmPassword'
                type='password'
                className='form-control'
                placeholder={t('SignUp.placeholder.confirmPassword')}
                control={control}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup>
          <Input
            className='auth-checkbox'
            id='checkTerms'
            name='checkTerms'
            control={control}
            type='checkbox'
            label={
              <>
                {t('SignUp.label.accept')}{' '}
                <a href={Navigation.TERMS} target='_blank' rel='noopener noreferrer'>
                  {t('SignUp.label.terms')}
                </a>
              </>
            }
          />
        </FormGroup>
        <FormGroup>
          <Button
            color='primary'
            block
            className='mt-3'
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            {t('SignUp.button.continue')}
          </Button>
        </FormGroup>
      </Form>
    </>
  );
};

export default SignUpForm;
