import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useFetchEventAndTickets } from '../hooks';

import EventHeader from './components/EventHeader';

import { Loader } from 'components';
import {
  // TicketIcon,
  WalletIcon,
} from 'components/icons';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { InnerLayout } from 'pages';
import { selectUiIsEventLayoutLoading } from 'store';
import TransactionIcon from 'components/icons/TransactionIcon';
import RefundIcon from 'components/icons/RefundIcon';

const EventLayout: FC = () => {
  const isLoading = useSelector(selectUiIsEventLayoutLoading);
  const isMobile = useMediaQuery();
  const { eventId } = useParams();

  const links = useMemo(
    () => [
      {
        name: 'Event Balance',
        to: `${eventId}/event-balance`,
        target: '',
        icon: <WalletIcon />,
      },
      // Temporary hidden My Ticket Orders page before real event
      // {
      //   name: 'My Ticket Orders',
      //   to: `${eventId}/tickets`,
      //   target: '',
      //   icon: <TicketIcon />,
      // },
      {
        name: 'Transactions',
        to: `${eventId}/transactions`,
        target: '',
        icon: <TransactionIcon />,
      },
      {
        name: 'Refund Request',
        to: `${eventId}/refund-request`,
        target: '',
        icon: <RefundIcon />,
      },
    ],
    [eventId],
  );

  useFetchEventAndTickets();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!isMobile && <EventHeader />}
          <InnerLayout links={links} mobileHeader={<EventHeader />} />
        </>
      )}
    </>
  );
};

export default EventLayout;
