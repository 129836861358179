import React from 'react';
import { Modal, ModalBody, Button, ModalFooter } from 'reactstrap';

interface MessageModalProps {
  message: string;
  isOpen: boolean;
  toggleFunction: () => void;
}
const MessageModal = ({ isOpen, message, toggleFunction }: MessageModalProps) => {
  return (
    <Modal isOpen={isOpen} centered size={'md'} backdrop='static'>
      <ModalBody className='flex'>
        <label className='pt-10 text-center font-bold'>{message}</label>
      </ModalBody>

      <ModalFooter className='flex justify-center'>
        <Button className='bg-blue-400 w-3/5 text-white' onClick={toggleFunction}>
          Okay
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MessageModal;
