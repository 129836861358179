import { createSelector } from 'reselect';

import { PaymentMethod } from 'data';
import { RootState } from 'store';

export const selectPaymentMethods = (state: RootState) => state.paymentsData.paymentMethods;
export const selectPaymentStatus = (state: RootState) => state.paymentsData.paymentStatus;
export const selectStatus = (state: RootState) => state.paymentsData.status;

export const selectPaymentMethodsArray = createSelector(
  [selectPaymentMethods],
  (paymentMethods): PaymentMethod[] => {
    return Object.values(paymentMethods);
  },
);
