export const Config = {
  API_URL: `https://cb-authentication-${process.env.REACT_APP_ENV}.web.app/api`,
  ROOT_ISSUER: `https://cb-authentication-${process.env.REACT_APP_ENV}.web.app/api`,
  EVENTS: `https://cb-events-${process.env.REACT_APP_ENV}.web.app/api`,
  ACCESS_CONTROL: `https://cb-access-control-${process.env.REACT_APP_ENV}.web.app/api`,
  EVENTS_CDN: 'https://services.crowdblink.com/events/snd',
  ORDERS: `https://cb-ticketing-${process.env.REACT_APP_ENV}.web.app/api`,
  SPREEDLY_PAYMENTS: `https://core.spreedly.com/v1/payment_methods.json?environment_key=${process.env.REACT_APP_ENV_SPREEDLY_KEY}`,
  PAYMENTS: `https://cb-payments-${process.env.REACT_APP_ENV}.web.app/api`,
  DISTRIBUTED_LEDGER:
    `${process.env.REACT_APP_ENV}` == 'production'
      ? 'https://cb-distributed-ledger-prod.web.app/api'
      : `https://cb-distributed-ledger-${process.env.REACT_APP_ENV}.web.app/api`,
  MIDDLEWARE: `https://cb-middleware-${process.env.REACT_APP_ENV}.web.app`,
};
