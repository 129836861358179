import React, { FC } from 'react';

import { mapCardsNumbersToOptions } from '../helpers';

import SelectFieldBase, { SelectFieldBaseProps } from './SelectFieldBase';

import { CardDigits } from 'components';

export type SelectFieldCardProps = SelectFieldBaseProps & {
  cardsNumbers?: string[];
};

const SelectFieldCard: FC<SelectFieldCardProps> = ({ cardsNumbers, options, ...restProps }) => (
  <SelectFieldBase
    {...restProps}
    options={mapCardsNumbersToOptions(cardsNumbers) || options}
    renderOptionItem={({ value }) => <CardDigits cardNumber={value} />}
  />
);

export default SelectFieldCard;
