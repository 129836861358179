import { createAsyncThunk } from '@reduxjs/toolkit';

export enum AccessControlActions {
  FETCH_ACCESS_TICKETS = 'accessControl/fetchAccessTickets',
}

export const fetchAccessTicketsAction = createAsyncThunk(
  AccessControlActions.FETCH_ACCESS_TICKETS,
  async ({ securedApi, eventUri }: any) => {
    const accessTickets = await securedApi.AccessControlApi.fetchAccessTickets(eventUri);
    return accessTickets;
  },
);
