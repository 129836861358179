import React from 'react';

const WristbandIcon = () => (
  <svg
    style={{ width: 'inherit', height: 'inherit' }}
    viewBox='0 0 24 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5 4C5 2.34315 6.04467 1 7.33333 1H16.6667C17.9553 1 19 2.34315 19 4V12C19 13.6569 17.9553 15 16.6667 15H7.33333C6.04467 15 5 13.6569 5 12V4Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.54297 8.36719L11.0117 9.83594L14.6836 6.16406'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1 5.13422C1 4.60664 1.22386 4.17896 1.5 4.17896H4.5C4.77614 4.17896 5 4.60664 5 5.13422V10.8658C5 11.3934 4.77614 11.821 4.5 11.821H1.5C1.22386 11.821 1 11.3934 1 10.8658V5.13422Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19 5.13422C19 4.60664 19.2239 4.17896 19.5 4.17896H22.5C22.7761 4.17896 23 4.60664 23 5.13422V10.8658C23 11.3934 22.7761 11.821 22.5 11.821H19.5C19.2239 11.821 19 11.3934 19 10.8658V5.13422Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default WristbandIcon;
