import React, { FC } from 'react';

type DividerWithTextProps = {
  children: string | string[];
};

const DividerWithText: FC<DividerWithTextProps> = ({ children }) => {
  return (
    <div className='flex w-full items-end'>
      <span className='w-full h-[1px] bg-[#e5e7eb]' />
      <span className='shrink-0 text-xs pl-3'>{children}</span>
    </div>
  );
};

export default DividerWithText;
