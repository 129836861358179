import React, { FC } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Button } from 'components';

type ModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  isFrozen: boolean;
  callback: () => void;
  onClose: () => void;
};

const freezeText =
  'Freezing this wristband means you will no longer be able to spend from your wallet';
const unfreezeText = 'Unfreezing this wristband means you will be able to spend from your wallet';
const freezeTitle = 'Are you sure you want to freeze this wristband?';
const unfreezeTitle = 'Are you sure you want to unfreeze this wristband?';

const FreezeWristband: FC<ModalProps> = ({ isOpen, callback, onClose, isLoading, isFrozen }) => {
  return (
    <>
      <Modal toggle={onClose} isOpen={isOpen} centered size={'md'}>
        <ModalHeader className='text-center font-bold' toggle={onClose}>
          {!isFrozen ? freezeTitle : unfreezeTitle}
        </ModalHeader>
        <ModalBody className='flex'>
          <label className='text-center'>{!isFrozen ? freezeText : unfreezeText}</label>
        </ModalBody>
        <ModalFooter className='flex justify-center'>
          <Button
            disabled={isLoading}
            className='btn btn-secondary btn-sm w-full md:w-auto'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            className='btn btn-primary btn-sm w-full md:w-auto'
            onClick={callback}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FreezeWristband;
