import { getAllISOCodes } from 'iso-country-currency';
import currencyFormatter from 'currency-formatter';

export enum currency {
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
}

/**
 * Find ISO Currency from ISO Country name
 */
export const getCurrencySymbolForCurrencyCode = (currencyCode: currency | undefined) => {
  const countries = getAllISOCodes();

  const currency = countries.find(({ currency }) => currency === currencyCode?.toString());

  if (!currency) return '$';

  return currency.symbol;
};

export const buildCurrencyBalance = (
  currency: currency | undefined,
  currencySymbol: string,
  currentWalletBalance: number,
) => {
  if (currency && currency.toString() === 'EUR') {
    return `${currentWalletBalance.toFixed(2)}${currencySymbol}`;
  }
  return `${currencySymbol}${currentWalletBalance.toFixed(2)}`;
};

export const getMinimumTopUpAmount = (currency: currency | undefined) => {
  if (currency && currency.toString() === 'EUR') {
    return 10;
  }
  return 50;
};

export const formatCurrency = (amount: number, currencyCode: currency | undefined): string => {
  return currencyFormatter.format(amount, {
    code: currencyCode ? currencyCode.toString() : 'CAD',
    decimal: '.',
  });
};
export const getFormattedAmount = (cents: number, currencyCode: currency | undefined) =>
  formatCurrency(cents / 100, currencyCode);

export const getRefundFeeForCurrency = (
  currencyCode: currency | undefined | any,
  refundFee = 0,
): string => {
  const currencySymbol = getCurrencySymbolForCurrencyCode(currencyCode);
  const fee = Number(refundFee).toFixed(2);

  if (currencyCode && currencyCode === currency.EUR) {
    return `${fee}${currencySymbol}`;
  }
  return `${currencySymbol}${fee}`;
};
