import React, { FC, ReactNode } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { MenuLinkType, Menu } from 'components';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import MobileNavTabs from 'components/Menu/MobileNavTabs';

export type InnerLayoutProps = {
  links?: MenuLinkType[];
  mobileHeader?: Nullable<ReactNode>;
};

const InnerLayout: FC<InnerLayoutProps> = ({ links, mobileHeader }) => {
  const isMobile = useMediaQuery();
  const [searchParams] = useSearchParams();

  const shouldHideLayoutHeaders = searchParams.get('hideLayoutHeader') && isMobile; // Currently available for mobile layout only!

  return (
    <>
      <div className={`${isMobile ? 'block' : 'flex'}`}>
        {links && (
          <div className={`${isMobile ? 'hidden' : null} md:w-[215px]`}>
            <Menu links={links} variant='secondary' />
          </div>
        )}
        <div className={classNames('flex-1 px-4', { 'pt-3': isMobile })}>
          {isMobile && !shouldHideLayoutHeaders && mobileHeader && mobileHeader}
          {isMobile && !shouldHideLayoutHeaders && <MobileNavTabs links={links || []} />}
          <Outlet />
        </div>
      </div>
    </>
  );
};

InnerLayout.displayName = 'InnerLayout';
export default InnerLayout;
