export type SignUpFormValues = {
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  email: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};
export type EditFormValues = {
  name: string;
  dob: string;
  gender: string;
  phoneNumber: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

export const mapAccountData = ({
  dob,
  city,
  country,
  gender,
  phoneNumber,
  state,
  streetAddress,
  zip,
}: SignUpFormValues) => ({
  dob,
  gender,
  contactInfo: {
    address: {
      line1: streetAddress,
      city,
      state,
      country,
      zip,
    },
    phoneNumber,
  },
});
export const mapEditAccountData = ({
  dob,
  gender,
  phoneNumber,
  name,
  streetAddress,
  city,
  state,
  zip,
  country,
}: EditFormValues) => ({
  name,
  dob,
  gender,
  contactInfo: {
    address: {
      line1: streetAddress,
      city,
      state,
      country,
      zip,
    },
    phoneNumber,
  },
});
