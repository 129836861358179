import { resourceToUri } from 'jwt-cert-sdk';
import { useCallback } from 'react';

import { useData } from 'data/ContentProvider';
import { useEventServiceIdentity } from 'data/api/EventApi';
import { fetchAccessTicketsAction, AppDispatch, useAppDispatch } from 'store';

export const useAccessControlApi = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const { securedApi } = useData();
  const eventServiceIdentity = useEventServiceIdentity();

  const fetchAccessTickets = useCallback(
    async (eventId: string) => {
      if (eventServiceIdentity) {
        const eventUri = resourceToUri({
          resourceId: eventId,
          serviceId: eventServiceIdentity.sub,
          resourceType: 'cb:event',
        });
        await dispatch(fetchAccessTicketsAction({ securedApi, eventUri }));
      }
    },
    [eventServiceIdentity, securedApi, dispatch],
  );

  return {
    fetchAccessTickets,
  };
};
