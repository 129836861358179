import React, { useState } from 'react';
import { Container, Button } from 'reactstrap';

import PaymentMethodRow from './PaymentMethodRow';

import { Heading, AddPaymentMethodModal } from 'components';
import { PaymentMethod } from 'data/model';
import { replaceSpreedlyHideString, getCreditCardIconByType } from 'helpers/creditCardUtils';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

interface PaymentMethodsTableProps {
  paymentMethods: any[];
  changeDefaultCallback: (id: string) => void;
  deleteMethodCallback?: (id: string) => void;
}

const PaymentMethodsTable = ({
  paymentMethods,
  changeDefaultCallback,
  deleteMethodCallback,
}: PaymentMethodsTableProps) => {
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(!showModal);
  const isMobile = useMediaQuery();

  return isMobile ? (
    <div className='w-full shadow-[0px_4px_8px_8px_rgba(146,146,210,0.1)] p-[1.5rem] bg-[#FFFFFF] rounded-lg'>
      {paymentMethods.length ? (
        <Container className='pt-3 pb-2'>
          {paymentMethods.map((card: PaymentMethod) => (
            <>
              <PaymentMethodRow
                key={card.uuid}
                id={card.uuid}
                defaultValue={card.default}
                setDefaultCallback={changeDefaultCallback}
                cardTypeIcon={getCreditCardIconByType(card.info.spreedlyPaymentMethod.card_type)}
                number={replaceSpreedlyHideString(card.info.spreedlyPaymentMethod.number)}
                expiryMonth={card.info.spreedlyPaymentMethod.month}
                expiryYear={card.info.spreedlyPaymentMethod.year}
                deleteMethodCallback={deleteMethodCallback ? deleteMethodCallback : undefined}
              />
              <hr className='w-70 mx-auto my-2 mb-3 border-t border-gray-300' />
            </>
          ))}
        </Container>
      ) : (
        <div className='pb-3'>
          <Heading headingLevel='h3' className='text-[18px] text-custom-primary font-normal'>
            Attached Payment Methods
          </Heading>
          <hr className='w-70 mx-auto my-3 border-t border-gray-300' />
          <p className='mt-3'>
            This will allow you to spend directly from your card so you don&apos;t need to Top-up
            funds to your Event Balance throughout the event.
          </p>
        </div>
      )}
      <div className='p-2'>
        <Button className='rounded-lg w-full' onClick={toggle}>
          Add Payment Method
        </Button>
      </div>
      <AddPaymentMethodModal toggle={toggle} modal={showModal} onClosed={toggle} addCard={true} />
    </div>
  ) : (
    <div className='w-full  shadow-[0px_4px_8px_8px_rgba(146,146,210,0.1)] p-[1.5rem] bg-[#FFFFFF] rounded-lg'>
      <div className='flex justify-between'>
        <Heading headingLevel='h3' className='text-[18px] text-custom-primary font-norml pt-3'>
          Attached Payment Methods
        </Heading>
        <Button className='rounded-lg' onClick={toggle}>
          Add Payment Method
        </Button>
      </div>
      <div className='flex-col'>
        <p className='font-bold'>
          This will allow you to spend directly from your card so you don&apos;t need to Top-up
          funds to your Event Balance throughout the event.
        </p>
      </div>
      {paymentMethods.length ? (
        <Container className='pt-6'>
          {paymentMethods.map((card: PaymentMethod) => (
            <PaymentMethodRow
              key={card.uuid}
              id={card.uuid}
              defaultValue={card.default}
              setDefaultCallback={changeDefaultCallback}
              cardTypeIcon={getCreditCardIconByType(card.info.spreedlyPaymentMethod.card_type)}
              number={replaceSpreedlyHideString(card.info.spreedlyPaymentMethod.number)}
              expiryMonth={card.info.spreedlyPaymentMethod.month}
              expiryYear={card.info.spreedlyPaymentMethod.year}
              deleteMethodCallback={deleteMethodCallback ? deleteMethodCallback : undefined}
            />
          ))}
        </Container>
      ) : (
        <div className='flex justify-center pt-5'>
          <Heading headingLevel='h3' className='text-[18px] text-custom-primary font-norml pt-2'>
            Click Add Payment Method to get started
          </Heading>
        </div>
      )}

      <AddPaymentMethodModal toggle={toggle} modal={showModal} onClosed={toggle} addCard={true} />
    </div>
  );
};

export default PaymentMethodsTable;
