import { ClientConfig, SecuredApi } from './Api';

import { Config } from 'data/Config';
import { paths } from 'data/constants';

interface RefundApi {
  manualRefundRequest: (body: any) => Promise<any>;
}

export const AccountTypeOptions = [
  {
    value: 'personal-savings',
    label: 'Personal Savings',
  },
  {
    value: 'personal-checking',
    label: 'Personal Checking/Chequing',
  },
  {
    value: 'corporation-savings',
    label: 'Corporation Savings',
  },
  {
    value: 'corporation-checking',
    label: 'Corporation Checking/Chequing',
  },
];

export type RefundType = 'Canada' | 'USA' | 'SEPA' | 'Non-SEPA' | 'Paypal' | 'n/a';

export interface manualRefundRequestBody {
  firstName: string;
  lastName: string;
  accountNumber?: string;
  branchNumber?: string;
  financialInstitutionNumber?: string;
  accountType?: string;
  refundType: RefundType;
  routingNumber?: string;
  typeOfTransfer?: string;
  iban?: string;
  bicCode?: string;
  country?: string;
  swiftCode?: string;
  beneficiaryBankName?: string;
  beneficiaryBankAddress?: string;
  beneficiaryBankCountry?: string;
  routAccountNumber?: string;
  paypalEmail?: string;
  addressLine1?: string;
  addressLine2?: string;
  routingTransitNumber?: string;
  eventId: string;
}

export class RefundApiImpl extends SecuredApi implements RefundApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return {
      baseURL: Config.PAYMENTS,
      timeout: 15000,
    };
  };

  manualRefundRequest = async (body: manualRefundRequestBody) => {
    try {
      const response = await this.client.post(paths.manualRefundRequest(), body);

      return response;
    } catch (err) {
      console.error('Failed to send manual refund request', err);
    }
  };

  fetchRefundPolicy = async (url: string) => {
    try {
      const response = await this.client.get(url, { responseType: 'arraybuffer' });
      return response.data;
    } catch (err) {
      console.error('Failed to fetch refund policy', err);
    }
  };
}

export default RefundApiImpl;
