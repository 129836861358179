import React, { FC } from 'react';

import { Heading } from 'components';
import { HeaderWrapper } from 'pages/layouts/common';

const SettingsHeader: FC = () => (
  <HeaderWrapper>
    <Heading
      headingLevel='p'
      className='self-center text-[#232E59] text-xl leading-5 font-normal text-base'
    >
      Profile
    </Heading>
  </HeaderWrapper>
);

export default SettingsHeader;
