import Axios, { AxiosInstance } from 'axios';
import {
  Agent,
  ContactInfo,
  EmailContactInfo,
  Identity,
  AxiosHTTPClient,
  TokenManager,
} from 'jwt-cert-sdk';
import { useEffect, useState } from 'react';

import { useKeyPair } from './useKeyPair';
import { useNativeObjectStore, UseNativeObjectStoreOptions } from './useNativeObjectStore';

import { Config } from 'data/Config';

export interface UseJCTAgentOptions<D extends ContactInfo = EmailContactInfo>
  extends UseNativeObjectStoreOptions<MyAgent<D>> {
  identity: Identity<D> | { (): Identity<D> | Promise<Identity<D>> };
  deviceName?: string;
  finalize?: { (agent: MyAgent<D>): void };
  deferInitialization?: boolean;
}

export type MyAgent<T extends ContactInfo> = Agent<T> & {
  rootAuthorityAxios: AxiosInstance;
  tokenManager: TokenManager<T>;
  alias: string;
};

export function useJCTAgent<T extends ContactInfo = EmailContactInfo>(opts: UseJCTAgentOptions<T>) {
  const rootAuthority = Config.ROOT_ISSUER;
  const { promise: keyPairPromise } = useKeyPair({ alias: opts.alias });
  const [agent, setAgent] = useState<MyAgent<T> | undefined>();
  const provideAgent = useNativeObjectStore<MyAgent<T>, UseJCTAgentOptions<T>>(
    'jctagents',
    async function instanciateAgent(opts, store): Promise<MyAgent<T>> {
      const [pair, identity] = await Promise.all([
        keyPairPromise,
        typeof opts.identity === 'function' ? opts.identity() : opts.identity,
      ]);

      const agent = new Agent(identity, pair, opts.deviceName);
      const myAgent = finalizeAgent(agent, opts.alias, rootAuthority);
      if (opts.finalize) opts.finalize(myAgent);
      await store(myAgent);
      setAgent(myAgent);
      return myAgent;
    },
    [
      function serialize(agent: MyAgent<T>) {
        return JSON.stringify({
          ag: agent.serialize(),
          ra: rootAuthority,
          al: opts.alias,
        });
      },
      (raw: string): MyAgent<T> => {
        const parsed = JSON.parse(raw);
        const agent = Agent.unserialize(parsed.ag) as unknown as Agent<T>;
        const myAgent = finalizeAgent(agent, parsed.al, parsed.ra);
        if (opts.finalize) opts.finalize(myAgent);
        setAgent(myAgent);
        return myAgent;
      },
    ],
  );

  useEffect(() => {
    if (opts.deferInitialization === true) return;

    const providedAgent = provideAgent(opts);
    /* 
      Need to add this so that we can use the agent returned by the cache as the "setAgent" wont be called from the callback(instanciateAgent) if in cache already
      Scenario: 
      1. User logs in -> user agent is added to the cache -> User Logs out
      2. A different User logs in -> user agent is added to the cache -> User Logs out
      3. Once the first user tries to log in, the agent is already in the cache so the (instanciateAgent) callback is never called but the actual cache is returned. 
    **/
    if (providedAgent.ready === true && providedAgent.instance !== undefined) {
      setAgent(providedAgent.instance);
    }
  }, [opts, provideAgent]);

  return { agent };
}

function finalizeAgent<T extends ContactInfo>(
  agent: Agent<T>,
  alias: string,
  rootAuthority: string,
): MyAgent<T> {
  const axios = Axios.create({
    baseURL: rootAuthority + '/cert',
  });
  agent.addRootAuthority(
    // TODO figure out why the AxiosInstance is different from the
    // jwtCertSDK one
    new AxiosHTTPClient(axios, rootAuthority),
  );

  const myAgent = Object.assign(agent, {
    rootAuthorityAxios: axios,
    tokenManager: new TokenManager(agent, 60),
    alias: alias,
  });
  return myAgent;
}
