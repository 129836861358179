import { createAction, CaseReducer } from '@reduxjs/toolkit';

export const resetAll = createAction('RESET_ALL');

export const setPendingState: CaseReducer = (state, _action) => {
  state.status = 'loading';
};

export const setRejectedState: CaseReducer = (state, action) => {
  state.response = action.payload;
  state.status = 'failed';
};

export const setFulfilledState: CaseReducer = (state, _action) => {
  state.status = 'idle';
};
