import React from 'react';

import { currency, getFormattedAmount } from '../../helpers/currency';

import { Table, TableCell, Box, Section, TextField } from 'components';
import { Order, OrderProduct } from 'data';
import { formatDateToMed } from 'helpers';
import {
  convertUndefinedPriceItemsToProducts,
  getOrderTotalAndTax,
  populateProductWithTotalAndTax,
} from 'helpers/order';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

const theadData = (isMobile: boolean) => {
  const mobileStyle = isMobile ? 'text-xs py-1 px-1' : 'w-[3%]';
  return [
    { Name: 'Product Type', className: `${mobileStyle} text-left` },
    { Name: 'Unit Price', className: `${mobileStyle} text-center` },
    { Name: 'Tax', className: `${mobileStyle} text-center` },
    { Name: 'Quantity', className: `${mobileStyle} text-center` },
    { Name: 'Subtotal', className: `${mobileStyle} text-center` },
  ];
};

const PurchaseTransactionDetails = ({
  order,
  vendorName,
  currency,
}: {
  order: Order;
  vendorName: string;
  currency: currency | undefined;
}) => {
  const isMobile = useMediaQuery();

  const products = [...order.products].flatMap(convertUndefinedPriceItemsToProducts);
  products.forEach(populateProductWithTotalAndTax);

  if (!isMobile && order.tip) {
    products.push({
      label: 'Tip',
      quantity: 1,
      tax: 0,
      unitPrice: order.tip,
      subtotal: order.tip,
    } as unknown as OrderProduct);
  }

  const { totalTax, totalWithoutTaxes, orderTotal } = getOrderTotalAndTax(order, !isMobile);

  return isMobile ? (
    <>
      <div className={`w-[${window.innerWidth}px] bg-white px-4 pt-2 mt-3 mx-[-24px] pb-3 mb-12`}>
        <div className='mt-2'>
          <div className='pb-3'>
            <TextField value={vendorName} label='Vendor' isDisabled />
          </div>
          <div className='pb-3'>
            <TextField value={formatDateToMed(order.createdOn)} label='Date' isDisabled />
          </div>
          <div className='pb-3'>
            <TextField value='Cashless Purchase' label='Transaction' isDisabled />
          </div>
          <div className='pb-0'>
            <TextField value={order.uuid} label='Order ID' isDisabled />
          </div>
        </div>

        <h2 className='text-lg my-4 text-gray-500'>Purchase Summary</h2>

        <div className='rounded-lg border border-[#e8efef] p-3'>
          {products.map((product, index) => (
            <div key={index} className='flex justify-between items-center mb-2'>
              <div>{`${product.label} x ${product.quantity}`}</div>
              <div>{getFormattedAmount(product.unitPrice! * product.quantity, currency)}</div>
            </div>
          ))}

          <div className='border-b border-gray-200 mb-2'></div>

          <div className='flex justify-between items-center mb-2'>
            <div>Subtotal</div>
            <div>{getFormattedAmount(totalWithoutTaxes, currency)}</div>
          </div>

          {order.tip ? (
            <div className='flex justify-between items-center mb-2'>
              <div>Tip</div>
              <div>{getFormattedAmount(order.tip, currency)}</div>
            </div>
          ) : null}

          <div className='flex justify-between items-center mb-2'>
            <div>Tax</div>
            <div>{getFormattedAmount(totalTax, currency)}</div>
          </div>

          <div className='border-b border-gray-200 mb-2'></div>

          <div className='flex justify-between items-center'>
            <div>Total</div>
            <div>
              {getFormattedAmount(order.tip ? orderTotal + order.tip : orderTotal, currency)}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <Box className='p-12 mt-[12px]'>
        <div className='md:flex md:justify-between'>
          <div className='md:w-1/2 md:pr-8'>
            <Section
              title='Details'
              titleClassName='text-xl md:text-3xl'
              className='col-span-12  justify-end'
              description='There are the specifics of your transaction.'
            />
          </div>
          <div className='md:w-1/2 md:pr-8'>
            <div className='pb-3'>
              <TextField value={vendorName} label='Vendor' isDisabled />
            </div>
            <div className='pb-3'>
              <TextField value={formatDateToMed(order.createdOn)} label='Date' isDisabled />
            </div>
            <div className='pb-3'>
              <TextField value='Cashless Purchase' label='Transaction' isDisabled />
            </div>
            <div className='pb-3'>
              <TextField value={order.uuid} label='Order ID' isDisabled />
            </div>
          </div>
        </div>

        <div className='w-full h-[1px] bg-[#E8EAED] mt-6 lg:mb-12' />

        <Table theadData={theadData(isMobile)} className='w-full rounded-lg mb-12'>
          {products.map((product) => (
            <tr key={product.uuid}>
              <TableCell
                className={`${
                  isMobile ? 'py-1 px-1 text-xs' : 'py-4 px-6'
                } text-custom-primary text-base`}
                data={product.label}
              />
              <TableCell
                className={`${isMobile && 'text-xs px-1'} text-center text-base text-[#6F7FAF]`}
                data={getFormattedAmount(product.unitPrice!, currency)}
              />
              <TableCell
                className={`${isMobile && 'text-xs px-1'} text-center text-base text-[#6F7FAF]`}
                data={getFormattedAmount(product.tax!, currency)}
              />
              <TableCell
                className={`${isMobile && 'text-xs px-1'} text-center text-[#6F7FAF]`}
                data={product.quantity}
              />
              <TableCell
                className={`${
                  isMobile ? 'text-xs px-1' : 'py-4 px-6'
                } text-base text-[#6F7FAF] text-center`}
                data={getFormattedAmount(product.subtotal!, currency)}
              />
            </tr>
          ))}
        </Table>

        <div className='md:flex md:justify-start'>
          <div className='md:w-1/2 md:pr-8'>
            <Section
              title='Purchase Summary'
              titleClassName='text-xl md:text-3xl'
              className='col-span-12  justify-end'
            />
          </div>
          <div className='md:w-1/3 md:pr-8'>
            <div className='flex justify-between text-[#6F7FAF]  pb-3'>
              <p>Total purchase</p>
              <p>{getFormattedAmount(totalWithoutTaxes, currency)}</p>
            </div>
            <div className='flex justify-between text-[#6F7FAF]  pb-3'>
              <p>Taxes</p>
              <p>{getFormattedAmount(totalTax, currency)}</p>
            </div>
            <div className='w-full h-[1px] bg-[#E8EAED] mb-3' />

            <div className='flex justify-between pb-3'>
              <p className='pb-6 font-bold  text-lg'>Total</p>
              <p className='pb-6 font-bold  text-lg'>{getFormattedAmount(orderTotal, currency)}</p>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default PurchaseTransactionDetails;
