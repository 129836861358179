import React from 'react';
import { useTranslation } from 'react-i18next';

import { ControlledSelectField, ControlledTextField } from 'components';
import { selectUserData, selectUiIsUpdateUserLoading, useAppSelector } from 'store';
import { getCountryOptions } from 'pages/ManageProfileInfo/helpers';
import { getProvinceOptions } from 'pages/ManageProfileInfo/helpers/getCountryOptions';
import { useManageAddressDetailsForm } from 'pages/ManageProfileInfo/hooks/useManageProfileForm';
import { Button } from 'pages/auth/components';

type AddressDetailsProps = {
  onSubmit: any;
  accountData: any;
};

const AddressDetails = ({ onSubmit, accountData }: AddressDetailsProps) => {
  const userData = useAppSelector(selectUserData);
  const { control, formState, getValues, watch } = useManageAddressDetailsForm(userData);
  const { isValid, isDirty } = formState;
  const isUpdateUserLoading = useAppSelector(selectUiIsUpdateUserLoading);
  const selectedCountry = watch('country');
  const { t } = useTranslation();

  const handleFormSubmit = () => {
    const formData = getValues();
    if (isValid) onSubmit({ ...accountData, ...formData });
  };

  return (
    <>
      <div className='text-custom-primary text-2xl py-3'>{t('AccountCreation.title2')}</div>
      <div className='text-sm text-custom-secondary pb-4'>{t('AccountCreation.description')}</div>
      <div className={`flex flex-col md:flex-row gap-3 mb-4`}>
        <ControlledTextField control={control} fieldName='streetAddress' label='Street Address' />
        <ControlledSelectField
          control={control}
          defaultValue=''
          fieldName='country'
          label='Country'
          options={getCountryOptions()}
        />
        {(selectedCountry === 'US' || selectedCountry === 'CA') && (
          <ControlledSelectField
            control={control}
            fieldName='state'
            label='State/Province'
            options={getProvinceOptions(selectedCountry)}
          />
        )}
        <ControlledTextField control={control} fieldName='city' label='City' />
        <ControlledTextField control={control} fieldName='zip' label='ZIP / Postal Code' />
      </div>
      <Button
        block
        style={{ borderRadius: '0.75rem', border: 'none' }}
        isLoading={isUpdateUserLoading}
        onClick={handleFormSubmit}
        disabled={!isDirty || !isValid}
        className='mt-3 mb-4 bg-intelli-primaryNew py-3'
      >
        {t('Login.link.createAccount')}
      </Button>
    </>
  );
};

export default AddressDetails;
