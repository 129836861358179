import { User, onAuthStateChanged, Auth } from 'firebase/auth';
import React, { useContext, useState, useEffect } from 'react';

import { auth } from '../../firebase';

type AuthContextInterface = {
  currentUser: Nullable<User>;
  auth: Auth;
  isLoading: Nullable<boolean>;
  refreshUser: () => Promise<any>;
  clearAuthProvider: () => void;
};

export const AuthContext = React.createContext<AuthContextInterface>({
  auth,
  currentUser: null,
  isLoading: null,
  refreshUser: (): Promise<User> => new Promise((resolve) => resolve({} as User)),
  clearAuthProvider: () => {
    return;
  },
});
export interface IAuthContext {
  children: React.ReactNode;
}

export const AuthProvider: React.FunctionComponent<IAuthContext> = (props) => {
  const [data, setAuthData] = useState<{
    isLoading: boolean;
    currentUser: User | null;
  }>({
    isLoading: true,
    currentUser: null,
  });

  const clearAuthProvider = () => {
    setAuthData({
      isLoading: true,
      currentUser: null,
    });
  };

  const refreshUser = async () => {
    await auth.currentUser?.reload();
    const updatedUser = auth.currentUser;

    setAuthData({
      isLoading: false,
      currentUser: updatedUser,
    });

    return updatedUser;
  };

  useEffect(() => {
    const AuthCheck = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setAuthData({ isLoading: false, currentUser: user });
      } else {
        setAuthData({ isLoading: false, currentUser: null });
      }
    });
    return () => {
      AuthCheck();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{ auth, refreshUser: refreshUser, ...data, clearAuthProvider: clearAuthProvider }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuthValue() {
  return useContext(AuthContext);
}
