import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSignUpForm } from '../hooks';

import SignUpForm from './SignUpForm';
import SignUpSuccess from './SignUpSuccess';

import { useProvideAuth } from 'hooks/Firebase';
import { Navigation } from 'navigation';

const SignUp: FC = () => {
  const { createEmailPassword, resendEmailVerification, isLoading, isEmailVerified } =
    useProvideAuth();
  const { control, isSuccess, onSubmit, onResend, watch, seconds } = useSignUpForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmailVerified) {
      navigate(Navigation.EVENTS);
    }
  }, [isEmailVerified, navigate]);

  return isSuccess ? (
    <SignUpSuccess
      isLoading={isLoading}
      email={watch('email')}
      seconds={seconds}
      onResend={() => onResend(resendEmailVerification)}
    />
  ) : (
    <SignUpForm isLoading={isLoading} control={control} onSubmit={onSubmit(createEmailPassword)} />
  );
};

export default SignUp;
