import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import PaymentLoader from '../../../../components/PaymentLoader';
import useLedger from '../../../../data/useLedger';
import { HeaderWrapper } from '../../common';
import {
  buildCurrencyBalance,
  getCurrencySymbolForCurrencyCode,
} from '../../../../helpers/currency';

import { Heading } from 'components';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { selectEventSelectedNameEn, selectEventSelectedLedgerId, selectEventCurrency } from 'store';

const EventHeader = () => {
  const eventName = useSelector(selectEventSelectedNameEn);
  const ledgerId = useSelector(selectEventSelectedLedgerId);
  const currency = useSelector(selectEventCurrency);
  const isMobile = useMediaQuery();
  const { currentWalletBalance } = useLedger();

  const currencySymbol = getCurrencySymbolForCurrencyCode(currency);
  const balance = buildCurrencyBalance(currency, currencySymbol, currentWalletBalance);

  return (
    <HeaderWrapper>
      <div className='flex justify-between w-full flex-wrap gap-y-3'>
        {eventName && (
          <Heading
            headingLevel='p'
            className={classNames(
              'self-center text-[#232E59] text-xl font-normal text-base',
              !isMobile && 'flex-1',
            )}
          >
            {eventName}
          </Heading>
        )}

        {(ledgerId && currentWalletBalance) || currentWalletBalance === 0 ? (
          <div className={`${isMobile ? 'flex items-center' : 'ml-auto'}`}>
            <span className={`${isMobile ? 'text-md' : 'px-6'} text-[#232E59]`}>
              Event Balance: <span className='text-[#6F7FAF]'>{balance}</span>
            </span>
          </div>
        ) : null}
        <PaymentLoader />
      </div>
    </HeaderWrapper>
  );
};

export default EventHeader;
