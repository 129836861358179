import { OptionType } from '../components';

type MapCardsNumbersToOptionsType = (cardsNumbers?: string[]) => null | OptionType[];

export const mapCardsNumbersToOptions: MapCardsNumbersToOptionsType = (cardsNumbers) =>
  cardsNumbers
    ? cardsNumbers.map((cardNumber) => ({
        value: cardNumber,
        label: cardNumber,
      }))
    : null;
