import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../store/hook';
import {
  fetchWallet,
  freezeWallet,
  unfreezeWallet,
  updateWalletBalance,
} from '../store/ledger/ledgerReducer';

import { useData } from './ContentProvider';
import { logError } from './api/LoggingApi';
import { WalletInfo } from './api/types/LedgerApiTypes';

import { selectEventSelectedLedgerId } from 'store';
import {
  selectStatus,
  selectUpdatingStatus,
  selectWalletByLedgerId,
} from 'store/ledger/ledgerSelector';
import { useAuthValue } from 'hooks/Firebase';

const disableSentryLog = true;

/**
 * Fetch details for a given ledgerId and walletId
 */
const useLedger = () => {
  const { securedApi } = useData();
  const dispatch = useAppDispatch();
  const selectedEventLedgerId = useSelector(selectEventSelectedLedgerId, shallowEqual);
  const currentWallet: any = useAppSelector((state) =>
    selectWalletByLedgerId(state, selectedEventLedgerId),
  );
  const isLoading = useAppSelector(selectStatus);
  const isUpdatingWallet = useAppSelector(selectUpdatingStatus);
  const { currentUser } = useAuthValue();

  const currenWalletId = currentWallet?.wallet?.id.toString() ?? '';
  const currentWalletBalance = currentWallet?.walletBalance / 100 ?? 0;
  const currentWalletRFID = currentWallet?.rfid ? currentWallet?.rfid : '';
  const isWristbandFrozen = currentWallet?.wallet?.isFrozen ? true : false;

  const updateWalletBalanceOptimistically = useCallback(
    async (newWalletBalance: number) => {
      dispatch(updateWalletBalance({ ledgerId: selectedEventLedgerId, newWalletBalance }));
    },
    [dispatch, selectedEventLedgerId],
  );

  const fetchEventWallet = useCallback(
    async (ledgerId: string, justClaimed?: boolean) => {
      const { payload } = await dispatch(fetchWallet({ ledgerId, securedApi }));

      // Only log the error when justClaimed is false OR undefined AND we don't have a wallet from API
      if (
        (justClaimed === undefined || !justClaimed) &&
        (!payload || !payload.wallet || !payload.wallet.id) &&
        !disableSentryLog
      ) {
        console.log('User Has no wallet: ', ledgerId);
        logError(currentUser, `User Has No Wallet for ledgerId: ${ledgerId}`);
      }
      return payload as WalletInfo;
    },
    [dispatch, securedApi, currentUser],
  );

  const freezeWalletAction = useCallback(
    async (ledgerId: string) => {
      const { payload } = await dispatch(freezeWallet({ ledgerId, securedApi }));

      return payload as any;
    },
    [dispatch, securedApi],
  );

  const unfreezeWalletAction = useCallback(
    async (ledgerId: string) => {
      const { payload } = await dispatch(unfreezeWallet({ ledgerId, securedApi }));

      return payload as any;
    },
    [dispatch, securedApi],
  );

  const fetchEventWalletAndDetailsOfSelectedEvent = useCallback(async () => {
    let response;
    if (selectedEventLedgerId) {
      response = await fetchEventWallet(selectedEventLedgerId);
    }
    return response;
  }, [selectedEventLedgerId, fetchEventWallet]);

  return {
    isLoading,
    currentWallet,
    currenWalletId,
    currentWalletBalance,
    currentWalletRFID,
    isUpdatingWallet,
    fetchEventWallet,
    updateWalletBalanceOptimistically,
    fetchEventWalletAndDetailsOfSelectedEvent,
    freezeWalletAction,
    unfreezeWalletAction,
    isWristbandFrozen,
  };
};

export default useLedger;
