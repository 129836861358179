import { Config } from '../Config';

import { ClientConfig, SecuredApi } from './Api';

interface DistributedLedgerApi {
  getWalletForLedger: (ledgerId: string) => Promise<unknown>;
  freezeWallet: (ledgerId: string) => Promise<unknown>;
  unfreezeWallet: (ledgerId: string) => Promise<unknown>;
}

export class DistributedLedgerApiImpl extends SecuredApi implements DistributedLedgerApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return {
      baseURL: Config.DISTRIBUTED_LEDGER,
      timeout: 15000,
    };
  };

  getWalletForLedger = async (ledgerId: string): Promise<unknown> => {
    const response = await this.client.get(
      `/ledger/${ledgerId}/account/current/accountAssignations`,
    );
    response.data.currentLedger = ledgerId;

    return response.data;
  };

  freezeWallet = async (ledgerId: string): Promise<unknown> => {
    const response = await this.client.put(`/ledger/${ledgerId}/wallet/current/freeze`);
    response.data.currentLedger = ledgerId;

    return response.data;
  };

  unfreezeWallet = async (ledgerId: string): Promise<unknown> => {
    const response = await this.client.put(`/ledger/${ledgerId}/wallet/current/unfreeze`);
    response.data.currentLedger = ledgerId;

    return response.data;
  };

  // This will get wallet details for a given wallet (owned by a patron)
  getWalletDetails = async (ledgerId: string, walletId: string): Promise<unknown> => {
    const response = await this.client.get(
      `/ledger/${ledgerId}/wallet/${walletId}?includeAssignations=true`,
    );
    return response.data;
  };
}

export default DistributedLedgerApi;
