import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const manageAccountDetailsSchema = yup.object().shape({
  dob: yup.string().required('Date of birth is required'),
  gender: yup.string().required('Gender is required'),
  phoneNumber: yup.string().required('Phone number is required'),
});

const manageAddressDetailsSchema = yup.object().shape({
  country: yup.string().required('Country is required'),
  streetAddress: yup.string().required('Street address is required'),
  city: yup.string().required('City is required'),
  zip: yup.string().required('ZIP/Postal code is required'),
  state: yup.string().when('country', (country, schema) => {
    if (!country || country[0] === 'CA' || country[0] === 'US') {
      return schema.required('State/Providence is required');
    }
    return schema;
  }),
});

const manageProfileSchema = yup.object().shape({
  dob: yup.string().required('Date of birth is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  streetAddress: yup.string().required('Street address is required'),
  city: yup.string().required('City is required'),
  zip: yup.string().required('ZIP/Postal code is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().when('country', (country, schema) => {
    if (!country || country[0] === 'CA' || country[0] === 'US') {
      return schema.required('State/Providence is required');
    }
    return schema;
  }),
  gender: yup.string().required('Gender is required'),
});
const editProfileSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  dob: yup.string().required('Date of birth is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  gender: yup.string().required('Gender is required'),
  streetAddress: yup.string().required('Street address is required'),
  city: yup.string().required('City is required'),
  zip: yup.string().required('ZIP/Postal code is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().when('country', (country, schema) => {
    if (!country || country[0] === 'CA' || country[0] === 'US') {
      return schema.required('State/Providence is required');
    }
    return schema;
  }),
});

export const useManageAccountDetailsForm = (userData: any) =>
  useForm({
    resolver: yupResolver(manageAccountDetailsSchema),
    defaultValues: {
      dob: '',
      gender: '',
      phoneNumber: '',
    },
    values: {
      dob: userData?.dob,
      gender: userData?.gender,
      phoneNumber: userData?.contactInfo?.phoneNumber,
    },
    mode: 'all',
  });

export const useManageAddressDetailsForm = (userData: any) =>
  useForm({
    resolver: yupResolver(manageAddressDetailsSchema),
    defaultValues: {
      country: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
    },
    values: {
      country: userData?.contactInfo?.address?.country,
      streetAddress: userData?.contactInfo?.address?.line1,
      city: userData?.contactInfo?.address?.city,
      state: userData?.contactInfo?.address?.state,
      zip: userData?.contactInfo?.address?.zip,
    },
    mode: 'all',
  });

export const useManageProfileForm = (userData: any) =>
  useForm({
    resolver: yupResolver(manageProfileSchema),
    defaultValues: {
      dob: '',
      gender: '',
      phoneNumber: '',
      streetAddress: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    values: {
      country: userData?.contactInfo?.address?.country,
      streetAddress: userData?.contactInfo?.address?.line1,
      city: userData?.contactInfo?.address?.city,
      state: userData?.contactInfo?.address?.state,
      zip: userData?.contactInfo?.address?.zip,
      phoneNumber: userData?.contactInfo?.phoneNumber,
      dob: userData?.dob,
      gender: userData?.gender,
    },
  });

export const useEditProfileForm = (userData: any) =>
  useForm({
    resolver: yupResolver(editProfileSchema),
    defaultValues: {
      name: '',
      dob: '',
      email: '',
      gender: '',
      phoneNumber: '',
      country: '',
      city: '',
      state: '',
      zip: '',
      streetAddress: '',
    },
    values: {
      name: userData?.name,
      country: userData?.contactInfo?.address?.country,
      email: userData?.primaryEmail,
      phoneNumber: userData?.contactInfo?.phoneNumber,
      dob: userData?.dob,
      gender: userData?.gender,
      streetAddress: userData?.contactInfo?.address?.line1,
      city: userData?.contactInfo?.address?.city,
      state: userData?.contactInfo?.address?.state,
      zip: userData?.contactInfo?.address?.zip,
    },
    mode: 'all',
  });
