import React from 'react';

const TransactionIcon = () => {
  return (
    <svg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.38281 5.08057L17.3828 5.08057M17.3828 5.08057L13.3828 1.08057M17.3828 5.08057L13.3828 9.08057M13.3828 15.0806L1.38281 15.0806M1.38281 15.0806L5.38281 19.0806M1.38281 15.0806L5.38281 11.0806'
        stroke='#00303B'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TransactionIcon;
