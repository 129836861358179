const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

// To be used in yup validator
const prepareValidateDate = (currentYear: number, currentMonth: number) => (value: string) => {
  if (!value) {
    return true; // Let the "required" validation handle empty values
  }
  const month = Number(value.split('/')[0]);
  let year = Number(value.split('/')[1]);

  if (year < 100) {
    year += 2000;
  }

  return currentYear < year || (currentYear === year && currentMonth < month);
};

export const validateCardExpiryDate = prepareValidateDate(currentYear, currentMonth);
