import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuLinkType } from 'components';
import { SignOutIcon, SettingsIcon, CalendarIcon, WristbandIcon, UserIcon } from 'components/icons';
import { useAuthValue, useProvideAuth } from 'hooks/Firebase';
import { Navigation } from 'navigation';
import { selectUserDetailedInfoLoading, selectUserHasContactInfo, useAppSelector } from 'store';
import MyTransactionsIcon from 'components/icons/MyTransactionsIcon';

export const useMenuLinks = (): MenuLinkType[] => {
  const { logOut } = useProvideAuth();
  const { clearAuthProvider } = useAuthValue();
  const location = useLocation();
  const hasContactInfo = useAppSelector(selectUserHasContactInfo);
  const isContactInfoLoading = useAppSelector(selectUserDetailedInfoLoading);

  const isLinkDisabled = !hasContactInfo || isContactInfoLoading;

  const shouldShowTransactionsLink = location.pathname === Navigation.MY_TRANSACTIONS;

  const links: MenuLinkType[] = useMemo(
    () => [
      {
        name: 'My Events',
        to: Navigation.EVENTS,
        isDisabled: isLinkDisabled,
        target: '',
        icon: <CalendarIcon />,
      },
      {
        name: 'Add Wristband/Card',
        to: Navigation.CLAIM_WRISTBAND,
        target: '',
        isDisabled: true,
        icon: <WristbandIcon />,
      },
      {
        name: 'Profile',
        to: Navigation.SETTINGS,
        target: '',
        icon: <SettingsIcon />,
        isDisabled: isLinkDisabled,
      },
      {
        name: 'Support',
        to: Navigation.SUPPORT,
        target: '_blank',
        icon: <UserIcon />,
        isDisabled: isLinkDisabled,
      },
      {
        name: 'Transactions',
        to: Navigation.MY_TRANSACTIONS,
        target: '',
        icon: <MyTransactionsIcon />,
        isDisabled: !shouldShowTransactionsLink,
      },
      {
        name: 'Log Out',
        to: Navigation.SIGN_OUT,
        target: '',
        icon: <SignOutIcon />,
        onClick: () => {
          if (clearAuthProvider) clearAuthProvider();
          logOut();
        },
      },
    ],
    /* eslint-disable-next-line */
    [hasContactInfo, isLinkDisabled, logOut, shouldShowTransactionsLink],
  );

  return links;
};
