import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation, type TFunction } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { emailRegExp } from 'validation';
import { Navigation } from 'navigation';
import { AuthActionType, ServerErrorCode } from 'hooks/Firebase';
import { useUser } from 'data';

export type LoginFormValues = {
  loginEmail: string;
  loginPassword: string;
  isRemember?: boolean;
};

const loginSchema = (t: TFunction) =>
  yup.object().shape({
    loginEmail: yup
      .string()
      .required(t('Login.error.emailAddressRequired'))
      .matches(emailRegExp, t('Login.error.invalidEmailAddress')),
    loginPassword: yup.string().required(t('Login.error.passwordRequired')),
    isRemember: yup.boolean(),
  });

export const useLoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fetchUserDetailedInfo } = useUser();

  const form = useForm<LoginFormValues>({
    resolver: yupResolver(loginSchema(t)),
    defaultValues: {
      loginEmail: '',
      loginPassword: '',
      isRemember: false,
    },
  });

  const onSuccess = (): void => {
    fetchUserDetailedInfo();
    navigate(Navigation.EVENTS);
  };

  const onReject = (error: any): void => {
    if (error.code === ServerErrorCode.USER_NOT_FOUND) {
      form.setError('loginEmail', {
        type: 'server',
        message: t('Login.error.accountDoesNotExist'),
      });
    }

    if (error.code === ServerErrorCode.WRONG_PASSWORD) {
      form.setError('loginPassword', {
        type: 'server',
        message: t('Login.error.accountDoesNotExist'),
      });
    }
  };

  const onSubmit = (loginWithEmailPassword: AuthActionType<LoginFormValues>, checkLoggedIn: any) =>
    form.handleSubmit((data) => {
      if (!checkLoggedIn()) {
        loginWithEmailPassword(data, onSuccess, onReject);
      }
    });
  return { onSubmit, ...form };
};
