import React from 'react';

import RefundCanada from './RefundCanada';
import RefundUS from './RefundUS';

type REFUND_FORM_TEMPLATE_CODES = 'canada' | 'usaAndCanada' | 'usa';

type TransferOption = {
  optionCode: string; // also used as translation key
  formComponent: JSX.Element;
};

type RefundFormTemplate = {
  code: REFUND_FORM_TEMPLATE_CODES;
  transferOptions: TransferOption[];
};

const REFUND_FORM_TEMPLATES: RefundFormTemplate[] = [
  {
    code: 'canada',
    transferOptions: [
      {
        optionCode: 'canada_bank_account',
        formComponent: <RefundCanada />,
      },
    ],
  },
  {
    code: 'usaAndCanada',
    transferOptions: [
      {
        optionCode: 'canada_bank_account',
        formComponent: <RefundCanada />,
      },
      {
        optionCode: 'usa_bank_account',
        formComponent: <RefundUS />,
      },
    ],
  },
  {
    code: 'usa',
    transferOptions: [
      {
        optionCode: 'usa_bank_account',
        formComponent: <RefundUS />,
      },
    ],
  },
];

export const getTransferOptionsByTemplateType = (templateCode: string | undefined, t: any) => {
  const template = REFUND_FORM_TEMPLATES.find((template) => template.code === templateCode);

  return (
    template?.transferOptions.map((option) => ({
      label: t(`refundRequest.transferOptions.${option.optionCode}`),
      value: option.optionCode,
    })) || []
  );
};

export const getFormByTransferOption = (
  templateCode: string,
  transferOptionCode: TransferOption['optionCode'],
) => {
  const template = REFUND_FORM_TEMPLATES.find((template) => template.code === templateCode);
  if (!template) return <div>If you see this message - the refund form is not yet supported</div>;

  const transferOption = template.transferOptions.find(
    (option) => option.optionCode === transferOptionCode,
  );

  return transferOption?.formComponent;
};
