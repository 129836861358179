import React from 'react';
import classNames from 'classnames';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, FlexBox } from 'components';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { Order } from 'data';
import PurchaseTransactionDetails from 'pages/TransactionDetails/PurchaseTransactionDetails';
import { currency } from 'helpers/currency';
import RefundTransactionDetails from 'pages/TransactionDetails/RefundTransactionDetails';

interface IProps {
  handleBackClick: () => void;
  order: Order;
}

const MyTransactionDetails = ({ handleBackClick, order }: IProps) => {
  const isMobile = useMediaQuery();

  const isRefundOrder = Boolean(order.refunded);

  return (
    <div>
      <FlexBox
        className={classNames('flex mt-[4px] items-center leading-10', {
          '!justify-start': isMobile,
        })}
      >
        {isMobile && (
          <div className='pl-1 pr-4'>
            <FontAwesomeIcon icon={faArrowLeft} size='lg' onClick={handleBackClick} />
          </div>
        )}
        <p className='text-[19px] font-semibold'>
          {isRefundOrder ? 'Refund' : 'Transaction'} Details
        </p>
        <div>
          {!isMobile && (
            <>
              <Button onClick={handleBackClick} className='btn btn-normal btn-secondary mr-2'>
                Back
              </Button>
            </>
          )}
        </div>
      </FlexBox>

      {isRefundOrder ? (
        <RefundTransactionDetails
          order={order}
          currency={order.currency ? (order.currency as currency) : currency.CAD}
          vendorName={order?.vendorName || 'Intellitix'}
        />
      ) : (
        <PurchaseTransactionDetails
          order={order}
          currency={order.currency ? (order.currency as currency) : currency.CAD}
          vendorName={order?.vendorName || 'Intellitix'}
        />
      )}
    </div>
  );
};

export default MyTransactionDetails;
