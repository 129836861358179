import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Label, Spinner } from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import classNames from 'classnames';

import {
  getRefundEndDate,
  getRefundStartDate,
  isRefundEnabledForEvent,
  isRefundFormAvailable,
} from './RefundUtils';
import { getFormByTransferOption, getTransferOptionsByTemplateType } from './refundFormHelper';
import RefundStateMessage from './RefundStateMessage';

import useRefundContext from 'pages/RefundRequest/RefundContext';
import { Button } from 'components';
import {
  selectEventRefundConfiguration,
  selectEventSelectedDetails,
  selectEventSelectedNameEn,
} from 'store';
import { RefundFormConfiguration, useData } from 'data';
import { getRefundFeeForCurrency } from 'helpers/currency';
import ControlledRadioButton from 'components/inputs/RadioButtonoField/ControlledRadioButton';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

const refundNonAvailabilityMessage = (
  eventRefundConfiguration: RefundFormConfiguration | undefined,
  t: any,
  eventName: string,
) => {
  const isPassedRefundEndDate =
    eventRefundConfiguration?.refundEndDate !== null &&
    moment().isAfter(eventRefundConfiguration?.refundEndDate);

  if (!eventRefundConfiguration || !isRefundEnabledForEvent(eventRefundConfiguration))
    return <div>{t('refundRequest.noRefundForEvent')}</div>;
  if (isPassedRefundEndDate)
    return (
      <div>
        {t('refundRequest.refundPeriodConcluded', {
          event: eventName,
          end_date: getRefundEndDate(eventRefundConfiguration),
        })}
      </div>
    );
  return (
    <div>
      {t('refundRequest.notInRefundWindow', {
        start_date: getRefundStartDate(eventRefundConfiguration),
        end_date: getRefundEndDate(eventRefundConfiguration),
      })}
    </div>
  );
};

const RefundRequest = () => {
  const eventDetails = useSelector(selectEventSelectedDetails);
  const eventRefundConfiguration = useSelector(selectEventRefundConfiguration);
  const eventName = useSelector(selectEventSelectedNameEn);
  const { securedApi } = useData();
  const { t } = useTranslation();
  const isMobile = useMediaQuery();

  const { control, watch } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        transferOption: yup.string(),
      }),
    ),
  });
  const { resetFormState, refundRequestFailed, refundSubmitted } = useRefundContext();
  const selectedTransferOption = watch('transferOption');

  const transferOptions = useMemo(
    () => getTransferOptionsByTemplateType(eventRefundConfiguration?.templateType, t),
    [eventRefundConfiguration, t],
  );

  const RefundForm = useMemo(() => {
    if (!eventRefundConfiguration) {
      return null;
    }

    return getFormByTransferOption(
      eventRefundConfiguration.templateType,
      selectedTransferOption || transferOptions[0].value,
    );
  }, [eventRefundConfiguration, transferOptions, selectedTransferOption]);

  if (!eventDetails) return <Spinner />;

  const handleOpenPolicy = async () => {
    const refundPolicyPDFLink = eventRefundConfiguration?.refundPolicyPDFLink;
    if (refundPolicyPDFLink) {
      const rawBlob = await securedApi!.RefundApi.fetchRefundPolicy(refundPolicyPDFLink);
      const pdfBlob = new Blob([rawBlob!], { type: 'application/pdf' });
      window.open(URL.createObjectURL(pdfBlob), '_blank');
    }
  };

  return (
    <div className='flex-1 md:p-4 max-w-7xl'>
      <div className={classNames('flex justify-between items-center pb-3', { hidden: isMobile })}>
        <p className='text-[21px] font-bold'>{t('refundRequest.title')}</p>
      </div>
      {refundSubmitted && (
        <RefundStateMessage
          onBackCallback={resetFormState}
          refundRequestFailed={refundRequestFailed}
          refundSubmitted={refundSubmitted}
        />
      )}
      {!refundSubmitted ? (
        <>
          {!eventRefundConfiguration || !isRefundFormAvailable(eventRefundConfiguration) ? (
            <div className='mb-4 pl-8 pr-8 text-slate-500'>
              {refundNonAvailabilityMessage(eventRefundConfiguration, t, eventName)}
            </div>
          ) : (
            <>
              <Card className='shadow-sm border-0 max-w-7xl'>
                <CardBody className='flex flex-col md:flex-row'>
                  <div className='w-full md:w-3/12'>
                    <div className='bg-white p-4 rounded-md'>
                      <span className={`${!isMobile && 'text-sm'} text-gray-500`}>
                        {t('refundRequest.sideInfo.description')}
                      </span>
                      <div className={`mt-4 ${isMobile && 'text-gray-500'}`}>
                        {t('refundRequest.sideInfo.refundFeeText')}
                        <span className={isMobile ? 'ml-3' : 'ml-1'}>
                          {getRefundFeeForCurrency(
                            eventRefundConfiguration.refundCurrency,
                            eventRefundConfiguration.refundFee,
                          )}
                        </span>
                      </div>
                      <div className='mt-4'>
                        <span className='text-sm text-gray-500'>
                          {t('refundRequest.sideInfo.refundPolicyWrapper')}
                          <span
                            className='text-violet-500 cursor-pointer'
                            onClick={handleOpenPolicy}
                          >
                            {' '}
                            {t('refundRequest.sideInfo.refundPolicyText')}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='w-full md:w-9/12 md:ml-6'>
                    <div
                      hidden={transferOptions.length <= 1}
                      className={`bg-white md:p-4 rounded-md w-full md:w-8/12 ${isMobile && 'p-4'}`}
                    >
                      <div className={isMobile ? '' : 'pb-3'}>
                        <Label className='text-gray-500'>
                          {t('refundRequest.form.transferType')}
                        </Label>
                        <ControlledRadioButton
                          control={control}
                          defaultValue={transferOptions[0]?.value}
                          fieldName='transferOption'
                          options={transferOptions}
                        />
                      </div>
                    </div>
                    {!isMobile && RefundForm}
                  </div>
                </CardBody>
              </Card>
              {isMobile && <Card className='my-8 shadow-sm border-0 max-w-7xl'>{RefundForm}</Card>}
            </>
          )}
          {/* Hide refund policy button when not configuration was saved for event */}
          <div
            hidden={!eventRefundConfiguration || isRefundFormAvailable(eventRefundConfiguration)}
            className='flex items-center justify-center'
          >
            <Button
              onClick={handleOpenPolicy}
              className={`${isMobile ? 'w-full mx-4' : 'w-48'} btn btn-secondary btn-sm`}
            >
              {t('refundRequest.refundPolicyButton')}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default RefundRequest;
