import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { formatCurrency } from '../../helpers/currency';

import { Table, TableCell, Box, Section, TextField, Loader } from 'components';
import { Order, useData } from 'data';
import { formatDateToMed } from 'helpers';
import { selectEventCurrency } from 'store';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

const theadData = [
  { Name: 'Product Type', className: 'text-left w-[3%]' },
  { Name: 'Top-Up Type', className: 'text-center' },
  { Name: 'Subtotal', className: 'text-center' },
];

const TopUpTransactionDetails = ({ order }: { order: Order }) => {
  const { securedApi } = useData();
  const currency = useSelector(selectEventCurrency);
  const isMobile = useMediaQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [topupType, setTopupType] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (order.folioId && order.topupTypeUuid) {
        setIsLoading(true);
        const data = await securedApi!.OrdersApi.getTopupTypeById(
          order.folioId,
          order.topupTypeUuid,
        );

        if (data.entry && data.entry.customName) {
          setTopupType(data.entry.customName);
        } else {
          // Non-custom Topups will have their 'topupTypeUuid' as Top-up Type name
          setTopupType(order.topupTypeUuid);
        }
        setIsLoading(false);
      } else {
        setTopupType(order.topupTypeUuid ? order.topupTypeUuid : 'Intellitix');
        setIsLoading(false);
      }
    };

    fetchData();
  }, [order.folioId, order.topupTypeUuid, securedApi]);

  if (isLoading) return <Loader />;

  return isMobile ? (
    <div className={`w-[${window.innerWidth}px] bg-white px-4 pt-2 mt-3 mx-[-24px] pb-3`}>
      <div className='mt-2'>
        <div className='pb-3'>
          <TextField value='Intellitix' label='Vendor' isDisabled />
        </div>
        <div className='pb-3'>
          <TextField value={formatDateToMed(order.createdOn)} label='Date' isDisabled />
        </div>
        <div className='pb-3'>
          <TextField value='Top-Up' label='Transaction' isDisabled />
        </div>
        <div className='pb-0'>
          <TextField value={order.uuid} label='Order ID' isDisabled />
        </div>
      </div>

      <h2 className='text-lg my-4 text-gray-500'>Top-Up Summary</h2>

      <div className='pt-0'>
        <div className='rounded-lg border border-gray-100 p-3'>
          <div className='flex justify-between items-center mb-3'>
            <div>Top-Up</div>
            <div>{topupType}</div>
          </div>

          <div className='border-b border-gray-100 mb-3'></div>
          <div className='flex justify-between items-center'>
            <div>Total</div>
            <div className='font-extrabold'>{formatCurrency(order.totalPrice / 100, currency)}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <Box className='p-12 mt-[12px]'>
        <div className='md:flex md:justify-between'>
          <div className='md:w-1/2 md:pr-8'>
            <Section
              title='Details'
              titleClassName='text-xl md:text-3xl'
              className='col-span-12  justify-end'
              description='There are the specifics of your transaction.'
            />
          </div>
          <div className='md:w-1/2 md:pr-8'>
            <div className='pb-3'>
              <TextField value={formatDateToMed(order.createdOn)} label='Date' isDisabled />
            </div>
            <div className='pb-3'>
              <TextField value='Top-Up' label='Transaction' isDisabled />
            </div>
            <div className='pb-3'>
              <TextField value={order.uuid} label='Order ID' isDisabled />
            </div>
          </div>
        </div>
        <div className='w-full h-[1px] bg-[#E8EAED] mt-6 mb-12' />

        <Table theadData={theadData} className='w-full rounded-lg mb-12'>
          <tr>
            <TableCell className='py-4 px-6 text-custom-primary text-base' data={'Top-Up'} />
            <TableCell className='text-center text-base text-[#6F7FAF]' data={topupType} />
            <TableCell
              className='py-4 px-6 text-base text-[#6F7FAF] text-center'
              data={formatCurrency(order.totalPrice / 100, currency)}
            />
          </tr>
        </Table>
      </Box>
    </>
  );
};

export default TopUpTransactionDetails;
