import React, { FC, ReactNode } from 'react';
import { Provider } from 'react-redux';

import { store } from 'store';

type ReduxProviderType = {
  children: ReactNode;
};

const ReduxProvider: FC<ReduxProviderType> = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

export default ReduxProvider;
