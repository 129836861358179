import React, { FC, ReactNode } from 'react';

type BoxProps = {
  className?: string;
  children?: ReactNode;
  removeBackground?: boolean;
};

const Box: FC<BoxProps> = ({ className, children, removeBackground }) => {
  const additionalClasses = removeBackground
    ? ''
    : 'shadow-[0px_4px_8px_8px_rgba(146,146,210,0.1)] p-[1.5rem] bg-[#FFFFFF] rounded-lg';

  const combinedClasses = `${additionalClasses} ${className}`;

  return <div className={`w-full ${combinedClasses}`}>{children}</div>;
};

export default Box;
