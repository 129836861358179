import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { t } from 'i18next';

import { mapArrOfObjectsByKey } from 'helpers';

export enum OrdersActions {
  TRANSFER_TICKET = 'ordersData/transferTicket',
  FETCH_EVENT_TICKETS = 'ordersData/fetchEventTickets',
  FETCH_PRODUCTS = 'orders/fetchProducts',
  CREATE_TOP_UP_ORDER = 'orders/createTopUpOrder',
  FETCH_TICKETS_BY_ACCOUNT_ID = 'orders/fetchTicketsByAccountId',
  SEND_EMAIL_RECEIPT = 'orders/sendEmailReceipt',
}

export const transferTicketAction = createAsyncThunk(
  OrdersActions.TRANSFER_TICKET,
  async ({ securedApi, id, body }: any) => {
    const response = await securedApi.OrdersApi.transferTicket(id, body);
    return response;
  },
);

export const fetchEventTicketsAction = createAsyncThunk(
  OrdersActions.FETCH_EVENT_TICKETS,
  async ({ securedApi, eventId }: any) => {
    const { validTickets, transferredTickets } =
      await securedApi.MiddlewareApi.fetchEventTickets(eventId);
    return {
      userTickets: mapArrOfObjectsByKey(validTickets, 'uuid'),
      transferredTickets: mapArrOfObjectsByKey(transferredTickets, 'uuid'),
    };
  },
);

export const fetchProductsAction = createAsyncThunk(
  OrdersActions.FETCH_PRODUCTS,
  async ({ securedApi, topUpFolioId }: any) => {
    const response = await securedApi.OrdersApi.getProducts(topUpFolioId);
    return response;
  },
);

export const fetchTicketsByAccountIdAction = createAsyncThunk(
  OrdersActions.FETCH_TICKETS_BY_ACCOUNT_ID,
  async ({ securedApi, accountId }: any) => {
    const response = await securedApi.MiddlewareApi.fetchTicketsByAccountId(accountId);
    return response;
  },
);

export const sendEmailReceiptAction = createAsyncThunk(
  OrdersActions.SEND_EMAIL_RECEIPT,
  async ({ securedApi, orderId }: any) => {
    try {
      const response = await securedApi.OrdersApi.sendEmailReceipt(orderId);
      toast.success(t('Transactions.toast.sendEmailReceiptSuccess'));
      return response;
    } catch (error: any) {
      toast.error(error.response.data.msg);
      return error;
    }
  },
);

export const createTopUpOrder = createAsyncThunk(
  OrdersActions.CREATE_TOP_UP_ORDER,
  async (props: any) => {
    const body = {
      contact_info: {
        email: props.userPrimaryEmail,
        first_name: props.userFirstName,
        last_name: props.userLastName,
        phone: '',
      },
      autoCheckout: true,
      buyerTimeZone: 'America/Toronto',
      preferedLanguage: 'en',
      products: [
        {
          answers: [
            {
              questionUuid: 'walletId',
              response: props.currenWalletId, // this is wallet id from the user
            },
            {
              questionUuid: 'rfidUid',
              response: props.rfidUid,
            },
          ],
          quantity: props.amount,
          uuid: props.topUpToken,
        },
      ],
    };
    props.eventId = props.selectedEventTopUpFolioId; // this is the top up folio id

    const response = await props.securedApi?.OrdersApi.createTopUpOrder(props.eventId, body);
    return response;
  },
);
