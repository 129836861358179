import i18next from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import English from './languages/en.json';
import Spanish from './languages/es.json';

i18next
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['queryString', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['cookie'],
    },
    resources: {
      en: {
        translation: English,
      },
      es: {
        translation: Spanish,
      },
    },
    fallbackLng: 'en',
  });

export default i18next;
