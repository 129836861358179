import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const transactionSearchSchema = yup.object().shape({
  wristbandId: yup.string().required('Wristband/Card UID is required'),
  securityId: yup
    .string()
    .matches(/^\d+$/, 'Must be only digits')
    .required('Security ID is required'),
});

export const useTransactionSearchForm = () =>
  useForm({
    resolver: yupResolver(transactionSearchSchema),
    defaultValues: {
      wristbandId: '',
      securityId: '',
    },
  });
