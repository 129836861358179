import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import here as must be overridden by custom styles
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import App from './App';
import './i18n/i18n';

Sentry.init({
  dsn: 'https://197cb98a2cbf080841c69df2280339e1@o4505918946672640.ingest.sentry.io/4505919125520384',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: [
      //   'localhost',
      //   'sandbox.webapp.intellitix',
      //   'stagging.webapp.intellitix',
      //   /^https:\/\/yourserver\.io\/api/,
      // ],
    }),
    new Sentry.Replay(),
  ],
  environment: process.env.REACT_APP_ENV,
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.REACT_APP_ENV !== 'sandbox',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
