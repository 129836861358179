/**
 * Converts array of objects into object using key
 *
 * Examples:
 * [{ id: 1 }, { id: 2 }] --> { 1: { id: 1 }, 2: { id: 2 } } // key === 'id'
 * [{ n: a }, { n: b }] --> { a: { n: a }, b: { n: b } } // key === 'n'
 *
 * @param arr - only array of objects
 * @param key - string
 * @returns object with nested as key-value objects from array
 */
export const mapArrOfObjectsByKey = (arr: any[], key: string) =>
  arr.reduce((acc: any, obj: any) => {
    if (!key || !obj || !Object.prototype.hasOwnProperty.call(obj, key)) return acc;

    return {
      ...acc,
      [obj[key]]: { ...obj },
    };
  }, {});
