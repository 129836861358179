import React, { FC, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Input, InputGroup, InputGroupText } from 'reactstrap';
import { useSelector } from 'react-redux';

import {
  buildCurrencyBalance,
  getCurrencySymbolForCurrencyCode,
  getMinimumTopUpAmount,
} from '../../../helpers/currency';

import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { selectEventCurrency } from 'store';

type CurrencyAmountConverterProps = {
  topUpAmount: number;
  onChange: (value: number, event: ChangeEvent<HTMLInputElement>) => void;
};

const CurrencyAmountConverter: FC<CurrencyAmountConverterProps> = ({ topUpAmount, onChange }) => {
  const isMobile = useMediaQuery();
  const currency = useSelector(selectEventCurrency);
  const { t } = useTranslation();
  const currencySymbol = getCurrencySymbolForCurrencyCode(currency);

  const handleKeyPress = (event: any) => {
    if (!/\d/.test(event.key) || event.key === '-') {
      event.preventDefault();
    }
  };

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (Number(value) >= 0) {
      onChange(Number(value === '' ? 0 : parseInt(value, 10)), event);
    }
  };

  const minimumAmount = getMinimumTopUpAmount(currency);

  return (
    <div className='pt-3'>
      <div className='flex justify-between'>
        <Label>{t('TopUpModal.amount')}</Label>
        {!isMobile && <Label>{t('TopUpModal.eventCurrency')}</Label>}
      </div>
      <div>
        {/* <div className={`${isMobile ? "block" : "flex"}  lg:pl-5`}> */}
        <div className={`${isMobile ? 'block' : 'flex'}`}>
          <div className='w-full'>
            <InputGroup>
              {/* TODO $ needs to be from event info */}
              <InputGroupText>{currencySymbol}</InputGroupText>
              <Input
                type='tel'
                pattern='[0-9]*'
                value={topUpAmount}
                onKeyPress={handleKeyPress}
                onChange={handleNumberChange}
              />
              {/* TODO currency needs to be from event info */}
              <InputGroupText>{currency ? currency : 'CAD'}</InputGroupText>
            </InputGroup>
          </div>
          {!isMobile && (
            <>
              <div className='p-1 pr-1'>
                <Label className='text-center text-lg'>=</Label>
              </div>

              <div className='w-full inline-block'>
                <InputGroup>
                  {/* TODO $ needs to be from event info */}
                  <InputGroupText>{currencySymbol}</InputGroupText>

                  <Input
                    id='widgetCode'
                    type='number'
                    rows={1}
                    disabled={true}
                    value={topUpAmount.toFixed(2)}
                  />
                  {/* TODO currency needs to be from event info */}
                </InputGroup>
              </div>
            </>
          )}
        </div>
      </div>
      <label className={isMobile ? 'pt-2' : 'pt-1 text-left'}>
        A minimum of {buildCurrencyBalance(currency, currencySymbol, minimumAmount)} must be added.
      </label>
    </div>
  );
};

export default CurrencyAmountConverter;
