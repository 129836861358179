import { yupResolver } from '@hookform/resolvers/yup'; // Import yupResolver
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup'; // Import yup package

import RefundFormContainer from './RefundFormContainer';
import useRefundContext from './RefundContext';

import { ControlledSelectField, ControlledTextField, Label } from 'components';
import { useData } from 'data';
import { AccountTypeOptions, manualRefundRequestBody } from 'data/api/RefundApi';

const refundRequestSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  addressLine1: yup.string().required('Address line 1 is required'),
  addressLine2: yup.string(),
  routingTransitNumber: yup
    .string()
    .required('Routing transit number is required')
    .matches(/^\d+$/, 'Numbers only') // Digits only
    .max(9, 'The security code should be 9 digits only')
    .min(9, 'The security code should be 9 digits only'),
  accountNumber: yup
    .string()
    .required('Account Number is required')
    .matches(/^\d+$/, 'Numbers only')
    .max(15, 'The Account number should be 5 to 15 digits only')
    .min(5, 'The Account number should be 5 to 15 digits only'),
  accountType: yup.string().required('Account Type is required'),
});

const resolver = {
  resolver: yupResolver(refundRequestSchema),
  defaultValues: {
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    routingTransitNumber: '',
    accountNumber: '',
    accountType: '',
  },
};

const RefundUS = () => {
  const { securedApi } = useData();
  const { control, reset, handleSubmit } = useForm({ ...resolver, mode: 'all' });
  const { eventId } = useParams();
  const {
    setRefundSubmitted,
    setRefundRequestFailed,
    setIsLoading,
    openModal,
    setIsRefundFormValid,
    resetFormState,
  } = useRefundContext();

  const onSubmit = async (value: typeof control._formValues) => {
    setIsLoading(true);

    const body: manualRefundRequestBody = {
      firstName: value.firstName,
      lastName: value.lastName,
      addressLine1: value.addressLine1,
      addressLine2: value.addressLine2,
      routingTransitNumber: value.routingTransitNumber,
      accountNumber: String(value.accountNumber),
      accountType: String(value.accountType),
      eventId: eventId ? eventId : '',
      refundType: 'USA',
    };

    const response = await securedApi?.RefundApi.manualRefundRequest(body);
    setRefundSubmitted(true);
    if (response?.status !== 201) {
      setRefundRequestFailed(true);
    }
    setIsLoading(false);
    setRefundSubmitted(true);
    reset();
    resetFormState();
  };

  const validationCheck = async () => {
    const result = await refundRequestSchema.isValid(control._formValues);
    if (result) {
      setIsRefundFormValid(true);
      openModal();
    } else {
      setIsRefundFormValid(false);
      handleSubmit(onSubmit as any)();
    }
  };

  const USForm = () => (
    <div className='lg:col-span-3 m-3'>
      <Label className='text-gray-500 mb-3'>Contact Information</Label>
      <div className='pb-3'>
        <div className='flex flex-col md:flex-row sm:gap-3 md:gap-5'>
          <ControlledTextField control={control} fieldName='firstName' label='First Name*' />
          <ControlledTextField control={control} fieldName='lastName' label='Last Name*' />
        </div>
      </div>
      <div className='pb-3'>
        <div className='flex flex-col md:flex-row sm:gap-3 md:gap-5'>
          <ControlledTextField
            control={control}
            fieldName='addressLine1'
            label='Address line 1*'
            type='string'
          />
          <ControlledTextField
            control={control}
            fieldName='addressLine2'
            label='Address line 2'
            maxLength={11}
          />
        </div>
      </div>
      <Label className='text-gray-500 mb-3 mt-3'>Bank Details</Label>
      <div className='pb-3'>
        <div className='flex flex-col md:flex-row gap-5'>
          <ControlledTextField
            control={control}
            fieldName='routingTransitNumber'
            label='Routing / Transit Number*'
            maxLength={9}
          />
        </div>
      </div>
      <div className='pb-3'>
        <div className='flex flex-col md:flex-row sm:gap-3 md:gap-5'>
          <ControlledTextField
            control={control}
            fieldName='accountNumber'
            label='Account Number*'
          />
          <ControlledSelectField
            defaultValue=''
            control={control}
            fieldName='accountType'
            label='Account Type*'
            options={AccountTypeOptions}
          />
        </div>
      </div>
    </div>
  );
  return (
    <RefundFormContainer
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      validationCheck={validationCheck}
    >
      <USForm />
    </RefundFormContainer>
  );
};

export default RefundUS;
