import { createSlice } from '@reduxjs/toolkit';

import { resetAll } from 'store/common';
import {
  isFulfilledAction,
  isRejectedAction,
  isPendingAction,
  handleFulfilledAction,
  handleRejectedAction,
  handlePendingAction,
} from 'store/ui';

export type UiState = {
  loadingActions: string[];
  rejectedActions: Record<string, unknown>;
};

const initialState = {
  loadingActions: [],
  rejectedActions: {},
} as UiState;

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resetAll, () => initialState)
      .addMatcher(isPendingAction, handlePendingAction)
      .addMatcher(isRejectedAction, handleRejectedAction)
      .addMatcher(isFulfilledAction, handleFulfilledAction);
  },
});

export default uiSlice.reducer;
