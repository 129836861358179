import React from 'react';
import { useTranslation } from 'react-i18next';

import { ControlledDatePicker, ControlledSelectField, ControlledTextField } from 'components';
import { selectUserData, selectUiIsUpdateUserLoading, useAppSelector } from 'store';
import { useManageAccountDetailsForm } from 'pages/ManageProfileInfo/hooks/useManageProfileForm';
import { Button } from 'pages/auth/components';

type AccountDetailsProps = {
  onContinue: (data: any) => void;
  options: { label: string; value: string }[];
};

const AccountDetails = ({ onContinue, options }: AccountDetailsProps) => {
  const userData = useAppSelector(selectUserData);
  const { control, formState, getValues } = useManageAccountDetailsForm(userData);
  const { isValid, isDirty } = formState;
  const { t } = useTranslation();
  const isUpdateUserLoading = useAppSelector(selectUiIsUpdateUserLoading);

  const handleContinue = () => {
    const formData = getValues();
    if (isValid) onContinue(formData);
  };

  return (
    <>
      <div className='text-custom-primary text-2xl py-3'>{t('AccountCreation.title')}</div>
      <div className='text-sm text-custom-secondary pb-4'>{t('AccountCreation.description')}</div>
      <div className='flex flex-col md:flex-row gap-3'>
        <ControlledDatePicker
          control={control}
          fieldName='dob'
          label='Date of Birth'
          placeholder='yyyy-mm-dd'
        />
        <ControlledSelectField
          control={control}
          defaultValue='male'
          fieldName='gender'
          label='Gender'
          options={options}
        />
        <ControlledTextField
          control={control}
          placeholder='(000) 000-0000'
          type='tel'
          fieldName='phoneNumber'
          label='Phone Number'
        />
      </div>
      <Button
        block
        style={{ borderRadius: '0.75rem', border: 'none' }}
        isLoading={isUpdateUserLoading}
        onClick={handleContinue}
        disabled={!isDirty || !isValid}
        className='mt-3 bg-intelli-primaryNew py-3 '
      >
        Continue
      </Button>
    </>
  );
};

export default AccountDetails;
