import { Config } from '../Config';

import { ClientConfig, SecuredApi } from './Api';

import { AccessTicket } from 'data';
import { paths } from 'data/constants';

interface AccessControlApi {
  fetchAccessTickets: (eventUri: string) => Promise<AccessTicket[]>;
}

export class AccessControlApiImpl extends SecuredApi implements AccessControlApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return {
      baseURL: Config.ACCESS_CONTROL,
      timeout: 15000,
    };
  };

  fetchAccessTickets = async (eventUri: string): Promise<AccessTicket[]> => {
    const response = await this.client.get(paths.getAccessTickets(eventUri));
    return response.data.entries;
  };
}

export default AccessControlApiImpl;
