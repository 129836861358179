import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { resourceToUri } from 'jwt-cert-sdk';

import { Loader } from 'components';
import useLedger from 'data/useLedger';
import { getDateFormat } from 'helpers';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { EventDetails, EventSummaryType } from 'data';
import { Navigation } from 'navigation';
import { useEventServiceIdentity } from 'data/api/EventApi';

const Cell = ({ className, children }: { className: string; children: ReactNode }) => (
  <div className={className}>{children}</div>
);

interface EventTableItemProps {
  uuid: string;
  eventDetails: EventDetails;
  onViewEventClick: (uuid: string) => void;
  eventSummary: EventSummaryType;
}

const EventTableItem: React.FC<EventTableItemProps> = ({
  uuid,
  eventDetails,
  eventSummary,
  onViewEventClick,
}) => {
  const eventServiceIdentity = useEventServiceIdentity();

  const [hasWristbandAssigned, setHasWristbandAssigned] = useState<boolean | null>(null);
  const { fetchEventWallet, currentWallet } = useLedger();
  const isMobile = useMediaQuery();
  const wristbandJustClaimed = localStorage.getItem('ClaimedWristband') ? true : false;

  const eventUri = eventServiceIdentity
    ? resourceToUri({
        resourceId: uuid,
        serviceId: eventServiceIdentity?.sub,
        resourceType: 'cb:event',
      })
    : null;

  useEffect(() => {
    const fetchWalletForEvent = async () => {
      const ledgerId = eventDetails?.distributedLedgerId;
      if (ledgerId) {
        const walletInfo: any = await fetchEventWallet(ledgerId, wristbandJustClaimed);
        setHasWristbandAssigned(
          walletInfo && walletInfo.rfid && walletInfo.rfid !== '' ? true : false,
        );
        if (walletInfo) {
          localStorage.removeItem('ClaimedWristband');
        }
      } else {
        setHasWristbandAssigned(false);
      }
    };

    const interval = setInterval(() => {
      // Fetch wallet every 3 seconds if no wallet
      fetchWalletForEvent();
    }, 3000);

    // Clear Interval if we have a wallet or we didn't claim
    if (currentWallet || !wristbandJustClaimed) {
      clearInterval(interval);
    }
    fetchWalletForEvent(); // Call it before setInterval calls the API

    return () => clearInterval(interval);
  }, [eventDetails, fetchEventWallet, currentWallet, wristbandJustClaimed]);

  const renderActionButton = (): ReactNode => {
    if (!eventDetails) {
      return 'Loading...';
    }

    if (wristbandJustClaimed) {
      return (
        <div className='flex items-center'>
          <Loader size={40} variant='inline' />
          <span className='pl-1'>Account is being Prepared...</span>
        </div>
      );
    }

    if (hasWristbandAssigned === null) return 'Loading...';

    if (hasWristbandAssigned) {
      return (
        <Link
          to={`${uuid}/event-balance`}
          className={`font-normal py-1 rounded text-medium text-intelli-primary ${
            isMobile ? '' : 'px-3'
          }`}
          onClick={() => onViewEventClick(uuid)}
        >
          {isMobile ? (
            <Button
              color='primary'
              size={`${isMobile ? 'lg' : 'sm'}`}
              onClick={() => onViewEventClick(uuid)}
            >
              View
            </Button>
          ) : (
            ' View'
          )}
        </Link>
      );
    }

    if (!hasWristbandAssigned && !wristbandJustClaimed) {
      return (
        <Link
          to={`${Navigation.CLAIM_WRISTBAND}?folioId=${eventUri}&securityIdOnly=${
            eventDetails.claimCredentials === 'Security Code Only'
          }&ledgerId=${eventDetails?.distributedLedgerId}`}
          className={`text-[#39A3F7] font-normal py-1 rounded text-medium ${
            isMobile ? '' : 'px-3'
          }`}
        >
          {isMobile ? (
            <Button
              color='secondary'
              className={`btn btn-primary btn-sm w-full md:w-auto ${isMobile && 'py-4'}`}
            >
              Add Wristband
            </Button>
          ) : (
            'Add Wristband'
          )}
        </Link>
      );
    }
  };

  return (
    <div
      key={uuid}
      className={`${
        isMobile ? 'block border-b-4' : 'flex border-b'
      } justify-between items-center py-2 px-6`}
    >
      <Cell
        className={`${
          isMobile ? 'border-b font-bold' : 'flex-1 text-center font-normal'
        }  text-[#232E59] text-lg py-3`}
      >
        {eventSummary.name.en}
      </Cell>
      {isMobile ? (
        <Cell className='flex-1 Font-light text-sm text-[#6F7FAF] pt-3'>START DATE</Cell>
      ) : null}
      <Cell
        className={`${
          isMobile ? 'pb-3 pt-1' : 'flex-1 text-center py-3 text-[#6F7FAF]'
        } font-light`}
      >
        {getDateFormat(eventSummary.startDate)}
      </Cell>
      <Cell className={`${isMobile ? 'pb-3 pt-1' : 'flex-1 text-center py-3'} font-light`}>
        {renderActionButton()}
      </Cell>
    </div>
  );
};

export default EventTableItem;
