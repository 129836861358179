import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useSelector } from 'react-redux';

import {
  buildCurrencyBalance,
  getCurrencySymbolForCurrencyCode,
  getMinimumTopUpAmount,
} from '../../helpers/currency';

import { CurrencyAmountConverter, PayNowModal, Button, Box, Section, Loader } from 'components';
import useLedger from 'data/useLedger';
import { useOrders } from 'data/useOrders';
import usePayments from 'data/usePayments';
import '../../helpers/fontAwesome'; // Requred for icons to work
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import {
  selectEventCurrency,
  selectUiIsCreateTopUpOrderLoading,
  selectUiIsFetchProductsLoading,
  selectUiIsFetchingPaymentMethods,
  useAppSelector,
} from 'store';
import { PAYMENT_STATUS_END_STATES } from 'store/payments/paymentsReducer';

function TopUp() {
  const { getAllPaymentMethods } = usePayments();
  const { createTopUpOrderAndPayWithNewCreditCard, createTopUpOrderAndPayWithExistingCreditCard } =
    useOrders();
  const isMobile = useMediaQuery();
  const currency = useSelector(selectEventCurrency);
  const [topUpAmount, setTopUpAmount] = useState(() => getMinimumTopUpAmount(currency));
  const { t } = useTranslation();
  const {
    currentWalletBalance,
    currentWallet,
    updateWalletBalanceOptimistically,
    isWristbandFrozen,
  } = useLedger();
  const [payEnabled, setPayEnabled] = useState(true);
  const { paymentStatus } = usePayments();
  const isFetchProductLoading = useAppSelector(selectUiIsFetchProductsLoading);
  const isCreateTopUpOrderLoading = useAppSelector(selectUiIsCreateTopUpOrderLoading);
  const isFetchingPaymentMethods = useAppSelector(selectUiIsFetchingPaymentMethods);
  const paymentIsLoading =
    isFetchProductLoading || isCreateTopUpOrderLoading || paymentStatus !== '';
  const [paymentFailed, setPaymentFailed] = useState(false);

  const [payModal, setPayModal] = useState(false);
  const currencySymbol = getCurrencySymbolForCurrencyCode(currency);
  const balance = buildCurrencyBalance(currency, currencySymbol, currentWalletBalance);
  const newEventBalance = buildCurrencyBalance(
    currency,
    currencySymbol,
    currentWalletBalance + topUpAmount,
  );

  const handlePayButtonOnClick = useCallback(
    async (paymentMethodId: string, callback: () => void) => {
      setPayEnabled(false); // Disable while API call is running
      setPaymentFailed(false);
      try {
        await createTopUpOrderAndPayWithExistingCreditCard(paymentMethodId, topUpAmount * 100);
      } catch {
        setPaymentFailed(true);
      } finally {
        setPayEnabled(true); // Enable after payment finished
        callback();
      }
    },
    [createTopUpOrderAndPayWithExistingCreditCard, topUpAmount],
  );

  const onCurrencyAmountChange = (value: number) => {
    setTopUpAmount(value);
  };

  const handleToggle = () => {
    setPayModal(!payModal);
  };

  // Create a new order and pay with a new credit card
  const instantPayWithCardInfo = useCallback(
    async (cardInfo: any, callback?: () => void) => {
      setPayEnabled(false);
      setPaymentFailed(false);
      try {
        await createTopUpOrderAndPayWithNewCreditCard(cardInfo, topUpAmount * 100);
      } catch {
        setPaymentFailed(true);
      } finally {
        setPayEnabled(true);
        callback && callback();
      }
    },
    [createTopUpOrderAndPayWithNewCreditCard, topUpAmount],
  );

  useEffect(() => {
    getAllPaymentMethods();
  }, [getAllPaymentMethods]);

  // Set minimum Top-Up amount based on fetched currency
  useEffect(() => {
    setTopUpAmount(() => getMinimumTopUpAmount(currency));
  }, [currency, setTopUpAmount]);

  // Handle Balance Update after payment
  useEffect(() => {
    if (paymentStatus) {
      if (
        PAYMENT_STATUS_END_STATES.includes(paymentStatus) &&
        paymentStatus === 'payment-succeeded'
      ) {
        // Payment was successfull, update the redux store with new balance without actually fetching the wallet again
        updateWalletBalanceOptimistically((currentWalletBalance + topUpAmount) * 100);
        setPaymentFailed(false);
      }
    }
    /* eslint-disable-next-line */
  }, [paymentStatus]);

  return (
    <div className='flex-1 mt-[16px]'>
      <Box>
        {paymentFailed && (
          <div className='text-red-600'>Top-up Failed. Please try again or contact support</div>
        )}
        <Section
          description={t('TopUpModal.addFunds')}
          className='lg:col-span-2'
          childrenClassName='lg:col-start-4'
        >
          {isFetchingPaymentMethods || !currentWallet ? (
            <Loader className='relative pb-[160px] !top-20' />
          ) : (
            <div className='text-[#232E59]'>
              <div className={`${isMobile ? 'block' : 'flex'}`}>
                <div className='lg:pl-5'>
                  <div className={isMobile ? 'flex justify-between pb-4 border-b' : ''}>
                    <div>{t('TopUpModal.initialBalance')}</div>
                    <div className={isMobile ? 'text-xl' : ''}>{balance}</div>
                  </div>
                  <div className={isMobile ? 'pb-4 border-b' : ''}>
                    <CurrencyAmountConverter
                      topUpAmount={topUpAmount}
                      onChange={onCurrencyAmountChange}
                    />
                  </div>
                </div>
              </div>
              <div className={isMobile ? 'flex flex-col' : 'flex items-center pt-5'}>
                <Label className={isMobile ? 'pb-2' : 'lg:pl-5'}>
                  <div className={isMobile ? 'flex justify-between pt-4' : ''}>
                    <div>{t('TopUpModal.newBalance')}</div>
                    <div className={isMobile ? 'text-xl' : ''}>{newEventBalance}</div>
                  </div>
                </Label>
                <Button
                  isLoading={paymentIsLoading}
                  onClick={() => setPayModal(!payModal)}
                  className={
                    isMobile
                      ? 'w-full btn btn-primary text-lg btn-sm md:w-auto my-3 py-7 relative'
                      : 'ml-10 w-20 h-12 btn btn-primary '
                  }
                  disabled={
                    topUpAmount < getMinimumTopUpAmount(currency) ||
                    !payEnabled ||
                    isWristbandFrozen
                  }
                >
                  {t('TopUpModal.Pay')}
                </Button>
              </div>
            </div>
          )}
          <PayNowModal
            toggle={handleToggle}
            modal={payModal}
            onClosed={handleToggle}
            instantPayCallback={instantPayWithCardInfo}
            payWithExistingCard={handlePayButtonOnClick}
            topUpAmount={topUpAmount}
            setTopUpAmount={setTopUpAmount}
            payEnabled={payEnabled}
            newWalletBalance={newEventBalance}
          />
        </Section>
      </Box>
    </div>
  );
}

export default TopUp;
