type CardRegExpPatternsType = { [key: string]: string };

const CARD_REG_EXP_PATTERNS: CardRegExpPatternsType = {
  visa: '^4',
  master: '^5[1-5]',
  discover: '^6011',
  unionpay: '^62',
  american_express: '^37',
};

const prepareGetCardType =
  (cardRegExpPatterns: CardRegExpPatternsType) =>
  (cardNumber: string): string =>
    Object.entries(cardRegExpPatterns).reduce(
      (acc, [cardType, pattern]) => (cardNumber.match(new RegExp(pattern)) ? cardType : acc),
      '',
    ) || 'visa';

export const getCardType = prepareGetCardType(CARD_REG_EXP_PATTERNS);
