import { yupResolver } from '@hookform/resolvers/yup';
import valid from 'card-validator';
import React, { useState, useEffect, useRef } from 'react';
import Cards from 'react-credit-cards-2';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  FormText,
} from 'reactstrap';
import * as yup from 'yup';
import InputMask from 'react-input-mask';

import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { CardInfo } from 'data/model';
import usePayments from 'data/usePayments';
import { Button } from 'components/Buttons';
import { validateCardExpiryDate } from 'components/AddCard/helpers';

interface AddPaymentMethodModalProps {
  toggle: () => void;
  modal: boolean;
  onClosed?: () => void;
  instantPay?: boolean;
  autoTopUp?: boolean;
  toggleAll?: () => void;
  instantPayCallback?: (cardInfo: CardInfo) => void;
  addCard?: boolean; // If true card will automatically save payment method
}

/**
 * AddPaymentMethodModal component for adding a payment method
 * is meant to be a standalone component that can be used anywhere to add payment method
 * To use this modal you must have state in place to handle the modal being open or closed within the parent component
 * ex:
 * import 'bootstrap/dist/css/bootstrap.min.css';
 * ...
 * const [modal, setModal] = useState(false);
 * const toggle = () => setModal(!modal);
 *
 * Note: This component has been extended to support instantPay and autoTopUp
 * instantPay: boolean - if true, the modal will present a save card checkbox
 * autoTopUp: boolean - if true, the modal will present a save card checkbox and the checkbox will be checked by default
 * @param {AddPaymentMethodModalProps} { toggle, modal }
 * @returns {JSX.Element}
 */
function AddPaymentMethodModal({
  toggle,
  modal,
  instantPay = false,
  autoTopUp = false,
  instantPayCallback,
  addCard = false,
}: AddPaymentMethodModalProps): JSX.Element {
  const { addPaymentMethod, status } = usePayments();
  const { t } = useTranslation();
  const [saveCard, setSaveCard] = useState(autoTopUp);
  const inputRef = useRef<HTMLInputElement>(null);
  const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);
  const creditCardSchema = yup.object().shape({
    cardNumber: yup
      .string()
      .required(t('addPaymentMethodModal.errorCreditCardIsRequired'))
      .test(
        'test-number', // this is used internally by yup
        t('addPaymentMethodModal.errorCreditCardNumber'), //validation message
        (value) => valid.number(value).isValid,
      ), // return true false based on validation
    cardHolderName: yup
      .string()
      .required(t('addPaymentMethodModal.errorCardholderNameRequired'))
      .matches(/^[a-zA-Z\s]*$/, t('addPaymentMethodModal.errorOnlyLettersAndSpaces')),
    expiryDate: yup
      .string()
      .required(t('addPaymentMethodModal.errorExpiryDateRequired'))
      .matches(
        /^(0[1-9]|1[0-2])\/?(\d{4}|\d{2})$/,
        t('addPaymentMethodModal.errorInvalidExpiryDateFormat'),
      )
      .test('is-not-expired', 'Expiry date is expired', validateCardExpiryDate),
    cvv: yup
      .string()
      .required(t('addPaymentMethodModal.errorCVVRequired'))
      .matches(/^\d{3,4}$/, t('addPaymentMethodModal.errorCVVInvalid')),
  });

  const methods = useForm<creditCardInfo>({
    resolver: yupResolver(creditCardSchema),
  });

  type creditCardInfo = {
    cardNumber: string;
    cardHolderName: string;
    expiryDate: string;
    cvv: string;
  };

  // If autoTopUp is set then we need to set the saveCard state to true
  useEffect(() => {
    setSaveCard(autoTopUp);
  }, [autoTopUp, setSaveCard]);

  useEffect(() => {
    const defaultValues = {} as creditCardInfo;
    defaultValues.cardNumber = '';
    defaultValues.cardHolderName = '';
    defaultValues.expiryDate = '';
    defaultValues.cvv = '';
    methods.reset(defaultValues);
  }, [methods]);

  const onSubmit = (data: creditCardInfo) => {
    // expirtyDate is of format MM/YY with optional / so we need to remove the / to get the month and year
    const dateWithoutSlashes = data.expiryDate.split('/').join('');
    const separatedDate = dateWithoutSlashes.match(/.{1,2}/g);
    if (!separatedDate || !separatedDate[0] || !separatedDate[1]) {
      console.log('invalid date'); // Should never happen
      return;
    }

    const newCardInfo: CardInfo = {
      number: data.cardNumber,
      cvv: data.cvv,
      month: separatedDate[0],
      year: '20' + separatedDate[1],
      full_name: data.cardHolderName,
      cardType: undefined,
      default: false,
      zip: undefined,
      country: undefined,
    };

    // Store card under user account if saveCard is checked
    if (saveCard || addCard) {
      addPaymentMethod(newCardInfo);
    }

    if (instantPay) {
      if (instantPayCallback) {
        instantPayCallback(newCardInfo);
      }
    }
    setPaymentMethodAdded(true);
  };

  const onErrors = (errors: unknown) => {
    console.log('onErrors', errors);
  };

  const onCancelClick = () => {
    methods.reset();
    toggle();
  };

  const handleSavedCardOnChange = (e: any) => {
    setSaveCard(e.target.checked);
  };

  // Prevent scroll from changing input value
  function handleWheel() {
    if (document?.activeElement === inputRef.current) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  const preventPasteNegativeOrDecimal = (e: any) => {
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0 || pastedData % 1 !== 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    // Close Screen once payment method added
    if (status !== 'loading' && paymentMethodAdded) {
      setPaymentMethodAdded(false);
      methods.reset();
      toggle();
    }
  }, [status, toggle, paymentMethodAdded, methods]);

  //Note: is instantPay is set they we need to add a save card checkbox
  // if autotop is set then save card checkbox should be checked by default and disabled
  // if save card is not checked then we should not retain the card
  // we must use different endpoint for instant pay without saving card
  // also if instantPay is set then we close the topup modal on 'confirm'
  return (
    <Modal isOpen={modal} toggle={toggle} backdrop='static'>
      <form onSubmit={methods.handleSubmit(onSubmit, onErrors)}>
        <ModalHeader>{t('addPaymentMethodModal.addPaymentMethod')}</ModalHeader>
        <ModalBody>
          <div className='min-h-[500px] w-full'>
            <Cards
              number={methods?.watch('cardNumber')}
              expiry={methods?.watch('expiryDate')}
              cvc={methods?.watch('cvv')}
              name={methods?.watch('cardHolderName')}
            />
            <Controller
              control={methods.control}
              render={({ field: { onChange, value, name } }) => (
                <FormGroup>
                  <Label for='cardNumber'>{t('addPaymentMethodModal.number')}</Label>
                  <Input
                    id='cardNumber'
                    name={name}
                    placeholder={t('addPaymentMethodModal.enterCardNumber')}
                    type='number'
                    value={value}
                    onWheel={() => handleWheel()}
                    onChange={onChange}
                    innerRef={inputRef}
                    onKeyDown={(e) => {
                      if (e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                    onInput={(e: any) => {
                      e.target.value =
                        Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null;
                    }}
                    onPaste={(e) => preventPasteNegativeOrDecimal(e)}
                  />
                  {methods.formState.errors.cardNumber && (
                    <Label className='text-red-600'>
                      {methods.formState.errors.cardNumber.message}
                    </Label>
                  )}
                </FormGroup>
              )}
              name='cardNumber'
            />
            <Controller
              control={methods.control}
              render={({ field: { onChange, value, name } }) => (
                <FormGroup>
                  <Label for='cardHolderName'>{t('addPaymentMethodModal.name')}</Label>
                  <Input
                    id='cardHolderName'
                    name={name}
                    placeholder={t('addPaymentMethodModal.fullName')}
                    type='search'
                    value={value}
                    onChange={onChange}
                  />
                  {methods.formState.errors.cardHolderName && (
                    <Label className='text-red-600'>
                      {methods.formState.errors.cardHolderName.message}
                    </Label>
                  )}
                </FormGroup>
              )}
              name='cardHolderName'
            />
            <Controller
              control={methods.control}
              name='expiryDate'
              render={({ field: { onChange, value } }) => (
                <FormGroup>
                  <Label for='expiryDate'>{t('addPaymentMethodModal.expiryDate')}</Label>
                  <InputMask mask='99/99' maskPlaceholder='' value={value} onChange={onChange}>
                    {
                      //@ts-ignore
                      () => (
                        <Input
                          id='expiryDate'
                          name='expiryDate'
                          placeholder={t('addPaymentMethodModal.expiryPlaceHolder')}
                          type='text'
                        />
                      )
                    }
                  </InputMask>
                  {methods.formState.errors.expiryDate && (
                    <Label className='text-red-600'>
                      {methods.formState.errors.expiryDate.message}
                    </Label>
                  )}
                </FormGroup>
              )}
            />
            <Controller
              control={methods.control}
              render={({ field: { onChange, value, name } }) => (
                <FormGroup>
                  <Label for='cvv'>{t('addPaymentMethodModal.cvv')}</Label>
                  <Input
                    id='cvv'
                    name={name}
                    placeholder={t('addPaymentMethodModal.cvvPlaceHolder')}
                    type='search'
                    value={value}
                    onChange={onChange}
                  />
                  {methods.formState.errors.cvv && (
                    <Label className='text-red-600'>{methods.formState.errors.cvv.message}</Label>
                  )}
                </FormGroup>
              )}
              name='cvv'
            />
            {/* Will only be displayed if instantPay prop is set*/}
            {!!instantPay && (
              <FormGroup check>
                {/* Handle user interaction here is setState not form */}
                <Input
                  type='checkbox'
                  checked={saveCard}
                  onChange={handleSavedCardOnChange}
                  disabled={autoTopUp}
                />
                <Label className='w-full' check>
                  {t('addPaymentMethodModal.saveCard')}
                </Label>
                <FormText>{t('addPaymentMethodModal.saveCardInfo')}</FormText>
              </FormGroup>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type='submit'
            className='w-24 btn btn-primary'
            disabled={paymentMethodAdded}
            isLoading={paymentMethodAdded}
          >
            {t('buttons.done')}
          </Button>
          <Button type='reset' onClick={onCancelClick} className='w-24 btn btn-secondary'>
            {t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

export default AddPaymentMethodModal;
