import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const selectUser = (state: RootState) => state.user;

export const selectUserData = createSelector([selectUser], (user) => user.data);

export const selectUserStatus = createSelector([selectUser], (user) => user.status);

export const selectUserName = createSelector([selectUserData], (userData) => userData.name);

export const selectUserFirstName = createSelector(
  [selectUserData],
  (userData) => userData?.name?.split(' ')[0],
);

export const selectUserLastName = createSelector(
  [selectUserData],
  (userData) => userData?.name?.split(' ')[1],
);

export const selectUserPrimaryEmail = createSelector(
  [selectUserData],
  (userData) => userData.primaryEmail,
);

export const selectUserHasGender = createSelector(
  [selectUserData],
  (userData) => !!userData.gender,
);

export const selectUserHasDateOfBirth = createSelector(
  [selectUserData],
  (userData) => !!userData.dob,
);

export const selectUserHasContactInfo = createSelector([selectUserData], (userData) =>
  Object.prototype.hasOwnProperty.call(userData, 'contactInfo'),
);

export const selectUserDetailedInfoLoading = createSelector(
  (state: RootState) => state.user.detailedInfoStatus === 'loading',
  (loading) => loading,
);

export const selectUserIsLoading = createSelector(
  [selectUserStatus],
  (status) => status === 'loading',
);

export const selectUserSub = createSelector([selectUserData], (userData) => userData.sub);
