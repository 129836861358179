import React, { FC } from 'react';
import classNames from 'classnames';
import { Input as ReactStrapInput, InputProps as ReactStrapInputProps, Label } from 'reactstrap';
import { useController, UseControllerProps } from 'react-hook-form';

type InputProps = ReactStrapInputProps & UseControllerProps<any>;

const Input: FC<InputProps> = ({ control, name, className, id, label, ...restProps }) => {
  const {
    field,
    fieldState: { invalid, error },
  } = useController({ name, control });

  return (
    <>
      <ReactStrapInput
        className={classNames('bg-clip-padding', className)}
        id={id}
        {...restProps}
        {...field}
      />
      {label && (
        <Label for={id} className='mb-0 pl-2 fs--1 text-[#5e6e82]'>
          {label}
        </Label>
      )}
      {invalid && error?.message && (
        <p className='text-[#FD563D] text-xs leading-[14px] pt-1 font-light'>{error?.message}</p>
      )}
    </>
  );
};

export default Input;
