import { useCallback } from 'react';

import { useData } from './ContentProvider';

import {
  fetchUserDetailedInfoAction,
  fetchUserDataAction,
  updateUserAction,
  useAppDispatch,
  AppDispatch,
} from 'store';

export const useUser = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const { securedApi } = useData();

  const fetchUserData = useCallback(async () => {
    await dispatch(fetchUserDataAction({ securedApi }));
  }, [securedApi, dispatch]);

  const fetchUserDetailedInfo = useCallback(async () => {
    await dispatch(fetchUserDetailedInfoAction({ securedApi }));
  }, [securedApi, dispatch]);

  const updateUser = useCallback(
    async (body: any) => {
      await dispatch(updateUserAction({ securedApi, body }));
    },
    [securedApi, dispatch],
  );

  return {
    fetchUserDetailedInfo,
    fetchUserData,
    updateUser,
  };
};
