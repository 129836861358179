import React, { FC } from 'react';
import { ColorRing } from 'react-loader-spinner';
import classNames from 'classnames';

type LoaderVariants = 'absolute' | 'inline';

type LoaderProps = {
  className?: string;
  variant?: LoaderVariants;
  size?: number;
  dataTestId?: string;
};

const LOADER_CLASS_NAMES = {
  absolute: 'absolute left-0 top-50 w-full m-auto flex align-center justify-center',
  inline: '',
};

const Loader: FC<LoaderProps> = ({ className, size = 80, variant = 'absolute', dataTestId }) => (
  <div className={classNames(LOADER_CLASS_NAMES[variant], className)} data-testid={dataTestId}>
    <ColorRing
      visible={true}
      height={size}
      width={size}
      ariaLabel='blocks-loading'
      wrapperStyle={{}}
      wrapperClass='blocks-wrapper'
      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
    />
  </div>
);

export default Loader;
