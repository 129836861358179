import classNames from 'classnames';
import React, { FC, ChangeEvent } from 'react';

import { FieldWrapper, FieldWrapperProps } from '../FieldWrapper';

type TextFieldProps = FieldWrapperProps & {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  name?: string;
  value?: string;
  placeholder?: string;
  type?: string | undefined;
  maxLength?: number;
  labelClassName?: string;
  inputClassName?: string;
};

const TextField: FC<TextFieldProps> = ({
  value,
  placeholder,
  name,
  type,
  maxLength,
  onChange,
  onFocus,
  onBlur,
  isDisabled,
  inputClassName,
  className,
  ...FieldWrapperProps
}) => (
  <FieldWrapper isDisabled={isDisabled} className={className} {...FieldWrapperProps}>
    <input
      name={name}
      value={isDisabled && !value ? 'Unissued' : value}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={isDisabled}
      type={type}
      maxLength={maxLength}
      className={classNames(
        'w-full focus:outline-none bg-inherit',
        isDisabled && !value && 'text-[#C4CBDD]',
        inputClassName,
      )}
    />
  </FieldWrapper>
);

export default TextField;
