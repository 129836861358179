import { Order, OrderProduct } from 'data';

export const populateProductWithTotalAndTax = (product: OrderProduct) => {
  const unitPrice = product.items[0].unitPrice;
  const { quantity = 0 } = product;

  const unitTax =
    product.items?.[0]?.fees!.reduce((sum, fee) => {
      if (fee.feeSlug === 'fee-tax') {
        return sum + fee.price;
      }
      return sum;
    }, 0) || 0;

  const subtotal = (unitPrice + unitTax) * quantity;

  product.unitPrice = unitPrice;
  product.tax = unitTax;
  product.subtotal = subtotal;

  return product;
};

export const getOrderTotalAndTax = (
  order: Order | { products: OrderProduct[]; tip: number },
  includeTip = true,
) => {
  const products = [...order.products].flatMap(convertUndefinedPriceItemsToProducts);

  products.forEach(populateProductWithTotalAndTax);

  if (includeTip && order.tip) {
    products.push({
      label: 'Tip',
      quantity: 1,
      tax: 0,
      unitPrice: order.tip,
      subtotal: order.tip,
    } as unknown as OrderProduct);
  }

  const totalWithoutTaxes = products.reduce((total, product) => {
    return total + product.unitPrice! * product.quantity;
  }, 0);

  const totalTax = products.reduce((total, product) => {
    return total + product.tax! * product.quantity;
  }, 0);

  const orderTotal = totalWithoutTaxes + totalTax;

  return {
    totalTax,
    totalWithoutTaxes,
    orderTotal,
  };
};

export const convertUndefinedPriceItemsToProducts = (product: OrderProduct): any[] => {
  if (product.productKind === 'undefined-price') {
    if (product.items?.length > 1) {
      const productItemsAsProducts = product.items.map((item) => ({
        ...product,
        unitPrice: item.unitPrice,
        quantity: 1,
        items: [item],
      }));

      return productItemsAsProducts as OrderProduct[];
    }
  }

  return [product];
};
