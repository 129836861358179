import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { resetAll } from 'store/common';

export const PAYMENT_STATUS_END_STATES = [
  'awaiting-payer-action',
  'payment-failed-retryable',
  'payment-failed-final',
  'payment-succeeded',
];

export enum PaymentsActions {
  SET_DEFAULT_PAYMENT_METHOD = 'paymentMethods/setDefaultPaymentMethod',
  FETCH_PAYMENT_METHODS = 'paymentMethods/fetchPaymentMethods',
}

interface IPaymentsState {
  paymentMethods: any;
  status: string;
  paymentStatus: string | null;
  paymentData: any;
}

const initialState: IPaymentsState = {
  paymentMethods: [],
  status: '',
  paymentStatus: '',
  paymentData: null,
};

export const fetchPaymentMethods = createAsyncThunk(
  'paymentMethods/fetchPaymentMethods',
  async (securedApi: any) => {
    const response = await securedApi?.paymentsApi.getPaymentMethods();
    return response;
  },
);

export const createPaymentMethod = createAsyncThunk(
  'paymentMethods/createPaymentMethod',
  async (props: any, { rejectWithValue }) => {
    try {
      const response = await props.securedApi?.paymentsApi.createPaymentMethod(props.cardInfo);
      return response;
    } catch (err: any) {
      // Note: if the request failed due to network issues or the server being down, the error object will not have a `response` field.
      if (!err.response) {
        throw err;
      }

      // We got validation errors, let's return those so we can reference in our component
      return rejectWithValue(err.response.data);
    }
  },
);

export const deletePaymentMethod = createAsyncThunk(
  'paymentMethods/deletePaymentMethod',
  async (props: any) => {
    const response = await props.securedApi?.paymentsApi.deletePaymentMethod(props.uuid);
    return response;
  },
);

export const setDefaultPaymentMethod = createAsyncThunk(
  'paymentMethods/setDefaultPaymentMethod',
  async (props: any) => {
    const response = await props.securedApi?.paymentsApi.setDefaultStatus(props.uuid);
    return response;
  },
);

//order.data.paymentInfo.paymentLink.slit('paymentId=')[1],
export const processPaymentWithExistingPaymentMethod = createAsyncThunk(
  'paymentMethods/processPaymentWithExistingPaymentMethod',
  async (props: any) => {
    const response = await props.securedApi?.paymentsApi.sendPaymentMethod(
      props.paymentId,
      props.paymentMethodId,
    );
    return response;
  },
);

export const pollPayment = createAsyncThunk('paymentMethods/pollPayment', async (props: any) => {
  try {
    const response = await props.securedApi?.paymentsApi.pollPayment(props.paymentId);
    const status = response?.entry?.statusHistory.reduce((a: any, b: any) => {
      return new Date(a?.occuredOn) > new Date(b?.occuredOn) ? a : b;
    });
    if (
      // status: awaiting-payer-action is initial state or a failed state. Here keep polling if initial state
      PAYMENT_STATUS_END_STATES.includes(status.status) &&
      response?.entry?.statusHistory?.length > 1
    ) {
      return { status: status?.status, data: response?.entry };
    } else {
      setTimeout(() => {
        props.dispatch(pollPayment(props));
      }, 2000);
    }
  } catch (e) {
    return { status: 'payment-failed-final', data: null };
  }
});

export const createSpreedlyCreditCard = createAsyncThunk(
  'paymentMethods/createSpreedlyTokenizeCard',
  async (props: any) => {
    const paymentData = {
      payment_method: {
        credit_card: {
          full_name: props.cardInfo.full_name,
          number: props.cardInfo.number,
          verification_value: props.cardInfo.cvv,
          month: props.cardInfo.month,
          year: props.cardInfo.year,
        },
      },
    };
    const paymentDataStr = JSON.stringify(paymentData);

    const response = await props.securedApi?.paymentsApi.createSpreedlyCreditCard(paymentDataStr);
    return response;
  },
);

export const processPaymentWithSpreedlyToken = createAsyncThunk(
  'paymentMethods/processPaymentWithSpreedlyToken',
  async (props: any) => {
    const response = await props.securedApi?.paymentsApi.sendNewPaymentMethod(
      props.paymentId,
      props.spreedlyToken,
    );
    return response;
  },
);

export const paymentsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    clearPayment: (state) => {
      state.paymentStatus = '';
      state.paymentData = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAll, () => initialState)
      .addCase(fetchPaymentMethods.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
        const newEntities: { [key: string]: any } = {};
        action?.payload?.forEach((paymentMethod: any) => {
          newEntities[paymentMethod.uuid] = paymentMethod;
        });
        state.paymentMethods = newEntities;
        state.status = 'idle';
      })
      .addCase(fetchPaymentMethods.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createPaymentMethod.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPaymentMethod.fulfilled, (state, action) => {
        const key = action?.payload?.entry.uuid;
        const value = action?.payload?.entry;
        state.paymentMethods[key] = value;
        state.status = 'idle';
        toast.success('Payment method added successfully');
      })
      .addCase(createPaymentMethod.rejected, (state, action: any) => {
        state.status = 'failed';
        const message = action?.payload?.msg ?? 'Failed to add payment method';
        toast.error(message);
      })
      .addCase(deletePaymentMethod.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePaymentMethod.fulfilled, (state, action) => {
        const { [action?.payload?.entry.uuid]: _, ...rest } = state.paymentMethods;
        state.paymentMethods = rest;
        state.status = 'idle';
        toast.success('Payment method deleted successfully');
      })
      .addCase(deletePaymentMethod.rejected, (state) => {
        state.status = 'failed';
        toast.error('Failed to delete payment method');
      })
      .addCase(setDefaultPaymentMethod.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setDefaultPaymentMethod.fulfilled, (state, action) => {
        const message = action?.payload?.msg;
        const newDefaultKey = message.substr(0, message.indexOf(' '));
        for (const key in state.paymentMethods) {
          if (key === newDefaultKey) {
            state.paymentMethods[key] = {
              ...state.paymentMethods[key],
              default: true,
            };
          } else {
            state.paymentMethods[key] = {
              ...state.paymentMethods[key],
              default: false,
            };
          }
        }
        state.status = 'idle';
        toast.success('Default payment method set successfully');
      })
      .addCase(setDefaultPaymentMethod.rejected, (state) => {
        state.status = 'failed';
        toast.error('Failed to set default payment method');
      })
      .addCase(processPaymentWithExistingPaymentMethod.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(processPaymentWithExistingPaymentMethod.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(processPaymentWithExistingPaymentMethod.rejected, (state) => {
        state.paymentStatus = 'payment-failed-final';
      })
      .addCase(pollPayment.pending, (state) => {
        state.paymentStatus = 'polling-needed';
        state.paymentData = null;
      })
      .addCase(pollPayment.fulfilled, (state, action) => {
        state.paymentStatus = action?.payload?.status;
        state.paymentData = action?.payload?.data;
      })
      .addCase(pollPayment.rejected, (state, action) => {
        state.paymentData = action?.payload;
        state.paymentStatus = 'payment-failed-final';
      })
      .addCase(createSpreedlyCreditCard.rejected, (state, _action) => {
        state.paymentStatus = 'payment-failed-final';
      });
  },
});
export const { clearPayment } = paymentsSlice.actions;
export const paymentsReducer = paymentsSlice.reducer;
