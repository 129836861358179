import React, { FC, ReactNode } from 'react';

interface FlexBoxProps {
  className?: string;
  children?: ReactNode;
}

const FlexBox: FC<FlexBoxProps> = ({ className, children }) => (
  <div className={`w-full md:flex justify-between rounded-lg ${className}`}>{children}</div>
);

export default FlexBox;
