import React, { FC, ReactNode } from 'react';

type LabelProps = {
  children?: ReactNode;
  htmlFor?: string;
  className?: string;
};

const Label: FC<LabelProps> = ({ htmlFor, className, children }) => (
  <label htmlFor={htmlFor} className={className}>
    {children}
  </label>
);

export default Label;
