import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Loader, Button } from 'components';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { selectOrdersUserTicketsArr } from 'store';
import { TicketType, useLedger, useOrders } from 'data';

type ModalProps = {
  isWristbandFrozen: boolean;
  setOpenFreezeWristbandModal: (isOpen: boolean) => void;
};

const HeaderCell = ({ children }: any) => (
  <div className={'flex-1 text-center font-light text-sm text-[#6F7FAF] py-3'}>{children}</div>
);

const Cell = ({ className, children }: any) => <div className={className}>{children}</div>;

const ClaimedWristband: FC<ModalProps> = ({ isWristbandFrozen, setOpenFreezeWristbandModal }) => {
  const tickets = useSelector(selectOrdersUserTicketsArr);
  const { isOrdersLoading } = useOrders();
  const { currentWallet, isLoading: walletIsLoading } = useLedger();
  const isMobile = useMediaQuery();
  const [ticket, setTicket] = useState<undefined | TicketType>(undefined);

  const isLoading = !tickets || !tickets.length || isOrdersLoading || walletIsLoading;

  // Use ticket that is tied to the current wallet
  useEffect(() => {
    if (tickets && currentWallet && tickets.length > 0) {
      const ticketFromWallet = currentWallet.ticket;
      const ticket = tickets.find((ticket) => ticket.uuid === ticketFromWallet);

      setTicket(ticket);
    }
  }, [currentWallet, tickets]);

  return (
    <div className='inline-block w-full mt-[16px]'>
      {isLoading ? (
        <Loader className='relative' />
      ) : (
        <div className='rounded-lg border shadow-2xl w-full'>
          {!isMobile ? (
            <div className='flex bg-slate-100 border-b justify-between items-center py-2 px-6'>
              <HeaderCell>WRISTBAND/CARD UID</HeaderCell>
              <HeaderCell>SECURITY ID</HeaderCell>
            </div>
          ) : null}
          {ticket && (
            <div
              className={`${
                isMobile ? 'block border-b-4' : 'flex border-b'
              } justify-between items-center py-2 px-6`}
            >
              {isMobile ? (
                <Cell className='flex-1 Font-light text-sm text-[#6F7FAF] pt-3'>
                  WRISTBAND/CARD UID
                </Cell>
              ) : null}
              <Cell
                className={`${
                  isMobile ? 'pb-2 pt-1' : 'flex-1 text-center py-3 text-[#6F7FAF]'
                } font-light`}
              >
                {ticket.rfidUid || 'Unissued'}
              </Cell>
              {isMobile ? (
                <Cell className='flex-1 Font-light text-sm text-[#6F7FAF] pt-3'>SECURITY ID</Cell>
              ) : null}
              <Cell
                className={`${
                  isMobile ? 'pb-7 pt-1' : 'flex-1 text-center py-3 text-[#6F7FAF]'
                } font-light`}
              >
                {ticket.securityCode}
              </Cell>
              <Cell>
                {isMobile && (
                  <Button
                    onClick={() => setOpenFreezeWristbandModal(true)}
                    isLoading={walletIsLoading}
                    disabled={walletIsLoading}
                    className='mt-2 btn btn-primary btn-sm w-full md:w-auto my-3 py-7 text-lg'
                  >
                    {isWristbandFrozen ? 'Unfreeze Wristband' : 'Freeze Wristband'}
                  </Button>
                )}
              </Cell>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClaimedWristband;
