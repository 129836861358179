import { createSelector } from '@reduxjs/toolkit';

import { formatCurrency } from '../../helpers/currency';

import { formatDateToMed } from 'helpers';
import { RootState } from 'store';
import type { EventDetails, RefundFormConfiguration } from 'data';
import { BalanceTransaction } from 'pages/EventBalance/types';

export const selectEventsData = (state: RootState) => state.eventsData;

export const selectEventTopUpFolioId = (state: RootState) =>
  state.eventsData.selectedEvent?.eventDetails?.tokensFolioId;

export const selectEventsStatus = createSelector(
  [selectEventsData],
  (eventsData) => eventsData.status,
);

export const selectEventsIsLoading = createSelector(
  [selectEventsStatus],
  (eventsStatus) => eventsStatus === 'loading',
);

export const selectEventsUpcomingEvents = createSelector(
  [selectEventsData],
  (eventsData) => eventsData.upcomingEvents,
);

export const selectEventsUpcomingEventsArray = createSelector(
  [selectEventsUpcomingEvents],
  (upcomingEvents) => upcomingEvents && Object.values(upcomingEvents),
);

export const selectEventsEventSelectorById = createSelector(
  [selectEventsUpcomingEvents],
  (upcomingEvents) => (id: string) => (upcomingEvents ? upcomingEvents[id] : {}),
);

export const selectEventSelected = createSelector([selectEventsData], (events) => {
  try {
    return events.selectedEvent;
  } catch (err) {
    return {};
  }
});

export const selectEventSelectedSummary = createSelector([selectEventSelected], (event) => {
  try {
    return event.eventSummary;
  } catch (err) {
    return {};
  }
});

export const selectEventSelectedName = createSelector(
  [selectEventSelectedSummary],
  (eventSummary) => {
    try {
      // @ts-ignore
      return eventSummary.name;
    } catch (err) {
      return {};
    }
  },
);

export const selectEventSelectedNameEn = createSelector([selectEventSelectedName], (eventName) => {
  try {
    return eventName.en;
  } catch (err) {
    return '';
  }
});

export const selectEventCurrency = createSelector([selectEventsData], (events) => {
  try {
    return events.selectedEvent.currencyCode;
  } catch (err) {
    ('');
  }
});

export const selectEventSelectedStartDate = createSelector(
  [selectEventSelectedSummary],
  (eventSummary) => {
    try {
      // @ts-ignore
      return eventSummary.startDate;
    } catch (err) {
      return '';
    }
  },
);

export const selectEventSelectedDetails = createSelector(
  [selectEventSelected],
  (events): EventDetails => {
    try {
      return events.eventDetails;
    } catch (err) {
      return {} as EventDetails;
    }
  },
);

export const selectEventRefundConfiguration = createSelector(
  [selectEventSelected],
  (events): RefundFormConfiguration | undefined => {
    try {
      return events.refundFormConfiguration;
    } catch (err) {
      return {} as RefundFormConfiguration;
    }
  },
);

export const selectEventSelectedLedgerId = createSelector(
  [selectEventSelectedDetails],
  (eventDetails) => {
    try {
      // @ts-ignore
      return eventDetails.distributedLedgerId;
    } catch (err) {
      return '';
    }
  },
);

export const selectEventsTransactionsHistory = createSelector(
  [selectEventsData],
  (eventsData) => eventsData.transactionHistory || {},
);

export const selectEventsTransactionsHistoryArr = createSelector(
  [selectEventsTransactionsHistory],
  (transactionHistory) =>
    Object.values(transactionHistory)
      /**
       * Remove zero amount price and declined transactions
       */
      .filter(({ products }) => products[0]?.items?.length),
);

export const selectTransactionsHistorySortedByLatest = createSelector(
  [selectEventsTransactionsHistoryArr],
  (transactionsHistory) =>
    transactionsHistory.sort(
      (transactionA, transactionB) =>
        new Date(transactionB.products[0].items[0].createdOn).getTime() -
        new Date(transactionA.products[0].items[0].createdOn).getTime(),
    ),
);

export const selectMappedTransactionsHistory = createSelector(
  [selectTransactionsHistorySortedByLatest, selectEventCurrency],
  (transactionsHistory, currency): BalanceTransaction[] =>
    transactionsHistory.map(({ id, products, subTotalPrice, tip = 0 }) => {
      const isDebit = products[0].productCategory !== 'topup';
      const totalPrice = subTotalPrice + tip;

      return {
        id: id,
        vendor: 'Intellitix',
        date: formatDateToMed(products[0].items[0].createdOn),
        createdOn: products[0].items[0].createdOn,
        transaction: isDebit ? 'Purchase' : 'Top-Up',
        amount: `${isDebit ? '-' : '+'} ${formatCurrency(totalPrice, currency)}`,
        productKind: products[0]?.productKind,
        isDebit,
      };
    }),
);
