import { useCallback } from 'react';

import { useData } from 'data';
import {
  fetchEventTransactionHistoryAction,
  fetchSelectedEventByIdAction,
  useAppDispatch,
  AppDispatch,
} from 'store';

export const useEventsApi = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const { securedApi } = useData();

  const fetchSelectedEventById = useCallback(
    async (id: string) => {
      await dispatch(fetchSelectedEventByIdAction({ securedApi, id }));
    },
    [securedApi, dispatch],
  );

  const fetchEventTransactionHistory = useCallback(
    async (eventId: string) => {
      await dispatch(fetchEventTransactionHistoryAction({ securedApi, eventId }));
    },
    [securedApi, dispatch],
  );

  return {
    fetchEventTransactionHistory,
    fetchSelectedEventById,
  };
};
