import React, { FC, useCallback, useMemo, useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link, NavLink } from 'react-router-dom';

import WalletId from './WalletId';

import { Heading, ImageSrcSet, Menu } from 'components';
import { useMenuLinks } from 'hooks';
import { useAuthValue } from 'hooks/Firebase';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { Navigation } from 'navigation';

const SideBar: FC = () => {
  const [isNavVisible, setIsNavVisible] = useState<boolean>(false);
  const isMobile = useMediaQuery();
  const { currentUser } = useAuthValue();
  const links = useMenuLinks();

  const logoHref = useMemo(
    () => (!currentUser?.emailVerified ? Navigation.LOGIN : Navigation.EVENTS),
    [currentUser?.emailVerified],
  );

  const closeNav = useCallback(() => {
    setTimeout(() => {
      document.body.classList.remove('scroll-lock');
      setIsNavVisible(false);
    }, 1);
  }, [setIsNavVisible]);

  const onMenuClick = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.body.classList.toggle('scroll-lock');
    setIsNavVisible((isVisible) => !isVisible);
  }, [setIsNavVisible]);

  return (
    <>
      {isMobile ? (
        <nav className='flex'>
          <div className='w-full md:hidden bg-[#00303B] z-10'>
            <div className='flex justify-between items-center'>
              <a href={logoHref}>
                <div className='px-4'>
                  <img
                    className={`bg-none bg-none w-[24px] h-[24px]`}
                    src={`/logo192.png`}
                    alt='logo'
                  />
                </div>
              </a>
              <WalletId />
              <button
                className='btn text-white px-4'
                onClick={isNavVisible ? closeNav : onMenuClick}
                title='menu'
              >
                {isNavVisible ? <FiX className='text-2xl' /> : <FiMenu className='text-2xl' />}
              </button>
            </div>

            {isNavVisible && (
              <>
                <div
                  className='fixed top-14 right-0 bottom-0 left-0 bg-black opacity-50 mt-[4px]'
                  onClick={closeNav}
                ></div>
                <div className='fixed top-14 right-0 bottom-0 bg-white w-4/5 max-w-[80%] mt-[4px] shadow-lg'>
                  <Heading
                    headingLevel='p'
                    className='flex px-3 mt-[4px] h-[62px] items-center text-xl font-normal text-base'
                  >
                    Welcome {currentUser?.displayName} 👋
                  </Heading>
                  <ul>
                    {links.map(({ name, to, icon, onClick, isDisabled }) => (
                      <>
                        {!isDisabled ? (
                          <li key={name} className='p-3 hover:bg-gray-200 border-t border-gray-100'>
                            <NavLink
                              to={to}
                              onClick={() => {
                                if (onClick) onClick();
                                closeNav();
                              }}
                              end
                              className='flex items-center'
                            >
                              <div className='h-[20px] w-[20px] mr-4'>{icon}</div>
                              <span>{name}</span>
                            </NavLink>
                          </li>
                        ) : null}
                      </>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        </nav>
      ) : (
        <div className='fixed top-0 left-0 h-full md:w-[226px] bg-[#00303B]'>
          <div className='pt-[1.5rem] w-[80%] flex justify-center items-center'>
            <Link to={logoHref}>
              <ImageSrcSet
                alt='logo'
                srcSet='/logo-all/cb-logo/Logo'
                className='bg-none h-[32px]'
              />
            </Link>
          </div>
          <Menu links={links} />
          <div className='pl-4 fixed bottom-2'>
            <WalletId />
          </div>
        </div>
      )}
    </>
  );
};

export default SideBar;
