import classNames from 'classnames';
import React, { FC } from 'react';
import DatePicker from 'react-datepicker';

import { FieldWrapper, FieldWrapperProps } from '../FieldWrapper';
import 'react-datepicker/dist/react-datepicker.css';
import './datePickerStyles.css';

type DatePickerProps = FieldWrapperProps & {
  onChange?: any;
  onFocus?: () => void;
  onBlur?: () => void;
  name?: string;
  value?: any;
  placeholder?: string;
  type?: string | undefined;
  isDisabled?: boolean;
};

const DatePickerCustom: FC<DatePickerProps> = ({
  onChange,
  onFocus,
  onBlur,
  name,
  value,
  placeholder,
  isDisabled,
  ...fieldWrapperProps
}) => {
  return (
    <FieldWrapper isDisabled={isDisabled} {...fieldWrapperProps}>
      <DatePicker
        name={name}
        value={isDisabled && !value ? 'Unissued' : value}
        selected={isDisabled && !value ? 'Unissued' : value}
        onChange={(date) => onChange && onChange(date)}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={isDisabled}
        placeholderText={placeholder}
        dateFormat='yyyy-MM-dd'
        wrapperClassName='w-full'
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={100}
        maxDate={new Date()}
        className={classNames(
          'w-full focus:outline-none bg-inherit',
          isDisabled && !value && 'text-[#C4CBDD]',
        )}
      />
    </FieldWrapper>
  );
};

export default DatePickerCustom;
