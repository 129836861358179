import { Config } from '../Config';

import { ClientConfig, SecuredApi } from './Api';

import { paths } from 'data/constants';

interface MiddlewareApi {
  fetchEventTickets: (eventId: string) => Promise<any>;
  fetchEventTransactionHistory: (eventId: string) => Promise<any>;
  fetchTicketsByAccountId: (accountId: string) => Promise<any>;
}

export class MiddlewareApiImpl extends SecuredApi implements MiddlewareApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return {
      baseURL: Config.MIDDLEWARE,
      timeout: 15000,
    };
  };

  fetchEventTickets = async (eventId: string): Promise<any> => {
    const response = await this.client.get(paths.fetchEventTickets(eventId));
    return response.data;
  };

  fetchEventTransactionHistory = async (eventId: string): Promise<any> => {
    const response = await this.client.get(paths.fetchEventTransactionHistory(eventId));
    return response.data;
  };

  fetchTicketsByAccountId = async (accountId: string): Promise<any> => {
    const response = await this.client.get(paths.getValidTicketByAccountId(accountId));
    return response.data;
  };
}

export default MiddlewareApi;
