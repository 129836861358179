import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import RefundFormContainer from './RefundFormContainer';
import useRefundContext from './RefundContext';

import { ControlledSelectField, ControlledTextField, Label } from 'components';
import { useData } from 'data';
import { AccountTypeOptions, manualRefundRequestBody } from 'data/api/RefundApi';

const refundRequestSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  addressLine1: yup.string().required('Address line 1 is required'),
  addressLine2: yup.string(),
  financialInstitutionNumber: yup
    .string()
    .required('Financial Institution Number is required')
    .matches(/^\d+$/, 'Numbers only')
    .max(3, 'The Financial Institution Number should be 3 digits only')
    .min(3, 'The Financial Institution Number should be 3 digits only'),
  branchNumber: yup
    .string()
    .required('Branch Number is required')
    .matches(/^\d+$/, 'Numbers only')
    .max(5, 'The branch number should be 5 digits only')
    .min(5, 'The branch number should be 5 digits only'),
  accountNumber: yup
    .string()
    .required('Account Number is required')
    .matches(/^\d+$/, 'Numbers only')
    .max(15, 'The Account number should be 5 to 15 digits only')
    .min(5, 'The Account number should be 5 to 15 digits only'),
  accountType: yup.string().required('Account Type is required'),
});

const resolver = {
  resolver: yupResolver(refundRequestSchema),
  defaultValues: {
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    financialInstitutionNumber: '',
    branchNumber: '',
    accountNumber: '',
    accountType: '',
  },
};

const RefundCanada = () => {
  const { securedApi } = useData();
  const { control, reset, handleSubmit } = useForm({ ...resolver, mode: 'all' });
  const { eventId } = useParams();

  const {
    openModal,
    setRefundSubmitted,
    setRefundRequestFailed,
    setIsLoading,
    setIsRefundFormValid: setIsRefundFormValid,
    resetFormState,
  } = useRefundContext();

  const onSubmit = async (value: typeof control._formValues) => {
    setIsLoading(true);

    const body: manualRefundRequestBody = {
      firstName: value.firstName,
      lastName: value.lastName,
      addressLine1: value.addressLine1,
      addressLine2: value.addressLine2,
      financialInstitutionNumber: String(value.financialInstitutionNumber),
      branchNumber: String(value.branchNumber),
      accountNumber: String(value.accountNumber),
      accountType: String(value.accountType),
      eventId: eventId ? eventId : '',
      refundType: 'Canada',
    };

    const response = await securedApi?.RefundApi.manualRefundRequest(body);
    setRefundSubmitted(true);
    if (response?.status !== 201) {
      setRefundRequestFailed(true);
    }
    setIsLoading(false);
    setRefundSubmitted(true);
    reset();
    resetFormState();
  };

  const validationCheck = async () => {
    const result = await refundRequestSchema.isValid(control._formValues);
    if (result) {
      setIsRefundFormValid(true);
      openModal();
    } else {
      setIsRefundFormValid(false);
      handleSubmit(onSubmit as any)();
    }
  };

  const CanadaForm = () => {
    return (
      <div className='lg:col-span-3 m-3'>
        <Label className='text-gray-500 mb-3'>Contact Information</Label>
        <div className='pb-3'>
          <div className='flex flex-col md:flex-row sm:gap-3 md:gap-5'>
            <ControlledTextField control={control} fieldName='firstName' label='First Name*' />
            <ControlledTextField control={control} fieldName='lastName' label='Last Name*' />
          </div>
        </div>
        <div className='pb-3'>
          <div className='flex flex-col md:flex-row sm:gap-3 md:gap-5'>
            <ControlledTextField
              control={control}
              fieldName='addressLine1'
              label='Address line 1*'
              type='string'
            />
            <ControlledTextField
              control={control}
              fieldName='addressLine2'
              label='Address line 2'
              maxLength={11}
            />
          </div>
        </div>
        <Label className='text-gray-500 mb-3 mt-3'>Bank Details</Label>
        <div className='pb-3'>
          <div className='flex flex-col md:flex-row gap-5'>
            <ControlledTextField
              control={control}
              fieldName='financialInstitutionNumber'
              label='Financial Institution Number*'
              maxLength={3}
            />
          </div>
        </div>
        <div className='pb-3'>
          <div className='flex flex-col md:flex-row gap-5'>
            <ControlledTextField
              control={control}
              fieldName='branchNumber'
              label='Branch Number*'
              maxLength={5}
            />
          </div>
        </div>
        <div className='pb-3'>
          <div className='flex flex-col md:flex-row sm:gap-3 md:gap-5'>
            <ControlledTextField
              control={control}
              fieldName='accountNumber'
              label='Account Number*'
              maxLength={15}
            />
            <ControlledSelectField
              defaultValue=''
              control={control}
              fieldName='accountType'
              label='Account Type*'
              options={AccountTypeOptions}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <RefundFormContainer
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        validationCheck={validationCheck}
      >
        <CanadaForm />
      </RefundFormContainer>
    </>
  );
};

export default RefundCanada;
