import React, { FC } from 'react';

import { Heading, FlexBox } from 'components';

type EmptyTextProps = {
  title?: string;
  description?: string;
};

const EmptyText: FC<EmptyTextProps> = ({ title, description }) => (
  <FlexBox className='!justify-center m-auto h-60 items-center align-items-center flex-col'>
    {title && (
      <Heading className='text-center text-custom-primary text-[21px] w-full' headingLevel='h4'>
        {title}
      </Heading>
    )}
    {description && (
      <Heading className='text-center text-[#6F7FAF]  text-[18px] w-full' headingLevel='h4'>
        {description}
      </Heading>
    )}
  </FlexBox>
);

export default EmptyText;
