import { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { useData } from './ContentProvider';
import useLedger from './useLedger';
import usePayments from './usePayments';
import { useUser } from './useUser';

import {
  selectUserPrimaryEmail,
  selectUserFirstName,
  selectUserLastName,
  selectEventSelected,
  selectEventTopUpFolioId,
  fetchUpcomingEventsAction,
  fetchEventTicketsAction,
  transferTicketAction,
  fetchProductsAction,
  fetchTicketsByAccountIdAction,
  sendEmailReceiptAction,
  createTopUpOrder,
  useAppDispatch,
  useAppSelector,
  AppDispatch,
  selectOrdersStatus,
} from 'store';
import { setWristbandFrozenStatus } from 'store/orders/ordersReducer';

export const useOrders = () => {
  const { fetchUserData } = useUser();
  const isOrdersLoading = useAppSelector(selectOrdersStatus) === 'loading';
  const userFirstName = useAppSelector(selectUserFirstName);
  const userLastName = useAppSelector(selectUserLastName);
  const userPrimaryEmail = useAppSelector(selectUserPrimaryEmail);
  const selectedEventId = useSelector(selectEventSelected, shallowEqual);
  const selectedEventTopUpFolioId = useSelector(selectEventTopUpFolioId, shallowEqual);
  const { currenWalletId, currentWalletRFID } = useLedger();
  const dispatch: AppDispatch = useAppDispatch();
  const { securedApi } = useData();
  const {
    processWithSavedPaymentMethod,
    pollPaymentStatus,
    processWithNewPaymentMethod,
    tokenizeCreditCard,
  } = usePayments();

  const transferTicket = useCallback(
    async (id: string, body: any) => {
      await dispatch(transferTicketAction({ securedApi, id, body }));
    },
    [securedApi, dispatch],
  );

  const fetchUpcomingEvents = useCallback(async () => {
    await dispatch(fetchUpcomingEventsAction({ securedApi }));
  }, [securedApi, dispatch]);

  const fetchEventTickets = useCallback(
    async (eventId: string) => {
      await dispatch(fetchEventTicketsAction({ securedApi, eventId }));
    },
    [securedApi, dispatch],
  );

  const setWristbandFrozenStatusWithBoolean = useCallback(
    async (ticketId: string, isFrozen: boolean) => {
      const eventId = selectedEventId.uuid;
      await dispatch(setWristbandFrozenStatus({ securedApi, eventId, ticketId, isFrozen }));
    },
    [securedApi, dispatch, selectedEventId],
  );

  const fetchTicketsByAccountId = useCallback(
    async (accountId: string) => {
      await dispatch(fetchTicketsByAccountIdAction({ securedApi, accountId }));
    },
    [securedApi, dispatch],
  );

  const sendEmailReceipt = useCallback(
    async (orderId: string) => {
      await dispatch(sendEmailReceiptAction({ securedApi, orderId }));
    },
    [securedApi, dispatch],
  );

  const fetchTopUpTokenForFolio = useCallback(async () => {
    const topUpFolioId = selectedEventTopUpFolioId;
    const response = await dispatch(fetchProductsAction({ securedApi, topUpFolioId }));

    //Dirty hack to get the topUpToken
    const tokens = response.payload.entries.filter((entry: any) => {
      if (entry?.productSummary?.kind === 'token') {
        return entry;
      }
    });
    if (!tokens.length) {
      throw new Error('No Top-Up token found');
    }
    return tokens[0].uuid;
  }, [securedApi, dispatch, selectedEventTopUpFolioId]);

  const createTopUpOrderAndPayWithExistingCreditCard = async (
    paymentMethodId: string,
    amount: number,
  ) => {
    if (!userFirstName || !userLastName || !userPrimaryEmail) {
      // Ensure user data is available..
      await fetchUserData();
    }
    const topUpToken = await fetchTopUpTokenForFolio();
    const result = dispatch(
      createTopUpOrder({
        selectedEventId,
        securedApi,
        userFirstName,
        userLastName,
        userPrimaryEmail,
        currenWalletId,
        selectedEventTopUpFolioId,
        topUpToken,
        amount,
        rfidUid: currentWalletRFID,
      }),
    )
      .then((res) => {
        const paymentLink = res?.payload?.paymentInfo?.paymentLink;
        if (!paymentLink) {
          throw new Error('No payment link found');
        }
        const paymentId = paymentLink.split('paymentId=')[1];
        return Promise.all([
          processWithSavedPaymentMethod(paymentId, paymentMethodId),
          pollPaymentStatus(paymentId),
        ]);
      })
      .catch((error) => {
        // TODO: handle error should be handled by reducer and UI
        throw new Error(error);
      });
    return result;
  };

  const createTopUpOrderAndPayWithNewCreditCard = async (cardInfo: any, amount: number) => {
    if (!userFirstName || !userLastName || !userPrimaryEmail) {
      await fetchUserData();
    }
    const topUpToken = await fetchTopUpTokenForFolio();
    let paymentId = '';
    const result = dispatch(
      createTopUpOrder({
        selectedEventId,
        securedApi,
        userFirstName,
        userLastName,
        userPrimaryEmail,
        currenWalletId,
        selectedEventTopUpFolioId,
        topUpToken,
        amount,
        rfidUid: currentWalletRFID,
      }),
    )
      .then((res) => {
        const paymentLink = res?.payload?.paymentInfo?.paymentLink;
        if (!paymentLink) {
          throw new Error('No payment link found');
        }
        paymentId = paymentLink.split('paymentId=')[1];
        return paymentId;
      })
      .then(() => {
        const result = tokenizeCreditCard(cardInfo);
        return result;
      })
      .then((spreedlyResult: any) => {
        const spreedlyToken = spreedlyResult?.payload?.transaction?.payment_method?.token;
        if (!spreedlyToken) {
          throw new Error('No spreedly token found');
        }
        return Promise.all([
          processWithNewPaymentMethod(paymentId, spreedlyToken),
          pollPaymentStatus(paymentId),
        ]);
      })
      .catch((error) => {
        console.log('error', error);
      });
    return result;
  };

  return {
    isOrdersLoading,
    transferTicket,
    fetchEventTickets,
    fetchUpcomingEvents,
    createTopUpOrderAndPayWithExistingCreditCard,
    createTopUpOrderAndPayWithNewCreditCard,
    setWristbandFrozenStatusWithBoolean,
    fetchTicketsByAccountId,
    sendEmailReceipt,
  };
};
