import React, { FC } from 'react';

type ImageSrcProps = {
  className?: string;
  srcSet?: string;
  alt?: string;
};

const ImageSrcSet: FC<ImageSrcProps> = ({ srcSet, className, alt }) => (
  <img
    className={`bg-none  ${className}`}
    srcSet={`${srcSet}@3x.png, ${srcSet}@2x.png ${srcSet}.png`}
    src={`${srcSet}.png`}
    sizes='(max-width:1024px) 1024px,(max-width:768px) 768px,(max-width:640px) 640px'
    alt={alt}
  />
);

export default ImageSrcSet;
