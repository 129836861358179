import { createSlice } from '@reduxjs/toolkit';

import { fetchAccessTicketsAction } from './accessControlActions';

import { AccessTicket } from 'data';
import { resetAll, setPendingState, setRejectedState } from 'store/common';

export type AccessControlState = {
  accessTickets: AccessTicket[] | null;
  eventUriPrefix: string;
  status: 'idle' | 'loading' | 'failed';
};

const initialState = {
  accessTickets: null,
  eventUriPrefix: '',
  status: 'idle',
} as AccessControlState;

export const accessControlSlice = createSlice({
  name: 'accesscontrol',
  initialState,
  reducers: {
    setEventUriPrefix: (state, action) => {
      state.eventUriPrefix = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetAll, () => initialState)
      // fetchAccessTickets
      .addCase(fetchAccessTicketsAction.pending, setPendingState)
      .addCase(fetchAccessTicketsAction.rejected, setRejectedState)
      .addCase(fetchAccessTicketsAction.fulfilled, (state, action) => {
        state.accessTickets = action.payload;
        state.status = 'idle';
      });
  },
});

export const { setEventUriPrefix } = accessControlSlice.actions;
export default accessControlSlice.reducer;
