export function getCreditCardIconByType(type: string): any {
  switch (type) {
    case 'visa':
      return 'fab fa-cc-visa';
    case 'master' || 'mastercard':
      return 'fab fa-cc-mastercard';
    case 'amex':
      return 'fab fa-cc-amex';
    case 'discover':
      return 'fab fa-cc-discover';
    default:
      return 'solid fa-credit-card';
  }
}

export function replaceSpreedlyHideString(value: string): string {
  return value.replace('XXXX-XXXX-XXXX-', '•••• •••• •••• ');
}
