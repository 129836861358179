import React from 'react';

const WalletIcon = () => (
  <svg
    style={{ width: 'inherit', height: 'inherit' }}
    viewBox='0 0 20 16'
    stroke='currentColor'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1 6H19M5 11H6M10 11H11M4 15H16C17.6569 15 19 13.6569 19 12V4C19 2.34315 17.6569 1 16 1H4C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15Z'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default WalletIcon;
