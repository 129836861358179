export const paths = {
  getEvent: (eventId: string): string => `public/events/${eventId}`,
  getAccessTickets: (eventUri: string): string => `/events/${eventUri}/accessTickets`,
  getAllOrders: (nextPageToken?: string) =>
    `/orders/all${nextPageToken ? `?nextPageToken=${nextPageToken}` : ''}`,
  fetchUpcomingEvents: () => `public/events?includePastEvents=false&pageSize=100`,
  fetchOrderById: (id: string) => `/orders/all/${id}`,
  sendEmailReceipt: (orderId: string) => `/orders/${orderId}/sendOrderReceipt`,
  getProducts: (topUpFolioUuid: string) => `/folios/${topUpFolioUuid}/products`,
  createOrder: (topUpFolioUuid: string) => `/folios/${topUpFolioUuid}/orders`,
  transferTicket: (folioId: string) => `/folios/${folioId}/itemTransfer`,
  fetchEventTransactionHistory: (eventId: string) => `/cb-ticketing/my-transactions/${eventId}`,
  fetchEventTickets: (eventId: string) => `/cb-ticketing/my-tickets/${eventId}`,
  setIsFrozen: (eventId: string, ticketId: string) => `/events/${eventId}/tickets/${ticketId}`,
  account: () => `/account`,
  getUserDetailedInfo: (email: string) => `/service/accountForEmail?email=${email}`,
  claimWristband: () => '/wristband/claim',
  checkIfWristbandExists: (rfidUid: string, securityCode: string) =>
    `/wristband/checkExists?rfidUid=${rfidUid}&securityCode=${securityCode}`,
  getServiceIdentity: () => '/meta/pub/idt',
  getValidTicketByAccountId: (accountId: string) => `/cb-ticketing/getValidTickets/${accountId}`,
  manualRefundRequest: () => `/user/refunds/data`,
  refundHistory: (accountId: string, eventId: string) =>
    `/orders/${accountId}/events/${eventId}/refundOrders`,
  getCurrency: (folioId: string) => `/folios/${folioId}/salesSummary/currencyCode`,
  getVendorByFolioId: (orderId: string) => `/folios/${orderId}/vendorInfo`,
  getTopupTypes: (folioId: string, topupTypeId: string) =>
    `folios/${folioId}/topupTypes/${topupTypeId} `,
  getOrdersByRfid: (rfidUid: string) => `/orders/${rfidUid}/patronWebOrderList`,
};
