import { Config } from '../Config';
import { CBIdentity } from '../model/Identity';

import { ClientConfig, SecuredApi } from './Api';

import { paths } from 'data/constants';

export interface UserApi {
  getCertifiedAccount: () => Promise<CBIdentity>;
  updateUser: (body: any) => Promise<any>;
  getUserDetailedInfo: (email: string) => Promise<any>;
}

export class _UserApiImpl extends SecuredApi implements UserApi {
  authtokenTTL = 180;
  rfidSearch = '';
  clientConfig = (): ClientConfig => {
    return { baseURL: Config.API_URL, timeout: 60000 };
  };

  getCertifiedAccount = async (): Promise<CBIdentity> => {
    const response = await this.client.post(paths.account());
    return response.data as CBIdentity;
  };

  getUserDetailedInfo = async (): Promise<CBIdentity> => {
    const response = await this.client.get(paths.account());
    return response.data as CBIdentity;
  };

  updateUser = async (body: any): Promise<any> => {
    const response = await this.client.post(paths.account(), body);
    return response.data;
  };
}
