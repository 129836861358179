import React from 'react';

const MyTransactionsIcon = () => {
  return (
    <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 5L17 5M17 5L13 1M17 5L13 9M13 15L0.999999 15M0.999999 15L5 19M0.999999 15L5 11'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default MyTransactionsIcon;
