import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import ClaimedWristband from './components/ClaimedWristband';
import FreezeWristband from './components/FreezeWristband';
import PrimaryPaymentMethod from './components/PrimaryPaymentMethod';

import { Button, SectionTitle } from 'components';
import TopUp from 'components/TopUp/TopUp';
import {
  selectEventSelectedDetails,
  selectEventSelectedLedgerId,
  selectUiIsEventLayoutLoading,
  useAppSelector,
} from 'store';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import { useLedger } from 'data';

const EventBalance: FC = () => {
  const isLayoutLoading = useSelector(selectUiIsEventLayoutLoading);
  const eventDetails = useSelector(selectEventSelectedDetails);
  const {
    isWristbandFrozen,
    isUpdatingWallet: walletIsLoading,
    unfreezeWalletAction,
    freezeWalletAction,
  } = useLedger();
  const ledgerId = useAppSelector(selectEventSelectedLedgerId);
  const [openFreezeWristbandModal, setOpenFreezeWristbandModal] = useState(false);
  const isMobile = useMediaQuery();

  const onFreezeClick = async () => {
    if (isWristbandFrozen) {
      await unfreezeWalletAction(ledgerId!);
    } else {
      await freezeWalletAction(ledgerId!);
    }

    setOpenFreezeWristbandModal(false);
  };

  if (isLayoutLoading) return <div></div>;

  return (
    <>
      <div className={isMobile ? 'hidden' : 'pb-10'}>
        <SectionTitle title='Event Balance' />
      </div>
      <div className='pb-10'>
        <div className='flex items-center justify-between'>
          <SectionTitle subtitle='Added Wristband/Card' />
          {!isMobile && (
            <Button
              isLoading={walletIsLoading}
              disabled={walletIsLoading}
              onClick={() => setOpenFreezeWristbandModal(true)}
              className='mt-2 btn btn-primary btn-sm w-full md:w-auto'
            >
              {isWristbandFrozen ? 'Unfreeze Wristband' : 'Freeze Wristband'}
            </Button>
          )}
        </div>
        <FreezeWristband
          callback={onFreezeClick}
          isOpen={openFreezeWristbandModal}
          onClose={() => setOpenFreezeWristbandModal(false)}
          isLoading={walletIsLoading}
          isFrozen={isWristbandFrozen}
        />
        <ClaimedWristband
          isWristbandFrozen={isWristbandFrozen}
          setOpenFreezeWristbandModal={setOpenFreezeWristbandModal}
        />
      </div>
      {eventDetails?.onlineTopupAllowed ? (
        <div className='pb-10'>
          <SectionTitle subtitle='Add Funds' isMobile={isMobile} />
          <TopUp />
        </div>
      ) : (
        <div className='pb-10'>
          <SectionTitle subtitle='Primary Payment Method' />
          <PrimaryPaymentMethod />
        </div>
      )}
    </>
  );
};

export default EventBalance;
