import { Agent } from 'jwt-cert-sdk';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import AccessControlApi, { AccessControlApiImpl } from './api/AccessControlApi';
import AccountApi, { AccountApiImpl } from './api/AccountApi';
import DistributedLedgerApi, { DistributedLedgerApiImpl } from './api/DistributedLedgerAPI';
import EventsApi, { EventApiImpl } from './api/EventApi';
import MiddlewareApi, { MiddlewareApiImpl } from './api/MiddlewareApi';
import OrdersApi, { OrdersApiImpl } from './api/OrdersApi';
import PaymentsApi, { PaymentsApiImpl } from './api/PaymentsApi';
import RefundApi, { RefundApiImpl } from './api/RefundApi';
import { UserApi, _UserApiImpl } from './api/UserApi';
import useAppAgent from './security/useAppAgent';

import { useAuthValue } from 'hooks/Firebase';

type binderState = 'ready' | 'loading' | 'error';

//clear all data
export function clearData() {
  localStorage.clear();
  sessionStorage.clear();
}

export interface DataBinder {
  binderState: binderState;
  securedApi?: Nullable<{
    paymentsApi: PaymentsApi;
    EventsApi: EventsApi;
    AccessControlApi: AccessControlApi;
    OrdersApi: OrdersApi;
    DistributedLedgerApi: DistributedLedgerApi;
    MiddlewareApi: MiddlewareApi;
    AccountApi: AccountApi;
    RefundApi: RefundApi;
    UserApi: UserApi;
  }>;
  clearDataBinder: Nullable<() => void>;
}

const DataContext = React.createContext<DataBinder>({
  binderState: 'loading',
  clearDataBinder: () => null,
});

export interface IDataContext {
  children: React.ReactNode;
}

export function useData() {
  return useContext(DataContext);
}

export const DataProvider = ({ children }: IDataContext) => {
  const { currentUser } = useAuthValue();
  const [dataBinder, setBinder] = useState(useData());
  const { agent } = useAppAgent(currentUser);

  const clearDataBinder = useCallback(() => {
    dataBinder.binderState = 'loading';
    dataBinder.securedApi = null;
    setBinder({ binderState: 'loading', securedApi: null, clearDataBinder: () => null });
  }, [dataBinder]);

  useEffect(() => {
    if (
      currentUser?.emailVerified &&
      agent &&
      // need to make sure that the new agent is using the current users credentials before attaching it to the dataBinder, otherwise there is race condition where the old agent is used for the new login
      agent.alias === currentUser.email &&
      dataBinder?.binderState === 'loading'
    ) {
      dataBinder.securedApi = {
        paymentsApi: new PaymentsApiImpl(agent as Agent),
        EventsApi: new EventApiImpl(agent as Agent),
        AccessControlApi: new AccessControlApiImpl(agent as Agent),
        OrdersApi: new OrdersApiImpl(agent as Agent),
        DistributedLedgerApi: new DistributedLedgerApiImpl(agent as Agent),
        MiddlewareApi: new MiddlewareApiImpl(agent as Agent),
        AccountApi: new AccountApiImpl(agent as Agent),
        RefundApi: new RefundApiImpl(agent as Agent),
        UserApi: new _UserApiImpl(agent as Agent),
      };
      dataBinder.binderState = 'ready';
      setBinder({ ...dataBinder });
    }
  }, [dataBinder, agent, currentUser, currentUser?.emailVerified]);

  return (
    <DataContext.Provider value={{ ...dataBinder, clearDataBinder }}>
      {children}
    </DataContext.Provider>
  );
};
