import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import AccountDetails from './components/AccountDetails';
import AddressDetails from './components/AddressDetails';

import { useProvideAuth } from 'hooks/Firebase';
import { Navigation } from 'navigation';
// import { useManageProfileForm } from 'pages/ManageProfileInfo/hooks';
// import { selectUserData, useAppSelector } from 'store';

type MobileAccountCreationProp = {
  onSubmit: any;
  options: { label: string; value: string }[];
};

const MobileAccountCreation = ({ onSubmit, options }: MobileAccountCreationProp) => {
  const [currentPage, setCurrentPage] = useState(2);
  const [accountData, setAccountData] = useState({} as any);
  const { logOut } = useProvideAuth();
  const { t } = useTranslation();

  const handleAccountDetailsSubmit = (data: any) => {
    setAccountData(data);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = async () => {
    if (currentPage === 2) {
      logOut();
      window.location.href = Navigation.LOGIN;
    }
    setCurrentPage(currentPage - 1);
  };

  return (
    <>
      <div className='flex flex-row justify-between text-custom-secondary font-light font-LRegular text- lg py-4'>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          <FontAwesomeIcon icon={faArrowLeft} size='lg' />
        </button>
        <span>{t('AccountCreation.header')}</span>
        <span>{`${currentPage} of 3`}</span>
      </div>
      <div className='flex-1 md:p-4'>
        <form>
          <div hidden={currentPage !== 2}>
            <AccountDetails onContinue={handleAccountDetailsSubmit} options={options} />
          </div>
          <div hidden={currentPage !== 3}>
            <AddressDetails onSubmit={onSubmit} accountData={accountData} />
          </div>
        </form>
      </div>
    </>
  );
};

export default MobileAccountCreation;
