import React, { FC, ReactNode } from 'react';

type HeadingProps = {
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
  className?: string;
  children?: ReactNode;
} & React.HTMLAttributes<HTMLHeadingElement>;

const Heading: FC<HeadingProps> = ({ headingLevel, children, className }) => {
  const Heading = ({ ...props }: React.HTMLAttributes<HTMLHeadingElement>) =>
    React.createElement(headingLevel, props, children);
  return <Heading className={className}>{children}</Heading>;
};

export default Heading;
