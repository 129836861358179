import React, { FC, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

import PaymentMethodsTable from 'components/layout/PaymentMethodsTable';
import usePayments from 'data/usePayments';

import 'react-toastify/dist/ReactToastify.css';

const PaymentMethods: FC = () => {
  const { getAllPaymentMethods, paymentMethods, deletePaymentMethodByUuid, changeDefaultPayment } =
    usePayments();

  useEffect(() => {
    getAllPaymentMethods();
  }, [getAllPaymentMethods]);

  return (
    <>
      <ToastContainer />
      <PaymentMethodsTable
        paymentMethods={paymentMethods}
        changeDefaultCallback={changeDefaultPayment}
        deleteMethodCallback={deletePaymentMethodByUuid}
      />
    </>
  );
};

export default PaymentMethods;
