import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

type HeaderWrapperProps = {
  children: ReactNode;
};

const HeaderWrapper: FC<HeaderWrapperProps> = ({ children }) => {
  const isMobile = useMediaQuery();

  return (
    <div
      className={classNames(
        'flex items-center w-full',
        isMobile
          ? 'border-b border-color-intelli-primary pb-3 mb-4 min-h-[40px]'
          : 'py-3 pl-5 pr-6 min-h-[70px]',
      )}
    >
      {children}
    </div>
  );
};

export default HeaderWrapper;
