import React, { FC, ReactNode } from 'react';

type SectionProps = {
  title?: string;
  description?: string;
  description2?: string;
  asideChildren?: ReactNode;
  children?: ReactNode;
  className?: string;
  titleClassName?: string;
  childrenClassName?: string;
  isMobile?: boolean;
};

const Section: FC<SectionProps> = ({
  title,
  description,
  description2,
  asideChildren,
  className,
  titleClassName,
  children,
  childrenClassName,
  isMobile,
}) => (
  <div className='grid grid-cols-9'>
    <div className={`col-span-12 ${className}`}>
      {isMobile ? description && <p className='text-[#6F7FAF] pb-6'>{description}</p> : null}
      {title && (
        <p className={`text-[32px] text-[#232E59] font-bold pb-6 ${titleClassName}`}>{title}</p>
      )}
      {!isMobile ? description && <p className='text-[#6F7FAF] pb-6'>{description}</p> : null}
      {description2 && <p className='text-[#6F7FAF] pb-6'>{description2}</p>}
      {asideChildren}
    </div>
    <div className={`col-span-12 lg:col-start-5 ${childrenClassName}`}>{children}</div>
  </div>
);

export default Section;
