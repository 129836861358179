import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const selectAccessControlData = (state: RootState) => state.accessControlData;

export const selectAccessTickets = createSelector(
  [selectAccessControlData],
  (accessControlData) => accessControlData.accessTickets,
);

export const selectEventUriPrefix = createSelector(
  [selectAccessControlData],
  (accessControlData) => accessControlData.eventUriPrefix,
);
