import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import usePayments from 'data/usePayments';
import StarIcon from 'assets/images/star.png';
import { Spinner } from 'components';
import { getCreditCardIconByType, replaceSpreedlyHideString } from 'helpers/creditCardUtils';

const PrimaryPaymentMethod = () => {
  const { t } = useTranslation();
  const { getAllPaymentMethods, paymentMethods, isLoading } = usePayments();

  const defaultPaymentMethod = paymentMethods.find((method) => method.default);

  useEffect(() => {
    getAllPaymentMethods();
  }, [getAllPaymentMethods]);

  const noMethodsLayout = (
    <div className='flex-1 text-left  items-center flex justify-center flex-col'>
      <>
        <h3 className='text-xl font-extralight mb-2'>{t('paymentMethod.noPaymentMethod')}</h3>
        <h4 className='text-lg text-gray-500'>{`Go to “Profile ${'>'} Payment Methods” to get started.`}</h4>
      </>
    </div>
  );
  if (isLoading) {
    return (
      <div className='mt-4 flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='flex items-center justify-center w-full mt-[16px]'>
      <div className='w-full pt-2 pb-4 flex'>
        <div className='bg-[#549E98] w-[281px] rounded p-3 mr-8 hidden md:block'>
          <h4 className='text-lg flex'>
            <img className='bg-none w-[20px] h-[20px] mr-2' src={StarIcon} alt='logo' />
            Intellitix Essentials
          </h4>
          <p className='text-sm font-extralight'>{t('paymentMethod.connectPaymentMethod')}</p>
        </div>

        {!paymentMethods?.length || paymentMethods.length === 0
          ? noMethodsLayout
          : defaultPaymentMethod && (
              <div className='flex w-full flex-col'>
                <div className='flex bg-white shadow-md rounded items-center h-[74px] p-4 justify-between'>
                  <h3>{t('paymentMethod.primaryPaymentMethod')}</h3>
                  <div className='flex mr-12'>
                    <FontAwesomeIcon
                      color='#1A1F71'
                      size='xl'
                      icon={getCreditCardIconByType(
                        defaultPaymentMethod!.info.spreedlyPaymentMethod.card_type,
                      )}
                    />
                    <p className='ml-4'>
                      {replaceSpreedlyHideString(
                        defaultPaymentMethod!.info.spreedlyPaymentMethod.number,
                      )}
                    </p>
                  </div>
                </div>
                <div className='flex w-full justify-end mt-3'>
                  <p className='text-gray-500 w-[350px] text-center'>{`To update your primary payment method, go to "Profile ${'>'} Payment Methods”`}</p>
                </div>
              </div>
            )}
      </div>
    </div>
  );
};

export default PrimaryPaymentMethod;
