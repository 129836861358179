import React, { FC, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { mapAccountData, SignUpFormValues, getCountryOptions } from './helpers';
import { getProvinceOptions } from './helpers/getCountryOptions';
import { useManageProfileForm } from './hooks';

import {
  Button,
  ControlledTextField,
  ControlledSelectField,
  Section,
  Loader,
  Box,
  ControlledDatePicker,
} from 'components';
import { useUser } from 'data';
import { Navigation } from 'navigation';
import {
  selectUserData,
  selectUserHasContactInfo,
  selectUserHasGender,
  selectUserHasDateOfBirth,
  selectUiIsUpdateUserLoading,
  selectUiIsUserDataLoading,
  selectUiIsUserDetailedInfoLoading,
  useAppSelector,
} from 'store';
import { MobileAccountCreation } from 'pages/MobileAccountCreation';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Non-binary/non-conforming', value: 'non-binary/non-conforming' },
  { label: 'Prefer not to respond', value: 'prefer not to respond' },
];

const ManageProfileInfo: FC = () => {
  const { updateUser } = useUser();
  const userData = useAppSelector(selectUserData);
  const { control, handleSubmit, watch } = useManageProfileForm(userData);
  const hasContactInfo = useAppSelector(selectUserHasContactInfo);
  const hasGender = useAppSelector(selectUserHasGender);
  const hasDateOfBirth = useAppSelector(selectUserHasDateOfBirth);
  const isUpdateUserLoading = useAppSelector(selectUiIsUpdateUserLoading);
  const isUserDataLoading = useAppSelector(selectUiIsUserDataLoading);
  const isUserDetailedInfoLoading = useAppSelector(selectUiIsUserDetailedInfoLoading);
  const hasUserDetailedInfo = useMemo(() => Object.keys(userData).length, [userData]);
  const isMobile = useMediaQuery();

  const onSubmit = async (value: typeof control._formValues) => {
    const accountData = mapAccountData(value as SignUpFormValues);

    await updateUser(accountData);

    window.location.href = Navigation.EVENTS;
  };

  const selectedCountry = watch('country');

  if (hasContactInfo && hasGender && hasDateOfBirth) {
    return <Navigate to={Navigation.EVENTS} replace />;
  }

  if (isUserDataLoading || isUserDetailedInfoLoading || !hasUserDetailedInfo) {
    return <Loader />;
  }
  return isMobile ? (
    <div className='px-4'>
      <MobileAccountCreation onSubmit={onSubmit} options={GENDER_OPTIONS} />
    </div>
  ) : (
    <div className='flex-1 md:p-4'>
      <div className='flex justify-between items-center pb-3'>
        <p className='text-[21px] font-bold'>Manage Profile</p>
      </div>
      <Box className='p-12'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Section title='Account Information'>
            <div className='pb-3'>
              <div className='flex flex-col md:flex-row gap-3'>
                <ControlledDatePicker
                  control={control}
                  fieldName='dob'
                  label='Date of Birth'
                  placeholder='yyyy-mm-dd'
                />
                <ControlledSelectField
                  control={control}
                  defaultValue='male'
                  fieldName='gender'
                  label='Gender'
                  options={GENDER_OPTIONS}
                />
              </div>
            </div>
            <div className='pb-3'>
              <ControlledTextField
                control={control}
                type='tel'
                fieldName='phoneNumber'
                label='Phone Number'
              />
            </div>
          </Section>
          <div className='w-full h-[1px] bg-[#E8EAED] mt-3 mb-3' />
          <Section title='Address Information'>
            <div className='pb-3'>
              <ControlledSelectField
                control={control}
                defaultValue=''
                fieldName='country'
                label='Country'
                options={getCountryOptions()}
              />
            </div>
            <div className='pb-3'>
              <div className='flex flex-col md:flex-row gap-3'>
                <ControlledTextField
                  control={control}
                  fieldName='streetAddress'
                  label='Street Address'
                />
                <ControlledTextField control={control} fieldName='city' label='City' />
              </div>
            </div>
            <div className='pb-3'>
              <div className='flex flex-col md:flex-row gap-3'>
                {(selectedCountry === 'US' || selectedCountry === 'CA') && (
                  <ControlledSelectField
                    control={control}
                    fieldName='state'
                    label='State/Province'
                    options={getProvinceOptions(selectedCountry)}
                  />
                )}

                <ControlledTextField control={control} fieldName='zip' label='ZIP / Postal Code' />
              </div>
            </div>

            <div className='flex flex-row-reverse gap-[18px] pb-4'>
              <Button isLoading={isUpdateUserLoading} type='submit' className='btn btn-primary'>
                {hasContactInfo ? 'Update Account' : 'Create Account'}
              </Button>
            </div>
          </Section>
        </form>
      </Box>
    </div>
  );
};

export default ManageProfileInfo;
