enum CardType {
  VISA = 'visa',
  MASTER_CARD = 'master',
  AMERICAN_EXPRESS = 'american_express',
}

export const getCardType = (cardNumber: string | number): CardType => {
  const firstDigit = cardNumber.toString().slice(0, 1);

  if (firstDigit === '2' || firstDigit === '5') return CardType.MASTER_CARD;
  if (firstDigit === '3') return CardType.AMERICAN_EXPRESS;
  if (firstDigit === '4') return CardType.VISA;
  return CardType.VISA;
};
