import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation, type TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { emailRegExp } from 'validation';
import { AuthActionType } from 'hooks/Firebase';

export type ResetPasswordFormValues = {
  email: string;
  confirmEmail: string;
};

const resetPasswordSchema = (t: TFunction) =>
  yup.object().shape({
    email: yup
      .string()
      .required(t('ResetPassword.error.emailAddressRequired'))
      .matches(emailRegExp, t('ResetPassword.error.invalidEmailAddress'))
      .min(5, t('ResetPassword.error.emailMinimumLength')),
    confirmEmail: yup
      .string()
      .required(t('ResetPassword.error.emailAddressRequired'))
      .oneOf([yup.ref('email')], t('ResetPassword.error.emailAddressMatch')),
  });

export const useResetPasswordForm = () => {
  const { t } = useTranslation();

  const form = useForm({
    resolver: yupResolver(resetPasswordSchema(t)),
    defaultValues: {
      email: '',
      confirmEmail: '',
    },
  });

  const onSuccess = ({ email }: ResetPasswordFormValues) => {
    toast.success(t('ResetPassword.success', { email }) as string);
  };

  const onReject = (error: any) => {
    form.setError('confirmEmail', {
      type: 'server',
      message: t('ResetPassword.error.emailDoesNotExist'),
    });

    toast.error(error.message);
  };

  const onResend =
    (sendPasswordReset: AuthActionType<ResetPasswordFormValues>) => (email: string) => {
      sendPasswordReset({ email, confirmEmail: email }, onSuccess, onReject);
    };

  const onSubmit = (sendPasswordReset: AuthActionType<ResetPasswordFormValues>) =>
    form.handleSubmit((data) => {
      sendPasswordReset(data, onSuccess, onReject);
    });

  return { onSubmit, onResend, ...form };
};
