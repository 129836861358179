import { useState, useEffect, Dispatch, SetStateAction } from 'react';

type UseCountdownType = (initSeconds?: number) => {
  seconds: number;
  setSeconds: Dispatch<SetStateAction<number>>;
};

export const useCountdown: UseCountdownType = (initSeconds = 0) => {
  const [seconds, setSeconds] = useState<number>(initSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) setSeconds((seconds) => seconds - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  return { seconds, setSeconds };
};
