import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

import { TransactionHistory, TransactionDetails } from './components';
import { calcTotalBalance } from './helpers';

import usePayments from 'data/usePayments';

const theadDataHistory = [
  { Name: 'VENDOR', className: 'text-left w-[3%]' },
  { Name: 'TRANSACTION TYPE', className: 'text-center' },
  { Name: 'DATE', className: 'text-center' },
  { Name: 'AMOUNT', className: 'text-center' },
  { Name: '', className: 'text-center' },
];

const theadDataDetails = [
  { Name: 'TRANSACTION', className: 'text-left w-[3%]' },
  { Name: 'PRODUCT', className: 'text-center' },
  { Name: 'AMOUNT', className: 'text-center' },
];

const transactionDetails = {
  eventName: 'CrowdBlink',
  product: 'Event Currency',
  amount: '20.00',
  orderId: '01234567890123456',
  cardNumber: '.... .... ....   1234',
  cardType: 'Visa',
  type: 'Top-Up',
  date: '12/08/22',
  taxes: 0,
  total: '20.00',
  vendor: 'CrowdBlink',
};

const transactionHistory = [
  {
    vendor: 'Crowdblink',
    trans_type: 'TopUp',
    date: moment(new Date()).format('MM/DD/YYYY'),
    amount: '$20.00',
    number: '0000',
    cardType: 'Visa',
  },
  {
    vendor: 'Crowdblink',
    trans_type: 'TopUp',
    date: moment(new Date()).format('MM/DD/YYYY'),
    amount: '$10.00',
    number: '0000',
    cardType: 'Visa',
  },
];

const EventDetail: FC = () => {
  const [balance, setBalance] = useState<number>(14.86);
  const [isVisibleDetails, setIsVisibleDetails] = useState<boolean>(false);
  const { getAllPaymentMethods } = usePayments();

  useEffect(() => {
    const tableRowsData = JSON.parse(localStorage.getItem('tableRows') || '{}');

    if (tableRowsData.length) {
      setBalance(calcTotalBalance(balance, tableRowsData));
    }
  }, [balance]);

  useEffect(() => {
    getAllPaymentMethods();
  }, [getAllPaymentMethods]);

  return (
    <div className='w-full px-[1rem] h-auto block'>
      {!isVisibleDetails ? (
        <TransactionHistory
          theadData={theadDataHistory}
          transactionHistory={transactionHistory}
          onManageClick={() => setIsVisibleDetails(true)}
        />
      ) : (
        <TransactionDetails
          theadData={theadDataDetails}
          transactionDetails={transactionDetails}
          onBackClick={() => setIsVisibleDetails(false)}
        />
      )}
    </div>
  );
};

export default EventDetail;
