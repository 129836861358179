import { yupResolver } from '@hookform/resolvers/yup';
import valid from 'card-validator';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cards from 'react-credit-cards-2';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup } from 'reactstrap';
import * as yup from 'yup';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { CurrencyAmountConverter, Button, DividerWithText } from 'components';
import { CardInfo, PaymentMethod } from 'data/model';
import usePayments from 'data/usePayments';
import {
  selectUiIsFetchProductsLoading,
  selectUiIsCreateTopUpOrderLoading,
  useAppSelector,
  selectEventCurrency,
} from 'store';
import { getCreditCardIconByType, replaceSpreedlyHideString } from 'helpers/creditCardUtils';
import { getMinimumTopUpAmount } from 'helpers/currency';
import { validateCardExpiryDate } from 'components/AddCard/helpers';

interface PayNowModalProps {
  toggle: () => void;
  modal: boolean;
  onClosed?: () => void;
  setTopUpAmount: any;
  topUpAmount: number;
  instantPayCallback?: (cardInfo: CardInfo, callback?: () => void) => void;
  payWithExistingCard: (paymentMethodId: string, callback: () => void) => void;
  newWalletBalance: string;
  payEnabled: boolean;
}

/**
 * AddPaymentMethodModal component for adding a payment method
 * is meant to be a standalone component that can be used anywhere to add payment method
 * To use this modal you must have state in place to handle the modal being open or closed within the parent component
 * ex:
 * import 'bootstrap/dist/css/bootstrap.min.css';
 * ...
 * const [modal, setModal] = useState(false);
 * const toggle = () => setModal(!modal);
 *
 * Note: This component has been extended to support instantPay and autoTopUp
 * instantPay: boolean - if true, the modal will present a save card checkbox
 * autoTopUp: boolean - if true, the modal will present a save card checkbox and the checkbox will be checked by default
 * @param {AddPaymentMethodModalProps} { toggle, modal }
 * @returns {JSX.Element}
 */
function PayNowModal({
  toggle,
  modal,
  topUpAmount,
  setTopUpAmount,
  instantPayCallback,
  payEnabled,
  payWithExistingCard,
  newWalletBalance,
}: PayNowModalProps): JSX.Element {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('');
  const isFetchProductLoading = useAppSelector(selectUiIsFetchProductsLoading);
  const isCreateTopUpOrderLoading = useAppSelector(selectUiIsCreateTopUpOrderLoading);
  const { paymentStatus, addPaymentMethod } = usePayments();
  const [shouldSaveCardDetail, setShouldSaveCardDetail] = useState(false);
  const { paymentMethods, getAllPaymentMethods } = usePayments();
  const [newCard, setNewCard] = useState(false);
  const currency = useSelector(selectEventCurrency);
  const minimumAmount = getMinimumTopUpAmount(currency);

  const paymentIsLoading =
    isFetchProductLoading || isCreateTopUpOrderLoading || paymentStatus !== '';

  const creditCardSchema = yup.object().shape({
    cardNumber: yup
      .string()
      .required(t('addPaymentMethodModal.errorCreditCardIsRequired'))
      .test(
        'test-number', // this is used internally by yup
        t('addPaymentMethodModal.errorCreditCardNumber'), //validation message
        (value) => valid.number(value).isValid,
      ), // return true false based on validation
    cardHolderName: yup
      .string()
      .required(t('addPaymentMethodModal.errorCardholderNameRequired'))
      .matches(/^[a-zA-Z\s]*$/, t('addPaymentMethodModal.errorOnlyLettersAndSpaces')),
    expiryDate: yup
      .string()
      .required(t('addPaymentMethodModal.errorExpiryDateRequired'))
      .matches(
        /^(0[1-9]|1[0-2])\/?(\d{4}|\d{2})$/,
        t('addPaymentMethodModal.errorInvalidExpiryDateFormat'),
      )
      .test('is-not-expired', 'Expiry date is expired', validateCardExpiryDate),
    cvv: yup
      .string()
      .required(t('addPaymentMethodModal.errorCVVRequired'))
      .matches(/^\d{3,4}$/, t('addPaymentMethodModal.errorCVVInvalid')),
  });

  type creditCardInfo = {
    cardNumber: string;
    cardHolderName: string;
    expiryDate: string;
    cvv: string;
  };

  const methods = useForm<creditCardInfo>({
    resolver: yupResolver(creditCardSchema),
  });

  const handlePayButtonOnClick = useCallback(async () => {
    payWithExistingCard(selectedPaymentMethodId, toggle);
  }, [selectedPaymentMethodId, payWithExistingCard, toggle]);

  const onSubmit = async (data: creditCardInfo) => {
    try {
      // expiryDate is of format MM/YY with optional / so we need to remove the / to get the month and year
      const dateWithoutSlashes = data.expiryDate.split('/').join('');
      const separatedDate = dateWithoutSlashes.match(/.{1,2}/g);
      if (!separatedDate || !separatedDate[0] || !separatedDate[1]) {
        console.log('invalid date'); // Should never happen
        setNewCard(false);
        return;
      }

      const newCardInfo: CardInfo = {
        number: data.cardNumber,
        cvv: data.cvv,
        month: separatedDate[0],
        year: '20' + separatedDate[1],
        full_name: data.cardHolderName,
        cardType: undefined,
        default: false,
        zip: undefined,
        country: undefined,
      };

      if (shouldSaveCardDetail) {
        addPaymentMethod(newCardInfo);
        setShouldSaveCardDetail(!shouldSaveCardDetail);
      }

      if (instantPayCallback) {
        instantPayCallback(newCardInfo, toggle);
      }
      methods.reset();
    } catch (error) {
      setNewCard(false);
    }
  };

  const onErrors = (errors: unknown) => {
    console.log('onErrors', errors);
  };

  const handleCancelonClick = () => {
    methods.reset();
    toggle();
  };

  const onUseNewCardChange = (): void => {
    setSelectedPaymentMethodId('');
    setNewCard(true);
  };

  // Prevent scroll from changing input value
  function handleWheel() {
    if (document?.activeElement === inputRef.current) {
      (document.activeElement as HTMLElement).blur();
    }
  }

  const preventPasteNegativeOrDecimal = (e: any) => {
    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0 || pastedData % 1 !== 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    getAllPaymentMethods();
  }, [getAllPaymentMethods]);

  useEffect(() => {
    const defaultValues = {} as creditCardInfo;
    defaultValues.cardNumber = '';
    defaultValues.cardHolderName = '';
    defaultValues.expiryDate = '';
    defaultValues.cvv = '';
    methods.reset(defaultValues);
  }, [methods]);

  // Only set the initial payment card on load incase there is a default card
  useEffect(() => {
    paymentMethods.forEach((card: PaymentMethod) => {
      if (card.default) {
        setSelectedPaymentMethodId(card.uuid);
      }
    });

    // Force user to enter new card details if they have no saved payment
    if (paymentMethods.length === 0) {
      setNewCard(true);
    } else {
      setNewCard(false);
    }
  }, [paymentMethods]);

  //Note: is instantPay is set they we need to add a save card checkbox
  // if autotop is set then save card checkbox should be checked by default and disabled
  // if save card is not checked then we should not retain the card
  // we must use different endpoint for instant pay without saving card
  // also if instantPay is set then we close the topup modal on 'confirm'
  return (
    <Modal isOpen={modal} toggle={toggle} backdrop='static'>
      <ModalHeader>{t('addPaymentMethodModal.paymentDetails')}</ModalHeader>
      <ModalBody>
        {newCard && (
          <Cards
            number={methods?.watch('cardNumber')}
            expiry={methods?.watch('expiryDate')}
            cvc={methods?.watch('cvv')}
            name={methods?.watch('cardHolderName')}
          />
        )}
        <div className='pb-4'>
          <CurrencyAmountConverter
            topUpAmount={topUpAmount}
            onChange={(value) => setTopUpAmount(value)}
          />
        </div>
        {paymentMethods.length ? (
          <>
            {t('TopUpModal.paymentMethod')}
            <div className='lg:pl-5'>
              <FormGroup tag='fieldset'>
                {paymentMethods?.map((card: PaymentMethod) => (
                  <div key={card.uuid} className='bg-gray-100 pl-2 mt-2 rounded-lg'>
                    <FormGroup check>
                      <Label check>
                        <div className='flex items-center'>
                          <Input
                            type='radio'
                            name='radio1'
                            value={card.uuid}
                            checked={selectedPaymentMethodId === card.uuid}
                            onChange={() => {
                              setSelectedPaymentMethodId(card.uuid);
                              setNewCard(false);
                            }}
                          />
                          <FontAwesomeIcon
                            icon={getCreditCardIconByType(card.meta.cardType)}
                            className='pr-2.5 pl-2 mt-1'
                            size='xl'
                          />
                          {replaceSpreedlyHideString(card.info.spreedlyPaymentMethod.number)}
                          <div className='ml-1'>
                            {`(expires ${card.info.spreedlyPaymentMethod.month}/${String(
                              card.info.spreedlyPaymentMethod.year,
                            ).slice(2)})`}
                          </div>
                        </div>
                      </Label>
                    </FormGroup>
                  </div>
                ))}
                <div className='bg-gray-100 pl-2 rounded-lg mt-2'>
                  <FormGroup check>
                    <Label check>
                      <div className='flex items-center'>
                        <Input
                          type='radio'
                          name='radio1'
                          checked={newCard}
                          onChange={onUseNewCardChange}
                        />
                        <p className='ml-2'>{t('TopUpModal.UseNewCard')}</p>
                      </div>
                    </Label>
                  </FormGroup>
                </div>
              </FormGroup>
            </div>
          </>
        ) : null}
      </ModalBody>
      {newCard ? (
        <form onSubmit={methods.handleSubmit(onSubmit, onErrors)}>
          <ModalBody>
            <div className='w-full'>
              <Controller
                control={methods.control}
                render={({ field: { onChange, value, name } }) => (
                  <FormGroup>
                    <Label for='cardNumber'>{t('addPaymentMethodModal.number')}</Label>
                    <Input
                      id='cardNumber'
                      name={name}
                      placeholder={t('addPaymentMethodModal.enterCardNumber')}
                      type='number'
                      value={value}
                      onWheel={() => handleWheel()}
                      onChange={onChange}
                      innerRef={inputRef}
                      onKeyDown={(e) => {
                        if (e.key === '.') {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e: any) => {
                        e.target.value =
                          Math.abs(e.target.value) >= 0 ? Math.abs(e.target.value) : null;
                      }}
                      onPaste={(e) => preventPasteNegativeOrDecimal(e)}
                    />
                    {methods.formState.errors.cardNumber && (
                      <Label className='text-red-600'>
                        {methods.formState.errors.cardNumber.message}
                      </Label>
                    )}
                  </FormGroup>
                )}
                name='cardNumber'
              />
              <Controller
                control={methods.control}
                render={({ field: { onChange, value, name } }) => (
                  <FormGroup>
                    <Label for='cardHolderName'>{t('addPaymentMethodModal.name')}</Label>
                    <Input
                      id='cardHolderName'
                      name={name}
                      placeholder={t('addPaymentMethodModal.fullName')}
                      type='search'
                      value={value}
                      onChange={onChange}
                    />
                    {methods.formState.errors.cardHolderName && (
                      <Label className='text-red-600'>
                        {methods.formState.errors.cardHolderName.message}
                      </Label>
                    )}
                  </FormGroup>
                )}
                name='cardHolderName'
              />
              <Controller
                control={methods.control}
                name='expiryDate'
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <Label for='expiryDate'>{t('addPaymentMethodModal.expiryDate')}</Label>
                    <InputMask mask='99/99' maskPlaceholder='' value={value} onChange={onChange}>
                      {
                        //@ts-ignore
                        () => (
                          <Input
                            id='expiryDate'
                            name='expiryDate'
                            placeholder={t('addPaymentMethodModal.expiryPlaceHolder')}
                            type='text'
                          />
                        )
                      }
                    </InputMask>
                    {methods.formState.errors.expiryDate && (
                      <Label className='text-red-600'>
                        {methods.formState.errors.expiryDate.message}
                      </Label>
                    )}
                  </FormGroup>
                )}
              />
              <Controller
                control={methods.control}
                render={({ field: { onChange, value, name } }) => (
                  <FormGroup>
                    <Label for='cvv'>{t('addPaymentMethodModal.cvv')}</Label>
                    <Input
                      id='cvv'
                      name={name}
                      placeholder={t('addPaymentMethodModal.cvvPlaceHolder')}
                      type='search'
                      value={value}
                      onChange={onChange}
                    />
                    {methods.formState.errors.cvv && (
                      <Label className='text-red-600'>{methods.formState.errors.cvv.message}</Label>
                    )}
                  </FormGroup>
                )}
                name='cvv'
              />
              <FormGroup className='mt-4'>
                <Input
                  className='mr-2'
                  type='checkbox'
                  checked={shouldSaveCardDetail}
                  onChange={() => setShouldSaveCardDetail(!shouldSaveCardDetail)}
                />
                <Label for='saveCardDetail'>Save This Card</Label>
                <a
                  data-tooltip-id='tip1'
                  className='p-2 inline-block text-intelli-tertiary'
                  data-tooltip-content='Selecting "Save this Card" will store the new card in your account, reducing the time needed when adding online top-up funds.'
                >
                  <FontAwesomeIcon icon={faCircleInfo} />
                </a>
                <ReactTooltip
                  id='tip1'
                  style={{ backgroundColor: '#F9F9F9', color: '#517077', width: '272px' }}
                  place='right-end'
                />
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter className='border-t-0 pt-0'>
            <div className='w-full pb-2'>
              <DividerWithText>New Event Balance: {newWalletBalance}</DividerWithText>
            </div>
            <Button
              isLoading={paymentIsLoading}
              type='submit'
              className='w-24 btn btn-primary'
              disabled={topUpAmount < minimumAmount || !payEnabled}
            >
              {t('buttons.pay')}
            </Button>
            <Button
              disabled={paymentIsLoading}
              onClick={handleCancelonClick}
              className='w-24 btn btn-secondary'
            >
              {t('buttons.cancel')}
            </Button>
          </ModalFooter>
        </form>
      ) : (
        <div>
          <ModalFooter className='border-t-0 pt-0'>
            <div className='w-full pb-2'>
              <DividerWithText>New Event Balance: {newWalletBalance}</DividerWithText>
            </div>
            <Button
              onClick={handlePayButtonOnClick}
              isLoading={paymentIsLoading}
              className='w-24 btn btn-primary'
              disabled={topUpAmount < minimumAmount || !payEnabled}
            >
              {t('buttons.pay')}
            </Button>
            <Button
              disabled={paymentIsLoading}
              onClick={handleCancelonClick}
              className='w-24 btn btn-secondary'
            >
              {t('buttons.cancel')}
            </Button>
          </ModalFooter>
        </div>
      )}
    </Modal>
  );
}

export default PayNowModal;
