import React from 'react';
import { ModalHeader, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import useRefundContext from './RefundContext';

import { Button } from 'components';
import ConfirmationIcon from 'components/icons/ConfirmationIcon';

type RefundModalProps = {
  handleSubmit: () => void;
  isLoading: boolean;
};

const RefundModal = ({ handleSubmit, isLoading }: RefundModalProps) => {
  const { t } = useTranslation();
  const { modalIsOpen, closeModal } = useRefundContext();

  return (
    <Modal isOpen={modalIsOpen} toggle={closeModal}>
      <div className='flex items-center flex-col'>
        <ModalHeader>
          <ConfirmationIcon />
        </ModalHeader>
        <ModalBody className='flex flex-col items-center'>
          <div className='text-lg font-bold'>{t('refundRequest.confirmationModal.body')}</div>
          <div className='text-sm'>{t('refundRequest.confirmationModal.description')}</div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} className='btn btn-secondary btn-sm'>
            {t('refundRequest.confirmationModal.cancel')}
          </Button>
          <Button
            type='submit'
            className='btn btn-primary btn-sm'
            isLoading={isLoading}
            onClick={() => handleSubmit()}
          >
            {t('refundRequest.confirmationModal.confirm')}
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default RefundModal;
