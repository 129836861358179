import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { groupTransactionsByDay, mapRefundTransactionToBalanceTransaction } from '../helpers';

import TransactionItem from './TransactionItem';

import { EmptyText, Loader, SectionTitle } from 'components';
import { useData, useEventsApi } from 'data';
import useMediaQuery from 'hooks/mediaQuery/useMediaQuery';
import {
  selectUserData,
  selectUiIsTransactionHistoryLoading,
  selectMappedTransactionsHistory,
  useAppSelector,
  selectEventCurrency,
} from 'store';
import { formatDateToMed } from 'helpers';
import { formatCurrency } from 'helpers/currency';

const HeaderCell = ({ children }: any) => (
  <div className={'flex-1 text-center font-light text-sm text-[#6F7FAF] py-3'}>{children}</div>
);

const Cell = ({ className, children }: any) => <div className={className}>{children}</div>;

const Transactions: FC = () => {
  const { securedApi } = useData();
  const { fetchEventTransactionHistory } = useEventsApi();
  const isLoading = useAppSelector(selectUiIsTransactionHistoryLoading);
  const allTransactions = useAppSelector(selectMappedTransactionsHistory);
  const [refundHistory, setRefundHistory] = useState([]);
  const { eventId } = useParams();
  const isMobile = useMediaQuery();
  const { t } = useTranslation();
  const userData = useAppSelector(selectUserData);
  const currency = useSelector(selectEventCurrency);

  const transactions = allTransactions.filter(
    (transaction) => transaction.productKind !== 'ticket',
  );

  useEffect(() => {
    if (eventId && userData) {
      fetchEventTransactionHistory(eventId);
      const accountId = userData.sub;

      securedApi?.OrdersApi.refundHistory(accountId, eventId).then((resp) => {
        const data = resp?.data;

        setRefundHistory(data);
      });
    }
  }, [userData, eventId, fetchEventTransactionHistory, securedApi?.OrdersApi]);

  const transactionsGroupedByDay = groupTransactionsByDay([
    ...transactions,
    ...refundHistory.map((refundItem) =>
      mapRefundTransactionToBalanceTransaction(refundItem, currency),
    ),
  ]);

  return (
    <>
      <div className={isMobile ? 'hidden' : 'pb-10'}>
        <SectionTitle title='Transactions' />
      </div>
      <div className={classNames('md:w-full', { 'mt-[16px]': !isMobile })}>
        {isLoading ? (
          <Loader className='relative' />
        ) : !transactions || !transactions.length ? (
          <EmptyText
            title='Looks like you haven’t made any transactions yet!'
            description='Once you Top-Up funds or make a purchase at the event, the transaction will appear here.'
          />
        ) : isMobile ? (
          <div>
            {Object.keys(transactionsGroupedByDay).map((transactionDate) => (
              <div key={transactionDate}>
                <h3 className='text-lg mb-2 text-gray-500'>{transactionDate}</h3>
                <ul>
                  {transactionsGroupedByDay[transactionDate].map((transaction) => (
                    <TransactionItem
                      key={transaction.id}
                      transactionEntry={transaction}
                      eventId={eventId!}
                    />
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ) : (
          <div className='rounded-lg border shadow-2xl w-full mt-[1rem]'>
            <div className='flex bg-slate-100 border-b justify-between items-center py-2 px-6 uppercase'>
              <HeaderCell>{t('Transactions.columnTitles.vendor')}</HeaderCell>
              <HeaderCell>{t('Transactions.columnTitles.date')}</HeaderCell>
              <HeaderCell>{t('Transactions.columnTitles.transactions')}</HeaderCell>
              <HeaderCell>{t('Transactions.columnTitles.amount')}</HeaderCell>
              <HeaderCell>{t('Transactions.columnTitles.details')}</HeaderCell>
            </div>
            {transactions?.map((transaction: any) => (
              <TransactionItem
                key={transaction.uuid}
                transactionEntry={transaction}
                eventId={eventId!}
              />
            ))}
            {refundHistory?.map(
              (
                {
                  refundFee,
                  completedOn,
                  _ticketId,
                  _transaction,
                  _refundSubTotalAmount,
                  refundTotalAmount,
                  refundStatus,
                }: any,
                _i: number,
              ) => (
                <div
                  key={'refundHistory' + _i}
                  className='flex border-b justify-between items-center py-2 px-6'
                >
                  <Cell className='flex-1 text-center font-normal text-[#232E59] text-xl py-3'>
                    Intellitix
                  </Cell>
                  <Cell className='flex-1 text-center py-3 text-[#6F7FAF] font-light'>
                    {formatDateToMed(completedOn)}
                  </Cell>
                  <Cell className='flex-1 text-center py-3 text-[#6F7FAF] font-light'>Refund</Cell>
                  <Cell className='flex-1 text-center py-3 text-[#6F7FAF] font-light'>
                    {formatCurrency(refundTotalAmount / 100, currency)} Refund /{' '}
                    {formatCurrency(refundFee / 100, currency)} Fee
                  </Cell>
                  <Cell className='flex-1 text-center py-3 text-[#6F7FAF] font-light'>
                    {refundStatus}
                  </Cell>
                </div>
              ),
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Transactions;
