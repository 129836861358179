import React, { FC } from 'react';

import { PersonalInfo } from './components';

import 'react-toastify/dist/ReactToastify.css';

const UserProfile: FC = () => {
  return (
    <div className='w-full  px-[1%] pt-[1%] h-auto'>
      <PersonalInfo />
    </div>
  );
};

export default UserProfile;
