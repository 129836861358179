import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { DatePicker } from 'components';

type ControlledDatePickerProps = {
  control: any;
  fieldName: string;
  label?: string;
  placeholder?: string;
};

const ControlledDatePicker: FC<ControlledDatePickerProps> = ({
  control,
  fieldName,
  label,
  placeholder,
  ...restProps
}) => (
  <Controller
    control={control}
    name={fieldName}
    render={({ field, fieldState }) => (
      <DatePicker
        label={label}
        placeholder={placeholder}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        isError={fieldState.invalid}
        errorMsg={fieldState.error?.message}
        {...restProps}
      />
    )}
  />
);

export default ControlledDatePicker;
